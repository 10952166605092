import React from 'react';
import { Typography, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { ScheduleOutlined, ErrorOutline } from '@material-ui/icons';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import WarningOrangeSvg from '@images/svgComponents/WarningOrangeSvg';
import InfoIconSmallBlueSvg from '@images/svgComponents/InfoIconSmallBlueSvg';
import CommonDropDownChips, { ChipLabel } from '@common/CommonDropDownChips';
import classNames from 'classnames';
import OverflowTip from '@common/OverflowTip';
import { ASSET_STATUSES_LIST, chipThemes } from '@common/CommonDropDownChips';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { getAlertsByAssetClaimId } from '@actions/assetsClaimEvent';
import { changeRegistrationStatusForAssetAPI } from '@api/assetsClaimEventController';
import { updateNestedField } from '@actions/apiActions';
import MoreDropDownButton from '@common/MoreDropDownButton';
import { useDispatch } from 'react-redux';
import ChatBubbleUnread from '@images/svgComponents/ChatBubbleUnreadSvg';

const useStyles = makeStyles((theme) => ({
    icons: {
        height: '20px',
        width: '20px',
        marginRight: '8px',
    },
    registrationStatusColumn: {
        minWidth: '150px',
        flexGrow: 1,
    },
    iconRow: {
        display: 'flex',
        alignItems: 'center',
    },
    displayFlex: {
        display: 'flex',
    },
    manualAlerts: { marginLeft: '8px', marginTop: '4px', alignItems: 'center' },
    disputeIcon: {
        width: '18px',
        height: '18px',
        fill: '#F44139',
    },
    iconStyle: {
        width: '18px',
        height: '18px',
    },
}));

const getAlertIconAndTooltip = (alertType, classes) => {
    let icon = null;
    let tooltipTitle = '';

    switch (alertType) {
        case 'DISPUTE':
            icon = <ErrorOutline className={classes.disputeIcon} />;
            tooltipTitle = 'Internal action required';
            break;
        case 'EVIDENCE_REQUIRED':
            icon = <WarningOrangeSvg />;
            tooltipTitle = 'Client action required';
            break;
        case 'CMO_VALIDATION':
            icon = <WarningOrangeSvg />;
            tooltipTitle = 'Validation error';
            break;
        case 'ACTION_REQUIRED':
            icon = <InfoIconSmallBlueSvg />;
            tooltipTitle = 'Awaiting CMO action';
            break;
        default:
            break;
    }

    return { icon, tooltipTitle };
};

export const AssetsCmoTableRow = ({
    headerItem,
    dataItem,
    pageModelName,
    setAnchorEl,
    setDialogDataItem,
    setIsDialogOpen,
    setIsCommentsDialogOpen,
    getAssetData,
}) => {
    const classes = useStyles();
    const columnTitle = headerItem.title.toLowerCase();
    const dispatch = useDispatch();

    const handleHistoryClick = (evt, dataItem) => {
        dispatch(changeStateByNestedKey(pageModelName, 'selectedRowDataItem', dataItem));
        setAnchorEl(evt.currentTarget);
    };

    const handleCommentsClick = (dataItem) => {
        dispatch(changeStateByNestedKey(pageModelName, 'selectedRowDataItem', dataItem));
        setIsCommentsDialogOpen(true);
    };

    const handleAlertClick = (dataItem) => {
        setDialogDataItem(dataItem);
        dispatch(getAlertsByAssetClaimId(dataItem.assetClaimId, pageModelName));
        setIsDialogOpen(true);
    };

    const statusChangeCb = async (status, dataItem) => {
        const response = await changeRegistrationStatusForAssetAPI(dataItem.assetClaimId, {
            claimStatus: status,
        });

        if (response?.data) {
            updateStatusInRow(response);
        }
    };

    const updateStatusInRow = (response) => {
        const dataItemFromResp = response.data;

        dispatch(
            updateNestedField(pageModelName, 'dataFromBE', dataItemFromResp.statusDate, {
                arrayName: 'content',
                idFieldName: 'assetClaimId',
                id: dataItemFromResp.assetClaimId,
                fieldNameToChange: 'statusDate',
            }),
        );
        dispatch(
            updateNestedField(pageModelName, 'dataFromBE', dataItemFromResp.newStatus, {
                arrayName: 'content',
                idFieldName: 'assetClaimId',
                id: dataItemFromResp.assetClaimId,
                fieldNameToChange: 'claimStatus',
            }),
        );
        getAssetData();
    };

    if (columnTitle.includes('track')) {
        return (
            <>
                <Typography variant="body2">
                    <OverflowTip>{dataItem[headerItem.dataIndex[0]]}</OverflowTip>
                </Typography>
                <Typography variant="caption">
                    <OverflowTip>{dataItem[headerItem.dataIndex[1]]}</OverflowTip>
                </Typography>
            </>
        );
    } else if (columnTitle === '') {
        return (
            <div className={classes.iconRow}>
                <IconButton onClick={(e) => handleHistoryClick(e, dataItem)} disableRipple>
                    <ScheduleOutlined className={classes.iconStyle} />
                </IconButton>
                <IconButton onClick={() => handleCommentsClick(dataItem)} disableRipple>
                    {dataItem.hasComment ? (
                        <ChatBubbleUnread className={classes.iconStyle} />
                    ) : (
                        <ChatBubbleOutlineOutlinedIcon className={classes.iconStyle} />
                    )}
                </IconButton>
                <MoreDropDownButton
                    callbacks={{
                        onAlertCB: handleAlertClick,
                    }}
                    dataItem={dataItem}
                />
            </div>
        );
    } else if (columnTitle.includes('registration status') && dataItem.claimStatus) {
        const menuItemsList = (dataItem.acceptableClaimStatuses || [])
            .filter((status) => Object.keys(ASSET_STATUSES_LIST).includes(status))
            .map((status) => ({
                title: status.split('_').join(' '),
                onClickClb: statusChangeCb,
                CbArgs: [status, dataItem],
                color: ASSET_STATUSES_LIST[status].color,
            }));

        return (
            <div className={classNames([classes.displayFlex, classes.registrationStatusColumn])}>
                {dataItem[headerItem.dataIndex] === 'EXCLUSIVE_LICENSE_DEAL' ? (
                    <ChipLabel label="EXCLUSIVE LICENSE" style={chipThemes.greenChip} />
                ) : (
                    <CommonDropDownChips
                        chipTitle={dataItem[headerItem.dataIndex].split('_').join(' ')}
                        menuItemsList={menuItemsList}
                        hasAlerts={dataItem?.alertCategories}
                    />
                )}
                {/* Manual Alerts */}
                <div
                    className={classNames([classes.displayFlex, classes.manualAlerts])}
                    onClick={() => handleAlertClick(dataItem)}
                >
                    {['DISPUTE', 'EVIDENCE_REQUIRED', 'ACTION_REQUIRED', 'CMO_VALIDATION'].map(
                        (alertType, index) => {
                            const { icon, tooltipTitle } = getAlertIconAndTooltip(
                                alertType,
                                classes,
                            );

                            return (
                                (dataItem.alertCategories || []).includes(alertType) && (
                                    <Tooltip title={tooltipTitle} key={index}>
                                        <div>{icon}</div>
                                    </Tooltip>
                                )
                            );
                        },
                    )}
                </div>
            </div>
        );
    } else {
        return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
    }
};
