import { createTheme, makeStyles } from '@material-ui/core/styles';
import { FUGA_COLORS } from '@styling/fugaColors';

const globalTheme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },

        error: {
            main: '#F44139',
            oulinedRestingBorder: '#EE9A9B', //design system
            background: '#FFEBEE', // design system
        },
        success: {
            main: '#00A542',
            oulinedRestingBorder: '#56CC82', //design system
            background: '#E3F6E9', //design system
        },
        orange: {
            content: FUGA_COLORS.ORANGE[900],
            main: FUGA_COLORS.ORANGE[600],
            outlinedRestingBorder: FUGA_COLORS.ORANGE[300],
            outlinedHoverBackground: FUGA_COLORS.ORANGE[50],
        },
        text: {
            secondary: '#5F5F60',
            white: '#FFFFFF',
        },
        grey: {
            content: FUGA_COLORS.WHITE,
            background: FUGA_COLORS.GREY[800],
            hover: FUGA_COLORS.GREY[600],
            border: FUGA_COLORS.GREY[500],
        },
        chips: {
            background: FUGA_COLORS.FUGA_BLUE[100],
        },
    },
});

const theme = createTheme({
    ...globalTheme,
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '1em',
            },
        },
        MuiFormControl: {
            root: {
                '& .MuiInput-underline.Mui-disabled:before': {
                    borderBottomStyle: 'solid',
                },
            },
        },
        MuiTextField: {
            root: {
                // disabled as per design change back
                // "& span[class*='MuiFormLabel-asterisk']": {
                //     color: '#FF8800',
                // },
            },
        },
        MuiButton: {
            outlined: {
                padding: '7px 16px',
                borderRadius: '4px',
                boxSizing: 'border-box',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '1.25px',
                fontWeight: '700',
                textAlign: 'center',
                fontStyle: 'normal',
            },
            contained: {
                padding: '8px 16px',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '1.25px',
                fontWeight: '700',
                textAlign: 'center',
                fontStyle: 'normal',
            },
            text: {
                color: '#FFFFFF',
                padding: '7px 16px',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '1.25px',
                fontWeight: '700',
                fontStyle: 'normal',
                textAlign: 'center',
            },
        },
        MuiChip: {
            outlinedPrimary: {
                color: '#006218',
                borderRadius: '4px',
                border: '1px solid #56CC82',
                padding: '4px, 6px, 4px, 6px',
                backgroundColor: '#E3F6E9',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10px',
                lineHeight: '14px',
                textAlign: 'center',
                letterSpacing: '1.5px',
                textTransform: 'capitalize',
            },
            outlinedSecondary: {
                color: '#9B9B9D',
                borderRadius: '4px',
                border: '1px solid #BDBCBC',
                padding: '4px, 6px, 4px, 6px',
                backgroundColor: '#ECECEE',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10px',
                lineHeight: '14px',
                textAlign: 'center',
                letterSpacing: '1.5px',
                textTransform: 'capitalize',
            },
        },
        MuiAlert: {
            outlinedError: {
                borderColor: globalTheme.palette.error.main,
                backgroundColor: globalTheme.palette.error.background,
            },
            outlinedSuccess: {
                borderColor: globalTheme.palette.success.main,
                backgroundColor: globalTheme.palette.success.background,
            },
            outlinedWarning: {
                borderColor: globalTheme.palette.warning.main,
                backgroundColor: '#FFF3E0',
            },
            message: {
                textAlign: 'center',
            },
        },
        MuiLink: {
            underlineHover: {
                '&:hover': {
                    color: globalTheme.palette.text.secondary,
                },
            },
        },
    },

    typography: {
        fontFamily: 'Nunito Sans, sans-serif',
        color: '#1F1F21',
        body1: {
            color: '#1F1F21',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
        },
        body2: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.25px',
        },
        h6: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '27px',
            letterSpacing: '0.15px',
            textAlign: 'left',
        },
        h5: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '33px',
            letterSpacing: '0.15px',
        },
        h4: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '36px',
            lineHeight: '42px',
            letterSpacing: '0.25px',
        },
        h3: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '48px',
            lineHeight: '65px',
            letterSpacing: '0.25px',
        },
        h2: {
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '64px',
            lineHeight: '87px',
            letterSpacing: '-0.5px',
        },
        h1: {
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '96px',
            lineHeight: '131px',
            letterSpacing: '-1.5px',
        },
        subtitle1: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.15px',
        },
        subtitle2: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.1px',
        },
        caption: {
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '12px',
            letterSpacing: '0.4px',
            color: '#5F5F60',
        },
    },
});

export default theme;

export const useTableClasses = makeStyles({
    root: {
        marginBottom: '42px',
        '& div.MuiDataGrid-footerContainer': {
            display: 'none',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& div.MuiDataGrid-columnHeaderWrapper *': {
            fontWeight: 'bold',
        },
        '& div.MuiDataGrid-columnHeader': {
            padding: '0 10px', // should be 16px but has 6px text padding by default
        },
        '& div.MuiDataGrid-cell': {
            padding: '0 16px',
            borderBottom: 'none',
        },
    },
});
