import { changeStateByNestedKey } from './fugaActions';
import { getCmoIssuedIdsByClientIdAPI } from '@api/clientController';

function getCmoIssuedIdsByClientId(clientId) {
    return async function (dispatch) {
        const resp = await getCmoIssuedIdsByClientIdAPI(clientId);

        dispatch(
            // TODO update store name to be not generic in scope of NR-603
            changeStateByNestedKey('performers', 'cmoIssuedIds', resp.data || []),
        );
    };
}

export { getCmoIssuedIdsByClientId };
