import React, { useState } from 'react';
import { Typography, Box, ButtonGroup, Button, Link, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PerformerDetailsContent from './PerformerDetailsContent';
import PerformerDealTermsContent from './PerformerDealTermsContent';
import PerformerBioContent from './PerformerBioContent';
import PerformerBillingContent from './PerformerBillingContent';
import PerformerCommentsContent from './PerformerCommentsContent';
import PerformerIdentifiersContent from './PerformerIdentifiersContent';
import PerformerContactsAndUsersContent from './PerformerContactsAndUsersContent';

const panes = {
    general: {
        label: 'General',
        id: 'general-client-info',
    },
    identifiers: {
        label: 'Identifiers',
        id: 'identifiers-client-info',
    },
    bio: {
        label: 'Bio',
        id: 'bio-client-info',
    },
    contactsAndUsers: {
        label: 'Contacts & Users',
        id: 'contacts-and-users',
    },
    dealTerms: {
        label: 'Deal Terms',
        id: 'deal_terms-client-info',
    },
    contacts: {
        label: 'Contacts',
        id: 'contacts-client-info',
    },
    billing: {
        label: 'Billing',
        id: 'billing-client-info',
    },
    comments: {
        label: 'Comments',
        id: 'comments-client-info',
    },
};
const useStyles = makeStyles({
    root: {
        background: '#DEDEE0',
    },
});

function PerformerMainDetailsNavigation() {
    const [active, setActive] = useState('General');
    const classes = useStyles();

    const handleTabBtnClick = (tabName) => {
        setActive(tabName.label);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
                <Grid item sx={2} md={3} lg={3} xl={2}>
                    <ButtonGroup orientation="vertical" style={{ width: '100%' }}>
                        {Object.keys(panes).map((tabName) => {
                            return (
                                <Button
                                    variant="text"
                                    key={panes[tabName].id}
                                    style={{ textTransform: 'unset', textAlign: 'left' }}
                                    onClick={() => handleTabBtnClick(panes[tabName])}
                                    className={`${
                                        active === panes[tabName].label ? classes.root : ''
                                    } tabBtn`}
                                >
                                    <Link underline="none" href={`#${panes[tabName].id}`}>
                                        <Typography variant="body1">
                                            {panes[tabName].label}
                                        </Typography>
                                    </Link>
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                </Grid>
                <Grid item sx={10} md={9} lg={9} xl={10}>
                    <div style={{ padding: '32px 0 0' }}>
                        <General />
                        <Identifiers />
                        <Bio />
                        <ContactsAndUsers />
                        <DealTerms />
                        <Contacts />
                        <Billing />
                        <Comments />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}

function General() {
    return (
        <div id={panes.general.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                General
            </Typography>
            <PerformerDetailsContent />
        </div>
    );
}

function Identifiers() {
    return (
        <div id={panes.identifiers.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Identifiers
            </Typography>
            <PerformerIdentifiersContent />
        </div>
    );
}

function Bio() {
    return (
        <div id={panes.dealTerms.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Bio
            </Typography>
            <PerformerBioContent />
        </div>
    );
}

function ContactsAndUsers() {
    return (
        <div id={panes.contactsAndUsers.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Contacts & Users
            </Typography>
            <PerformerContactsAndUsersContent />
        </div>
    );
}

function DealTerms() {
    return (
        <div id={panes.dealTerms.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Deal Terms
            </Typography>
            <PerformerDealTermsContent />
        </div>
    );
}

function Contacts() {
    return (
        <div id={panes.contacts.id}>
            {/* <Typography variant='h6' style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>Contacts</Typography> */}
        </div>
    );
}

function Billing() {
    return (
        <div id={panes.billing.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Billing
            </Typography>
            <PerformerBillingContent />
        </div>
    );
}

function Comments() {
    return (
        <div id={panes.comments.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Comments
            </Typography>
            <PerformerCommentsContent />
        </div>
    );
}

export default PerformerMainDetailsNavigation;
