import Fetch from '@utils/Fetch';

function constructURL(baseUrl, path, queryParams) {
    const url = new URL(`${baseUrl}/${path}`);
    url.search = new URLSearchParams(queryParams);
    return url;
}

function setupRequestOptions(bodyParams, method = 'POST') {
    return {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyParams),
    };
}

export async function handlePostRequest({
    baseUrl,
    path,
    queryParams,
    bodyParams,
    successMessage = 'Operation successful',
    errorMessage = 'Operation failed',
    pendingMessage,
    withToasts = true,
}) {
    if (!queryParams.claimMethod) queryParams.claimMethod = 'AGENT';

    if (bodyParams.isrcs) {
        bodyParams = {
            ...bodyParams,
            isrcs: bodyParams.isrcs.split(',').map((item) => item.trim()),
        };
    }

    const url = constructURL(baseUrl, path, queryParams);
    const options = setupRequestOptions(bodyParams);

    return withToasts
        ? Fetch.fetchWithToasts({
              url,
              options,
              successMessage,
              errorMessage,
              pendingMessage,
          })
        : Fetch.fetchWithErrorToast({
              url: url.toString(),
              options,
          });
}
