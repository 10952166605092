import React from 'react';

export default function WarningBlackSvg(props) {
    return (
        <div className={props.styles}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="#000000DE"
            >
                <path d="M9 4.8675L14.6475 14.625H3.3525L9 4.8675ZM9 1.875L0.75 16.125H17.25L9 1.875ZM9.75 12.375H8.25V13.875H9.75V12.375ZM9.75 7.875H8.25V10.875H9.75V7.875Z" />
            </svg>
        </div>
    );
}
