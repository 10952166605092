import React, { useState } from 'react';
import { Button as MuiButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const useStyles = makeStyles({
    menuItem: {
        padding: '0px 16px',
        height: '48px',
    },
    centerIcon: {
        display: 'flex',
    },
    iconColor: {
        '& svg': {
            fill: (props) => props.color || 'black',
        },
        marginRight: '8px',
    },
});

export default function CommonDropDownButtonWithFixedLabel({
    btnTitle,
    manualAlertsItemsList,
    customStyles,
    selectedValue,
    setSelectedValue,
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const classes = useStyles({ color: selectedValue?.color });

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedValue(null);
    };

    const onItemClick = (item) => {
        handleClose();
        setSelectedValue(item);
        if (item.onClickClb) item.onClickClb();
    };

    return (
        <>
            {selectedValue ? (
                <div style={{ display: 'flex' }}>
                    <div className={classes.iconColor}>
                        {selectedValue.coloredIcon && selectedValue.coloredIcon}
                    </div>
                    <Typography style={{ color: selectedValue.color }} variant="subtitle2">
                        {selectedValue.title}
                    </Typography>
                </div>
            ) : (
                <div>
                    <MuiButton
                        variant={customStyles?.variant ? customStyles.variant : 'text'}
                        onClick={handleOpen}
                        color={customStyles?.color ? customStyles.color : 'default'}
                        className={customStyles.classStyles}
                        disableElevation
                        disableRipple
                    >
                        {btnTitle}
                        <ArrowDropDown style={{ marginRight: '0', marginLeft: '16px' }} />
                    </MuiButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        {manualAlertsItemsList.map((item, ind) => (
                            <MenuItem
                                key={`${ind}-${item.title}`}
                                onClick={() => onItemClick(item)}
                                className={classes.menuItem}
                            >
                                <div style={{ marginRight: '8px' }}>
                                    {item.coloredIcon && item.coloredIcon}
                                </div>

                                <Typography variant="body1" style={{ color: item.color }}>
                                    {item.title}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
        </>
    );
}
