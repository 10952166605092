import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.basePerformerClientUrl();

function getAllPerformersAPI(queryParams = { page: '', size: '' }) {
    const url = new URL(`${CONTROLLER_URL}`);

    url.search = new URLSearchParams({
        ...(queryParams.page ? { page: queryParams.page } : {}),
        ...(queryParams.size ? { size: queryParams.size } : {}),
        ...(queryParams.cmo ? { cmo: queryParams.cmo } : {}),
        ...(queryParams.statuses ? { statuses: queryParams.statuses } : {}),
        ...(queryParams.alerts ? { alerts: queryParams.alerts } : {}),
    });

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get performers list',
    });
}

function getPerformerByIdAPI(id) {
    const url = `${CONTROLLER_URL}/${id}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get performer',
    });
}

function editPerformerAPI(content) {
    const url = `${CONTROLLER_URL}`;

    const options = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Performer edited successfully',
        errorMessage: 'Failed to edit performer',
        pendingMessage: 'Editing performer...',
    });
}

export { getAllPerformersAPI, getPerformerByIdAPI, editPerformerAPI };
