import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { withRouter } from 'react-router-dom';
import { FormControl, Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    textField: {
        margin: '0 0 32px 0',
    },
});

const PerformerDetailsContent = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isDisabled, setIsDisabled] = useState(true);
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const { byClietnId } = performersModel;

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        dispatch(changeStateByNestedKey('performers', 'isEditBtnVisible', true));
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        const { mainDetailsInptValues, isInSaveNewMode, selectedIndex } = performersModel;

        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...mainDetailsInptValues,
                firstName: isInSaveNewMode && selectedIndex < 0 ? '' : byClietnId.firstName,
                aliases: byClietnId.aliases || '',
                tier: byClietnId.tier !== undefined ? byClietnId.tier.toString() : '',
                surname: isInSaveNewMode && selectedIndex < 0 ? '' : byClietnId.lastName,
                artistName: byClietnId.artistNames || '',
                mainArtist: byClietnId.isMainArtist,
                businessMailingAddress: byClietnId.businessMailingAddress,
                businessRegisteredAddress: byClietnId.businessRegisteredAddress,
                sessionArtist: byClietnId.isSessionArtist,
            }),
        );
    }, [byClietnId]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    const handleArtistTypes = (event, key) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [key]: event.target.checked,
            }),
        );
    };

    return (
        <div className="topContainer flex-left">
            <Grid container spacing={4}>
                <Grid item sx={6} md={6}>
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <TextFieldWrapper
                            label="First name(s)"
                            required
                            onChange={(evt) => handleInputChange(evt)}
                            name="firstName"
                            type="text"
                            isDisabled={isDisabled}
                            value={performersModel.mainDetailsInptValues.firstName ?? ''}
                            classNameOpt={classes.textField}
                            inputProps={{ tabIndex: '1' }}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                        />
                        <br />
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '3' }}
                            label="Aliases"
                            onChange={(evt) => handleInputChange(evt)}
                            name="aliases"
                            type="text"
                            isDisabled={isDisabled}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={performersModel.mainDetailsInptValues.aliases ?? ''}
                            classNameOpt={classes.textField}
                        />
                        <br />
                        <TextFieldWrapper
                            label="Business Registered Address"
                            onChange={(evt) => handleInputChange(evt)}
                            name="businessRegisteredAddress"
                            type="text"
                            isDisabled={isDisabled}
                            classNameOpt={classes.textField}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={
                                performersModel.mainDetailsInptValues.businessRegisteredAddress ??
                                ''
                            }
                            inputProps={{ tabIndex: '2' }}
                        />
                        <br />
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '5' }}
                            label="Tier"
                            onChange={(evt) => handleInputChange(evt)}
                            name="tier"
                            type="text"
                            isDisabled={isDisabled}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={performersModel.mainDetailsInptValues.tier ?? ''}
                            classNameOpt={classes.textField}
                        />
                        <br />
                    </FormControl>
                </Grid>
                <Grid item sx={6} md={6}>
                    <FormControl variant="standard" style={{ textAlign: 'left', width: '100%' }}>
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '2' }}
                            label="Surname"
                            required
                            onChange={(evt) => handleInputChange(evt)}
                            name="surname"
                            type="text"
                            isDisabled={isDisabled}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={performersModel.mainDetailsInptValues.surname ?? ''}
                            classNameOpt={classes.textField}
                        />
                        <br />
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '4' }}
                            label="Artist name(s)"
                            onChange={(evt) => handleInputChange(evt)}
                            name="artistName"
                            type="text"
                            isDisabled={isDisabled}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={performersModel.mainDetailsInptValues.artistName ?? ''}
                            classNameOpt={classes.textField}
                        />
                        <br />
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '3' }}
                            label="Business Mailing Address"
                            onChange={(evt) => handleInputChange(evt)}
                            name="businessMailingAddress"
                            type="text"
                            isDisabled={isDisabled}
                            classNameOpt={classes.textField}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={
                                performersModel.mainDetailsInptValues.businessMailingAddress ?? ''
                            }
                        />
                        <br />
                        <FormControlLabel
                            control={<Typography />}
                            label="Artist type"
                            color="primary"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={isDisabled}
                                    onChange={(e) => handleArtistTypes(e, 'mainArtist')}
                                    checked={!!performersModel.mainDetailsInptValues.mainArtist}
                                    color="primary"
                                    tabIndex="6"
                                />
                            }
                            label="Main artist"
                            color="primary"
                        />
                        <FormControlLabel
                            color="primary"
                            control={
                                <Checkbox
                                    color="primary"
                                    onChange={(e) => handleArtistTypes(e, 'sessionArtist')}
                                    disabled={isDisabled}
                                    tabIndex="7"
                                    checked={!!performersModel.mainDetailsInptValues.sessionArtist}
                                />
                            }
                            label="Session artist"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
};

const withUrlPerformerDetailsContent = withRouter(PerformerDetailsContent);
export default connect((r) => r)(withUrlPerformerDetailsContent);
