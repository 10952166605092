import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseAssetUrl();

function getAllReportsAPI() {
    const url = `${CONTROLLER_URL}/report/import-report`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get reports',
    });
}

function getReportByIdAPI(id) {
    const url = `${CONTROLLER_URL}/report/import-report/${id}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get report',
    });
}

export { getAllReportsAPI, getReportByIdAPI };
