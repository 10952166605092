import React from 'react';

export default function InfoIconSvg(props) {
    return (
        <div className={props.styles}>
            <svg height="20px" viewBox="2 2 20 20" width="20px" fill="rgba(0, 0, 0, 0.6)">
                <path d="M0 0h20v20H0V0z" fill="none" />
                <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            </svg>
        </div>
    );
}
