import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    makeStyles,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { deleteClientDealPerfOrRH } from '@actions/fugaActions';

const useStyles = makeStyles((theme) => ({
    titile: {
        padding: '19px 16px',
    },
    content: {
        padding: '10px 16px',
    },
}));

function DeleteClientDealDialog({ isOpen, closeCb, dataItem, isPerformer }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
    };

    const handleConfirm = () => {
        const isPerf = isPerformer ? true : false;
        dispatch(
            deleteClientDealPerfOrRH(
                {
                    clientId: dataItem.clientId,
                    clientDealId: dataItem.clientDealId,
                },
                isPerf,
            ),
        );
        handleClose();
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={open} maxWidth="md">
                <DialogTitle onClose={handleClose} className={`${classes.titile}`}>
                    Delete Client Deal?
                </DialogTitle>
                <DialogContent className={`${classes.content}`}>
                    <Typography variant="body1">
                        This will remove this Deal from the system, do you want to proceed?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="primary"
                        disableElevation
                    >
                        cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleConfirm}
                    >
                        delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteClientDealDialog;
