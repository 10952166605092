import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import {
    withStyles,
    Input,
    Popover,
    Paper,
    Button,
    ClickAwayListener,
    List,
    ListItem,
    createStyles,
    ListSubheader,
    makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from '@styling/muiThemes/FugaMainStyles';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { getRightHoldersList } from '@actions/rightHolders';

const useStyles = makeStyles(() => ({
    listItem: {
        paddingTop: '20px',
        paddingBottom: '18px',
    },
}));

const withLookupStyles = withStyles((theme) =>
    createStyles({
        button: {
            fill: theme.palette.text.secondary,
        },
        list: {
            minHeight: 80,
            maxHeight: 300,
            overflowY: 'auto',
            margin: '8px 0px',
        },
    }),
);

const useSearchTerm = (open) => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');

    // when open is set to false (close modal), clean up state
    useEffect(() => {
        if (!open) {
            setSearchTerm('');
        }
    }, [open]);

    // component unmount clean up state
    useEffect(() => () => setSearchTerm(''), []);

    useEffect(() => {
        dispatch(getRightHoldersList(searchTerm));
    }, [searchTerm]);

    return { setSearchTerm, searchTerm };
};

let LookupContent = ({ onClose, open, width }) => {
    const inputRef = useRef(null);
    const [areSuggestedItemsShown, setAreSuggestedItemsShown] = useState(false);
    const [goToClientPage, setGoToClientPage] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    // use search capabilities with debouncing & clean up of state
    const { setSearchTerm, searchTerm } = useSearchTerm(open);
    const clientsModel = useSelector((state) => state.fugaReducers.clients);
    const clientPageModel = useSelector((state) => state.fugaReducers.clientPage);

    useEffect(() => {
        if (clientsModel.suggestedClients && clientsModel.suggestedClients.length) {
            setAreSuggestedItemsShown(true);
        } else if (clientsModel.suggestedClients.length === 0 || searchTerm) {
            setAreSuggestedItemsShown(false);
        }
    }, [clientsModel.suggestedClients]);

    // allows input search to be focused when modal opens
    // need for timeout to wait animation to finish
    useLayoutEffect(() => {
        setTimeout(() => {
            inputRef.current && inputRef.current.focus();
        }, 50);
    }, [width, open]);

    const handleAddNew = () => {
        dispatch(changeStateByNestedKey('clientPage', 'isInSaveNewMode', true));
        if (clientsModel.suggestedClients.length <= 0 && searchTerm) {
            dispatch(
                changeStateByNestedKey('clientPage', 'data', { legalCompanyName: 'New Client' }),
            );
            setGoToClientPage(true);
        }
        if (clientPageModel.selectedIndex >= 0) {
            setGoToClientPage(true);
        }
    };

    // when user selects an option triggers callback and close modal
    function onSelectOption(event, index) {
        if (!open) return;
        const name = event.currentTarget.getAttribute('value');
        dispatch(changeStateByNestedKey('clientPage', 'selectedIndex', index));
        dispatch(changeStateByNestedKey('clientPage', 'data', { legalCompanyName: name }));
        handleAddNew();
    }

    return (
        <Popover
            open={open}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 260, left: window.innerWidth - 200 }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <ClickAwayListener onClickAway={onClose}>
                <Paper
                    style={{
                        padding: '8px 0 8px 16px',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '383px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '334px',
                            height: '330px',
                            overflow: 'auto',
                        }}
                    >
                        {
                            <Input
                                placeholder="Type to search ..."
                                inputProps={{ autoComplete: 'false' }}
                                inputRef={inputRef}
                                onChange={(evt) => setSearchTerm(evt.target.value)}
                                style={{ width: '300px' }}
                            />
                        }
                        <List>
                            {areSuggestedItemsShown && (
                                <ListSubheader disableSticky> Suggested </ListSubheader>
                            )}
                            {areSuggestedItemsShown &&
                                clientsModel.suggestedClients.map((item, index) => {
                                    return (
                                        <ListItem
                                            className={classes.listItem}
                                            button
                                            key={item.rightsHolderId}
                                            value={item.rightsHolderName}
                                            selected={clientPageModel.selectedIndex === index}
                                            onClick={(e) => onSelectOption(e, index)}
                                        >
                                            {item.rightsHolderName}
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </div>
                    <div
                        className="flex-horizontal"
                        style={{
                            width: '90%',
                            bottom: 0,
                            position: 'absolute',
                            marginBottom: '8px',
                        }}
                    >
                        <ThemeProvider theme={theme}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleAddNew}
                                disableElevation
                            >
                                ADD AS NEW
                            </Button>
                            {goToClientPage ? <Redirect to="/client-page/-1" /> : null}
                            <Button color="primary" onClick={onClose} disableElevation>
                                CANCEL
                            </Button>
                        </ThemeProvider>
                    </div>
                </Paper>
            </ClickAwayListener>
        </Popover>
    );
};

LookupContent = withLookupStyles(LookupContent);

let Lookup = (props) => {
    const { label, open: initialOpen, options, createAction } = props;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    function makeClose() {
        setOpen(false);
        dispatch(changeStateByNestedKey('clients', 'isNewClientModalOpen', false));
    }

    useLayoutEffect(() => {
        if (initialOpen === true) {
            setOpen(true);
        } else if (initialOpen === false) {
            setOpen(false);
        }
    }, [initialOpen]);

    return (
        <>
            <LookupContent
                onClose={makeClose}
                label={label}
                open={open}
                options={options}
                createAction={createAction}
            />
        </>
    );
};

Lookup = withStyles((theme) => ({
    label: {
        cursor: 'pointer',
        width: '100%',
    },
    input: {
        width: '100%',
        cursor: 'pointer',
        'caret-color': 'transparent',
    },
    root: {
        width: '334px',
        height: '383px',
        marginBottom: 10,
    },
    required: {
        '& span': {
            color: `${theme.palette.warning.dark} !important`,
        },
    },
}))(Lookup);

export default Lookup;
