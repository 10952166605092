import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { withRouter } from 'react-router-dom';
import { FormControl, Grid } from '@material-ui/core';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';

const PerformerBillingContent = (props) => {
    const dispatch = useDispatch();
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const { byClietnId } = performersModel;
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                bankAccNum: byClietnId.bankAccountNumber || '',
                bankCity: byClietnId.bankCity || '',
                iban: byClietnId.bankIbanNumber || '',
                bankName: byClietnId.bankName || '',
                bankAccPayee: byClietnId.bankAccountPayee || '',
                bankCountry: byClietnId.bankCountry || '',
                bankSwiftNumber: byClietnId.bankSwiftNumber || '',
            }),
        );
    }, [performersModel.byClietnId]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <>
            <div className="topContainer flex-left">
                <Grid container spacing={4}>
                    <Grid item sx={6} md={6}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <TextFieldWrapper
                                label="Bank account number"
                                onChange={(evt) => handleInputChange(evt)}
                                name="bankAccNum"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.bankAccNum
                                        ? performersModel.mainDetailsInptValues.bankAccNum
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                                inputProps={{ tabIndex: '21' }}
                            />
                            <br />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '23' }}
                                label="Bank city"
                                onChange={(evt) => handleInputChange(evt)}
                                name="bankCity"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.bankCity
                                        ? performersModel.mainDetailsInptValues.bankCity
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <br />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '25' }}
                                label="Bank IBAN number"
                                onChange={(evt) => handleInputChange(evt)}
                                name="iban"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.iban
                                        ? performersModel.mainDetailsInptValues.iban
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <br />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '27' }}
                                label="Bank name"
                                onChange={(evt) => handleInputChange(evt)}
                                name="bankName"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.bankName
                                        ? performersModel.mainDetailsInptValues.bankName
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={6} md={6}>
                        <FormControl
                            variant="standard"
                            style={{ textAlign: 'left', width: '100%' }}
                        >
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '22' }}
                                label="Bank account payee"
                                onChange={(evt) => handleInputChange(evt)}
                                name="bankAccPayee"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.bankAccPayee
                                        ? performersModel.mainDetailsInptValues.bankAccPayee
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <br />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '24' }}
                                label="Bank country"
                                onChange={(evt) => handleInputChange(evt)}
                                name="bankCountry"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.bankCountry
                                        ? performersModel.mainDetailsInptValues.bankCountry
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <br />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '26' }}
                                label="Bank Swift number"
                                onChange={(evt) => handleInputChange(evt)}
                                name="bankSwiftNumber"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.bankSwiftNumber
                                        ? performersModel.mainDetailsInptValues.bankSwiftNumber
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const withUrlPerformerBillingContent = withRouter(PerformerBillingContent);
export default connect((r) => r)(withUrlPerformerBillingContent);
