import React, { useState } from 'react';
import { makeStyles, Menu, MenuItem, IconButton, Tooltip } from '@material-ui/core';
import { MoreVertSharp as MoreVert } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    moreIcon: {
        width: '18px',
        height: '18px',
        marginRight: '0',
        color: '#5F5F60',
    },
    moreIconBtn: {
        padding: '8px',
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
    iconWraper: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default function CommonMoreDropDownButton({ menuItems }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={(e) => handleOpen(e)}
                disableRipple
                className={classes.moreIconBtn}
            >
                <MoreVert className={`${classes.moreIcon}`} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {menuItems.map((el, ind) => {
                    return (
                        <Tooltip
                            key={`${el.title}-${ind}`}
                            title={el?.tooltip?.text}
                            disableHoverListener={!el?.tooltip?.visible}
                        >
                            <div>
                                <MenuItem
                                    onClick={() => {
                                        el.onClickClb();
                                        handleClose();
                                    }}
                                    disabled={el.isDisabled}
                                >
                                    <div className={classes.iconWraper}>
                                        {el.icon ? (
                                            <div style={{ marginRight: '16px' }}>{el.icon}</div>
                                        ) : (
                                            <></>
                                        )}
                                        <div>{el.title}</div>
                                    </div>
                                </MenuItem>
                            </div>
                        </Tooltip>
                    );
                })}
            </Menu>
        </>
    );
}
