/* eslint-disable indent */
import fugaState from '@state/fugaState';

const updateNestedFieldCb = (updatedState, action) => {
    const { arrayName, idFieldName, id, fieldNameToChange } = action.params;
    const arrayToWorkOn = updatedState[action.key1][action.key2][arrayName];
    const foundElementInd = arrayToWorkOn.findIndex((el) => el[idFieldName] === id);
    if (foundElementInd > -1) {
        arrayToWorkOn[foundElementInd][fieldNameToChange] = action.val;
    }
};

function userReducer(state = fugaState, action) {
    let updatedState = { ...state };
    switch (action.type) {
        case 'changeStateByNestedKey':
            updatedState[action.key1][action.key2] = action.val;
            break;
        case 'updateStateByNestedKey':
            let tmpData = [];
            if (Array.isArray(updatedState[action.key1][action.key2])) {
                tmpData = updatedState[action.key1][action.key2].concat(action.val);
            }
            // TODO update object case
            // else if (typeof updatedState[action.key1][action.key2] === 'object') {
            //     tmpData = {...updatedState[action.key1][action.key2], action.keyName}
            // }
            updatedState[action.key1][action.key2] = tmpData;
            break;
        case 'appendToStateByNestedKey':
            updatedState[action.key1][action.key2].push(action.val);
            break;
        case 'changeStateByKey':
            updatedState[action.key] = action.val;
            break;
        case 'setError':
            updatedState.error = action.msg;
            break;
        case 'deleteByIndex':
            updatedState[action.key1][action.key2].splice(action.index, 1);
            break;
        case 'forceUpdateByNestedKey':
            const tempValue = updatedState[action.key1][action.key2];
            updatedState[action.key1][action.key2] = [...tempValue];
            break;
        case 'updateNestedField':
            updateNestedFieldCb(updatedState, action);
            break;
        default:
            break;
    }

    return updatedState;
}

export default userReducer;
