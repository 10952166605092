import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseCmoUrl();

function getStatusHistoryByCmoRegIdAPI(cmoRegId) {
    const url = `${CONTROLLER_URL}/registration-history/${cmoRegId}`;

    return Fetch.fetchWithErrorToast({
        url,
    });
}

function getCmoByIdAPI(cmoId) {
    const url = `${CONTROLLER_URL}/${cmoId}`;

    return Fetch.fetchWithErrorToast({ url });
}

function getAllCmosAPI(queryParams) {
    const queryParam = queryParams.isPerformer
        ? '?type=PERFORMER'
        : queryParams.isRH
          ? '?type=RIGHTS_HOLDER'
          : '';

    const url = `${CONTROLLER_URL}${queryParam}`;

    return Fetch.fetchWithErrorToast({
        url,
    });
}

function deleteCmoRegistrationAPI(regId) {
    const url = `${CONTROLLER_URL}/delete-client-cmo-registration/${regId}`;

    const options = {
        method: 'DELETE',
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'CMO registration deleted successfully',
        errorMessage: 'Failed to delete CMO registration',
        pendingMessage: 'Deleting CMO registration...',
    });
}

function changeRegistrationStatusAPI(content) {
    const url = `${CONTROLLER_URL}/change-reg-state`;

    const options = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Registration status changed successfully',
        errorMessage: 'Failed to change registration status',
        pendingMessage: 'Changing registration status...',
    });
}

export {
    getCmoByIdAPI,
    getAllCmosAPI,
    deleteCmoRegistrationAPI,
    changeRegistrationStatusAPI,
    getStatusHistoryByCmoRegIdAPI,
};
