class UtilsGeneral {
    /**
     * Function for decrypting base64 data to string
     * @param {String} data - value that should be decoded
     * @returns {String} decoded string
     */
    decodeBase64(data) {
        // Create a function to decode base64 to a string using TextDecoder
        const bytes = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
        return new TextDecoder('utf-8').decode(bytes);
    }

    /**
     * Function for encrypting string to base64
     * @param {String} pattern - value that should be encoded
     * @returns {String} encoded string
     */
    encodeBase64(pattern) {
        // Create a function to encode a string to base64 using TextEncoder
        const bytes = new TextEncoder().encode(pattern);
        return btoa(String.fromCharCode(...bytes));
    }
}

export default new UtilsGeneral();
