import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import CmoListPage from '@pages/CmoListPage';
import Header from '@common/Header';
import ClientPage from '@pages/ClientPage/ClientPage';
import ClientsList from '@pages/ClientsListPage/ClientsList';
import CmoPage from '@pages/CmoPage/CmoPage';
import PerformersList from '@pages/PerformersListPage/PerformersList';
import ClientCmoSelectionContainer from '@pages/ClientCmoSelectionPage/ClientCmoSelectionContainer';
import { ThemeProvider } from '@material-ui/core/styles';
import themeForNR from './styling/muiThemes/FugaMainStyles';
import themeForLogin from './styling/muiThemes/LoginMainStyles';
import InfoMessage from '@common/InfoMessage';
import PerformerPage from '@pages/PerformerPage/PerformerPage';
import LoginMainPage, { LoginSteps } from '@pages/LoginPage/LoginMainPage';
import ProtectedRoute from '@common/ProtectedRoute';
import { pages } from './data/constants';
import cerberusUtils from './utils/cerberusUtils';
import UserManagementPage from '@pages/UserManagementPage';
import FullScreenLoader from '@common/FullScreenLoader';
import MaintenancePage from '@pages/MaintenancePage';
import HealthCheckPage from '@pages/HealthCheckPage/HealthCheckPage';
import { APP_ROUTES } from './utils/navigation';
import { ToastContainer } from 'react-toastify';
const { getUserRole, getCerberusToken } = cerberusUtils;

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function LoginContainer() {
    //different theme provider
    return (
        <ThemeProvider theme={themeForLogin}>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route exact path="/login" render={() => <LoginMainPage step={LoginSteps.login} />} />
            <Route
                exact
                path="/reset_password"
                render={() => <LoginMainPage step={LoginSteps.resetPassword} />}
            />
            <Route
                path="/update_password"
                render={() => <LoginMainPage step={LoginSteps.updatePassword} />}
            />
        </ThemeProvider>
    );
}

function DefaultContainer() {
    const role = getUserRole();
    const location = useLocation();

    // ProtectedRoute routine can be done here - if all routes are protected
    if (!getCerberusToken() || !role) {
        return <Redirect to="/login" />;
    }
    return (
        <ThemeProvider theme={themeForNR}>
            <div className="App">
                {!['/login', '/reset_password', '/update_password'].includes(location.pathname) && (
                    <Header />
                )}
                <div className="container">
                    <Route path={APP_ROUTES.CMO_LIST}>
                        <ProtectedRoute role={role} pageName={pages.cmoList}>
                            <CmoListPage />
                        </ProtectedRoute>
                    </Route>
                    <Route path={APP_ROUTES.CMO_SELECTION}>
                        <ProtectedRoute role={role} pageName={pages.clientCmoSelectionContainer}>
                            <ClientCmoSelectionContainer />
                        </ProtectedRoute>
                    </Route>
                    <Route path={APP_ROUTES.CLIENT_PAGE}>
                        <ProtectedRoute role={role} pageName={pages.clientPage}>
                            <ClientPage />
                        </ProtectedRoute>
                    </Route>
                    <Route path={APP_ROUTES.PERFORMER_PAGE}>
                        <ProtectedRoute role={role} pageName={pages.performerPage}>
                            <PerformerPage />
                        </ProtectedRoute>
                    </Route>
                    <Route path={APP_ROUTES.CMO_PAGE}>
                        <ProtectedRoute role={role} pageName={pages.cmoPage}>
                            <CmoPage />
                        </ProtectedRoute>
                    </Route>
                    <Route path={APP_ROUTES.CLIENTS}>
                        <ProtectedRoute role={role} pageName={pages.clientsList}>
                            <ClientsList />
                        </ProtectedRoute>
                    </Route>
                    <Route path={APP_ROUTES.PERFORMERS}>
                        <ProtectedRoute role={role} pageName={pages.performersList}>
                            <PerformersList />
                        </ProtectedRoute>
                    </Route>
                    <Route path={APP_ROUTES.USERS}>
                        <ProtectedRoute role={role} pageName={pages.users}>
                            <UserManagementPage />
                        </ProtectedRoute>
                    </Route>
                    <Route path={APP_ROUTES.MAINTENANCE}>
                        <ProtectedRoute role={role} pageName={pages.maintenance}>
                            <MaintenancePage />
                        </ProtectedRoute>
                    </Route>
                    <Route path={APP_ROUTES.HEALTH}>
                        <HealthCheckPage />
                    </Route>
                    <InfoMessage />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                        transition:Slide
                        className="toast-container-custom"
                    />
                    <FullScreenLoader />
                </div>
            </div>
        </ThemeProvider>
    );
}

// this should allow use to segment the login and remove the app bar but it doesn't
function App() {
    return (
        <BrowserRouter basename="/">
            <Switch>
                <Route
                    exact
                    path={['/login', '/reset_password', '/update_password']}
                    component={LoginContainer}
                />
                <Route component={DefaultContainer} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
