import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { Modal } from 'antd';
import React, { useState } from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        marginLeft: '5px',
        position: 'relative',
    },
    title: { fontWeight: 'bold' },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: 'white',
    },
    actionBtnLoading: {
        height: '34px',
        color: theme.palette.grey.content,
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
    },
}));

export function BulkUpdatesModal({ open, onCancel, onClick, title, bodyText }) {
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const handleClick = async () => {
        setLoading(true);
        await onClick();
        setLoading(false);
    };

    const handleClose = () => {
        onCancel();
    };

    return (
        <Modal
            title={<span className={classes.title}>{title}</span>}
            open={open}
            okText="Yes"
            onCancel={handleClose}
            footer={[
                <Button variant="outlined" onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>,
                <Button
                    variant="contained"
                    color="primary"
                    className={classNames(classes.actionBtn, {
                        [classes.actionBtnLoading]: loading,
                    })}
                    onClick={handleClick}
                    disabled={loading}
                >
                    {!loading && 'Confirm Update'}
                    {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
                </Button>,
            ]}
        >
            {bodyText ? (
                ReactHtmlParser(bodyText)
            ) : (
                <div className={classes.loaderContainer}>
                    <CircularProgress />
                </div>
            )}
        </Modal>
    );
}
