import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseRHUrl();
const baseURL = userData.api.baseUrl;

function getRightHoldersListAPI(searchTerm, clientConnected = true) {
    const url = `${CONTROLLER_URL}/all?filterClientConnected=${clientConnected}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get rights holders list',
    });
}

function createRightHolderAPI(rhName) {
    const url = `${CONTROLLER_URL}/create-rh`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            rightsHolderName: rhName,
        }),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        errorMessage: 'Failed to create right holder',
        successMessage: 'Right holder successfully created',
        pendingMessage: 'Creating right holder...',
    });
}

function editRightHolderAPI(content) {
    const url = `${baseURL}/rh-client`;

    const options = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        errorMessage: 'Failed to edit right holder',
        successMessage: 'Right holder successfully edited',
        pendingMessage: 'Editing right holder...',
    });
}

export { getRightHoldersListAPI, editRightHolderAPI, createRightHolderAPI };
