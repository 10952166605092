import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    makeStyles,
    Grid,
    TextField,
    withStyles,
    Typography,
    Link,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
    agreeTermsAndConditions,
    cleanLoginMessages,
    login,
    resetPassword,
    updatePassword,
} from '@actions/authActions';
import utilsGeneral from '@utils/utilsGeneral';
import cerberusUtils from '@utils/cerberusUtils';

// import promoImage from '@images/branding/promoimage.png';
import dwtLoginImage from '@images/branding/dt_nr_login_image.jpg';
import brandedTitle from '@images/branding/brandedtitle.png';
import { useHistory, useLocation } from 'react-router-dom';
import { changeStateByKey } from '@actions/fugaActions';
import InfoMessage from '@common/InfoMessage';
// import PrivacyPolicyModal from './PrivacyPolicyModal';
import TermsAndConditions from './TermsAndConditions';
import MarketingModule from './MarketingModule';

const useBrandedTitleStyles = makeStyles(() => ({
    container: {
        marginBottom: 65,
    },
    logo: {
        width: 250,
        height: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const BrandedTitle = () => {
    const classes = useBrandedTitleStyles();

    return (
        <div className={classes.container}>
            <img className={classes.logo} src={brandedTitle} />
        </div>
    );
};

/*
 * Form for login - authentication
 */
const LoginForm = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    // const [openPrivPolModal, setOpenPrivPolModal] = useState(false);

    // this shouldn't be wired with redux.
    const errorMessage = useSelector((state) => state.fugaReducers.loginErrorMsg);
    const dispatch = useDispatch();

    function onChangeUsername(evt) {
        dispatch(cleanLoginMessages());
        setUsername(evt.target.value);
    }
    function onChangePassword(evt) {
        dispatch(cleanLoginMessages());
        setPassword(evt.target.value);
    }

    function onLoginAction() {
        onLogin(username, password);
    }

    function handleSubmit(evt) {
        evt.preventDefault();
        onLoginAction();
    }

    function handleKeyPress(evt) {
        if (evt.key.toString() === 'Enter') {
            handleSubmit(evt);
        }
    }

    // function handlePrivPolClick() {
    //     setOpenPrivPolModal(true);
    // }

    return (
        <>
            <form name="login" onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h5" align="justify">
                            Log in
                        </Typography>
                    </Grid>
                    {errorMessage && (
                        <Grid item>
                            <Alert severity="error" variant="outlined">
                                {errorMessage}
                            </Alert>
                        </Grid>
                    )}
                    <Grid item>
                        <TextField
                            label="Username"
                            type="text"
                            fullWidth
                            value={username}
                            onChange={onChangeUsername}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={onChangePassword}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            fullWidth
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            type="submit"
                        >
                            Log in
                        </Button>
                    </Grid>
                    <Grid item>
                        <Link
                            href="/reset_password"
                            variant="body2"
                            align="justify"
                            color="inherit"
                            style={{ marginBottom: 24 }}
                        >
                            Forgot your password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            variant="body2"
                            align="justify"
                            color="inherit"
                            href="https://fuga.com/privacypolicy/"
                            target="_blank"
                            // onClick={handlePrivPolClick}
                        >
                            Privacy Policy
                        </Link>
                    </Grid>
                </Grid>
            </form>
            {/* Disabled and redirected to privacy policy page in new tab in scope of NR-1426 */}
            {/* <PrivacyPolicyModal isOpen={openPrivPolModal} setOpen={setOpenPrivPolModal} /> */}
        </>
    );
};

/*
 * Form for send email for reseting the password
 */

//RFC 2822 standard email validation
const REGEX_VALID_STANTARD =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function validEmail(email) {
    if (REGEX_VALID_STANTARD.test(email)) {
        return true;
    }
    return false;
}

const NOT_VALID_EMAIL = 'Please enter a valid email.';

const ResetPasswordForm = ({ onResetPassword }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    // this shouldn't be wired with redux.
    const dispatch = useDispatch();
    const errorMessage = useSelector((state) => state.fugaReducers.loginErrorMsg);
    const successMessage = useSelector((state) => state.fugaReducers.loginSuccessMsg);

    function onChangeUsername(evt) {
        const value = evt.target.value;
        dispatch(cleanLoginMessages());
        setEmail(value);
    }

    function onResetPasswordAction() {
        onResetPassword(email);
    }

    function handleSubmit(evt) {
        evt.preventDefault();
        onResetPasswordAction();
    }

    function handleKeyPress(evt) {
        if (evt.key.toString() === 'Enter') {
            if (validEmail(email) && email !== '') {
                setError(false);
                handleSubmit(evt);
            } else {
                setError(true);
                setHelperText(NOT_VALID_EMAIL);
            }
        }
    }

    return (
        <form name="resetPassword" onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5" align="justify">
                        Reset Password
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" align="left">
                        Enter your username and we will send you a link to reset your password
                    </Typography>
                </Grid>
                {errorMessage && (
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            {errorMessage}
                        </Alert>
                    </Grid>
                )}
                {successMessage && (
                    <Grid item>
                        <Alert severity="success" variant="outlined">
                            {successMessage}
                        </Alert>
                    </Grid>
                )}
                <Grid item>
                    <TextField
                        error={error}
                        label="Username"
                        type="text"
                        fullWidth
                        value={email}
                        onChange={onChangeUsername}
                        helperText={error && helperText}
                    />
                </Grid>
                <Grid item>
                    <Button
                        disabled={error}
                        fullWidth
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        type="submit"
                    >
                        Request reset link
                    </Button>
                </Grid>
                <Grid item>
                    <Link
                        href="/login"
                        variant="body2"
                        align="justify"
                        color="inherit"
                        style={{ marginBottom: 24 }}
                    >
                        Back to login page
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

const updatePasswordStyles = makeStyles(() => ({
    alertContainer: {
        '& .MuiAlert-icon': {
            display: 'none',
        },
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
}));

// user email
const NOT_VALID_CONFIRMATION_PASSWORD = 'Passwords do not match.';
// const NOT_VALID_FORMAT_PASSWORD = 'Password should be at least 6 characters long';

const UpdatePasswordForm = ({ onUpdatePassword }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorConfimationPassword, setErrorConfirmPassword] = useState(false);
    const [errorNewPassword, setErrorNewPassword] = useState(false);
    const dispatch = useDispatch();
    const errorMessage = useSelector((state) => state.fugaReducers.loginErrorMsg);
    const classes = updatePasswordStyles();

    const { search } = useLocation();

    const query = new URLSearchParams(search);
    const id = query.get('id');
    const key = query.get('key');

    const validatePassword = (passwordText) => {
        // regex matching all the criterias mentioned on lines 433-446
        const VALID_PASSWD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*_+,.'"]).{12,}$/;
        return VALID_PASSWD_REGEX.test(passwordText);
    };

    function onBlurNewPassword() {
        if (!newPassword || validatePassword(newPassword)) {
            setErrorNewPassword(false);

            if ((confirmPassword && confirmPassword === newPassword) || !confirmPassword) {
                setErrorConfirmPassword(false);
            } else {
                setErrorConfirmPassword(true);
            }
        } else {
            setErrorNewPassword(true);
        }
    }

    function onBlurConfirmPassword() {
        if (confirmPassword === newPassword || !confirmPassword) {
            setErrorConfirmPassword(false);
        } else {
            setErrorConfirmPassword(true);
        }
    }

    function onChangeNewPassword(evt) {
        dispatch(cleanLoginMessages());
        setNewPassword(evt.target.value);
    }
    function onChangeConfirmPassword(evt) {
        dispatch(cleanLoginMessages());
        setConfirmPassword(evt.target.value);
    }

    function onUpdatePasswordAction() {
        onUpdatePassword(newPassword, id, key);
    }

    function handleSubmit(evt) {
        evt.preventDefault();
        onUpdatePasswordAction();
    }

    function handleKeyPress(evt) {
        if (evt.key.toString() === 'Enter') {
            evt.target.blur();
            if (confirmPassword === newPassword && !!confirmPassword && !!newPassword) {
                handleSubmit(evt);
            }
        }
    }

    return (
        <form name="updatePassword" onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5" align="justify">
                        Reset Password
                    </Typography>
                </Grid>
                {errorMessage && (
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            {errorMessage}
                        </Alert>
                    </Grid>
                )}
                <Grid item>
                    <TextField
                        // error={errorNewPassword}
                        label="New password"
                        type="password"
                        fullWidth
                        value={newPassword}
                        onChange={onChangeNewPassword}
                        onBlur={onBlurNewPassword}
                        // helperText={errorNewPassword && NOT_VALID_FORMAT_PASSWORD}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        error={errorConfimationPassword}
                        label="Re-enter new password"
                        type="password"
                        fullWidth
                        value={confirmPassword}
                        onChange={onChangeConfirmPassword}
                        onBlur={onBlurConfirmPassword}
                        helperText={errorConfimationPassword && NOT_VALID_CONFIRMATION_PASSWORD}
                    />
                </Grid>
                {errorNewPassword && (
                    <Grid item>
                        <Alert
                            severity="error"
                            variant="outlined"
                            className={classes.alertContainer}
                        >
                            <Typography variant="body2">
                                - Password must be 12 characters minimum
                            </Typography>
                            <Typography variant="body2">
                                - Password must contain at least one alphabetical character
                            </Typography>
                            <Typography variant="body2">
                                - Password must contain at least one digit (0-9)
                            </Typography>
                            <Typography variant="body2">
                                {`
                                - Password must contain at least one special character (!@#$%^&*_+-,.')
                                `}
                            </Typography>
                        </Alert>
                    </Grid>
                )}
                <Grid item>
                    <Button
                        disabled={
                            errorConfimationPassword ||
                            errorNewPassword ||
                            !newPassword ||
                            !confirmPassword
                        }
                        fullWidth
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        type="submit"
                    >
                        Update Password
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

/*
Form, Terms, Forget Password action. Making This working accordling with design real estate
Wire up the functionality
Make use of Typography
*/
export const LoginSteps = {
    login: 'login',
    resetPassword: 'resetPassword',
    updatePassword: 'updatePassword',
};

const LoginSection = ({ step, onLogin, onResetPassword, onUpdatePassword }) => {
    //which step are we in
    const isLogin = step === LoginSteps.login;
    const isResetPassword = step === LoginSteps.resetPassword;
    const isUpdatePassword = step === LoginSteps.updatePassword;

    return (
        <div style={{ width: '280px' }}>
            <BrandedTitle />
            {isLogin && <LoginForm onLogin={onLogin} />}
            {isResetPassword && <ResetPasswordForm onResetPassword={onResetPassword} />}
            {isUpdatePassword && <UpdatePasswordForm onUpdatePassword={onUpdatePassword} />}
        </div>
    );
};

const WhiteTypography = withStyles({
    root: {
        color: '#FFFFFF',
    },
})(Typography);

// const usePromoPanelStyles = makeStyles((theme) => ({
//     background: {
//         backgroundImage: (props) => `url(${props.image})`,
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center center',
//         height: '100vh',
//         width: '100%',
//     },
//     textSection: {
//         paddingLeft: '101px',
//         paddingRight: '101px',
//         textAlign: 'left',
//         color: theme.palette.text.secondary,
//         position: 'absolute',
//         top: '50%',
//         transform: 'translateY(-50%)',
//     },
// }));

// This part was replaced with marketing panel on NR-1027
// const PromoPanel = ({ image, title, text }) => {
//     const classes = usePromoPanelStyles({ image });

//     return (
//         <div className={classes.background}>
//             <div className={classes.textSection}>
//                 {title && (
//                     <WhiteTypography variant="h5" align="left">
//                         {title}
//                     </WhiteTypography>
//                 )}
//                 <WhiteTypography variant="h5" align="left">
//                     {text}
//                 </WhiteTypography>
//             </div>
//         </div>
//     );
// };

const LoginLandingPage = ({ step }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const updatedPassword = useSelector((state) => state.fugaReducers.updatedPassword);
    const submitTermsAndConditions = useSelector(
        (state) => state.fugaReducers.submitTermsAndConditions,
    );
    const [termsAndConditionsOpen, setTermsAndCondtionsOpen] = useState(false);

    useEffect(() => {
        dispatch(cleanLoginMessages());
    }, [step]);

    useEffect(() => {
        if (updatedPassword) {
            // Keeping the state intact for the message.
            history.push('/login');
            dispatch(changeStateByKey('updatedPassword', false));
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Password successfully updated',
                    severity: 'Success',
                }),
            );
        }
        if (submitTermsAndConditions) {
            setTermsAndCondtionsOpen(true);
        } else {
            setTermsAndCondtionsOpen(false);
        }
    }, [updatedPassword, submitTermsAndConditions]);

    function onLogin(username, password) {
        dispatch(changeStateByKey('loginErrorMsg', ''));
        // copying already existent logic. The dispatch is used to handle error message,
        dispatch(
            login({
                username: utilsGeneral.encodeBase64(username),
                password: utilsGeneral.encodeBase64(password),
            }),
        );
    }

    function onResetPassword(username) {
        dispatch(
            resetPassword({
                username: encodeURIComponent(username),
            }),
        );
    }

    function onUpdatePassword(password, id, key) {
        dispatch(
            updatePassword({
                password: utilsGeneral.encodeBase64(password),
                id,
                key,
            }),
        );
    }

    function onCancelTermsAndConditions() {
        dispatch(changeStateByKey('submitTermsAndConditions', false));
        cerberusUtils.setUserRole('');
        cerberusUtils.setCerberusToken('');
    }

    function onAcceptOnTermsAndConditions() {
        dispatch(agreeTermsAndConditions());
        dispatch(changeStateByKey('submitTermsAndConditions', false));
    }

    return (
        <>
            <Grid container style={{ flexWrap: 'nowrap' }}>
                <Grid
                    item
                    xl={2}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    container
                    alignContent="center"
                    justifyContent="center"
                    alignItems="flex-start"
                    // downtown vintage color
                    // inlined defition - how do we take the color defition here and move to the child keeping the fullwidth size(?)
                    style={{ backgroundColor: '#E1DFD6', maxWidth: '544px' }}
                >
                    <LoginSection
                        step={step}
                        onLogin={onLogin}
                        onResetPassword={onResetPassword}
                        onUpdatePassword={onUpdatePassword}
                    />
                </Grid>
                <Grid item xl={10} lg={8} md={8}>
                    <MarketingModule image={dwtLoginImage} />
                    {/* This part was replaced with marketing panel on NR-1027 */}
                    {/* <PromoPanel
                        image={promoImage}
                        text="Powered by FUGA's end-to-end technology, Downtown Neighbouring Rights offers a bespoke, client-centric service to artists and labels from in-house experts with decades of experience."
                    /> */}
                </Grid>
            </Grid>
            <InfoMessage />
            {termsAndConditionsOpen && (
                <TermsAndConditions
                    onCancel={onCancelTermsAndConditions}
                    onAccept={onAcceptOnTermsAndConditions}
                />
            )}
        </>
    );
};

export default LoginLandingPage;
