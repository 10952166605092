import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseAlertUrl();

function getAlertMsgByCmoRegIdAPI(cmoRegId) {
    const url = `${CONTROLLER_URL}/${cmoRegId}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get alert text',
    });
}

function createAlertMsgByCmoRegIdAPI(content) {
    const url = `${CONTROLLER_URL}`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Alert text created',
        errorMessage: 'Failed to create alert text',
        pendingMessage: 'Creating alert text...',
    });
}

function resolveAlertMsgByCmoRegIdAPI(regAlertId) {
    const url = `${CONTROLLER_URL}/resolve/${regAlertId}`;

    return Fetch.fetchWithToasts({
        url,
        successMessage: 'Alert resolved successfully',
        errorMessage: 'Failed to resolve alert',
        pendingMessage: 'Resolving alert...',
    });
}

function updateAlertMsgByCmoRegIdAPI(content) {
    const url = `${CONTROLLER_URL}`;

    const options = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Alert text updated',
        errorMessage: 'Failed to update alert text',
        pendingMessage: 'Updating alert text...',
    });
}

export {
    createAlertMsgByCmoRegIdAPI,
    updateAlertMsgByCmoRegIdAPI,
    getAlertMsgByCmoRegIdAPI,
    resolveAlertMsgByCmoRegIdAPI,
};
