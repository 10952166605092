import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Button,
    Dialog,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Typography,
    Divider,
} from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByKey, changeStateByNestedKey, loadAllCmos } from '@actions/fugaActions';
import CommonDropDownInput from '@common/CommonDropDownInput';
import { Alert } from '@material-ui/lab';
import { createOrUpdateCmoRegistration } from '@actions/apiActions';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { getCmoIssuedIdsByClientId } from '@actions/cmoIssuedId';
import { createCmoIssuedIdAPI, editCmoIssuedIdAPI } from '@api/clientController';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
    },
    collapsed: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    listItem: {
        borderBottom: '1px solid #DEDEE0',
    },
    checkboxes: {
        padding: '0 8px 0 0',
    },
    errorBanner: {
        backgroundColor: '#FFEBEE',
    },
}));

const RegionItem = ({
    territorySelection,
    territories,
    setTerritories,
    region,
    classes,
    isDisabled,
}) => {
    const [collapsed, setCollapsed] = useState(true);

    const countSelected = () => {
        if (territories.length === 0 && Object.keys(territories).some((key) => key.includes('0')))
            return 0;
        return territorySelection.filter((territory) => {
            return (territories[region.id] || []).includes(territory.id);
        }).length;
    };

    const handleUpdateTerritories = (territoryCode) => {
        if ((territories[region.id] || []).includes(territoryCode)) {
            setTerritories({
                ...territories,
                [region.id]: (territories[region.id] || []).filter(
                    (territory) => territory !== territoryCode,
                ),
            });
        } else {
            setTerritories({
                ...territories,
                [region.id]: (territories[region.id] || []).concat(territoryCode),
            });
        }
    };

    const selectAllTerritories = (event) => {
        if (event.target.checked) {
            let allForRegion = territorySelection.map((territory) => territory.id);

            setTerritories({ ...territories, [region.id]: allForRegion });
        } else {
            setTerritories({ ...territories, [region.id]: [] });
        }
    };

    return (
        <List key={region.id} component="div" disablePadding>
            <ListItem disableGutters className={classes.listItem}>
                <Checkbox
                    className={classes.checkboxes}
                    color="primary"
                    checked={territorySelection.every((territory) =>
                        (territories[region.id] || []).includes(territory.id),
                    )}
                    indeterminate={
                        territorySelection.some((territory) =>
                            (territories[region.id] || []).includes(territory.id),
                        ) && (territories[region.id] || []).length !== territorySelection.length
                    }
                    onClick={selectAllTerritories}
                    disabled={isDisabled}
                />
                <ListItemText
                    primary={
                        <Typography variant="subtitle2">
                            {`${region.name} (${
                                territorySelection.length
                            } items, ${countSelected()} selected)`}
                        </Typography>
                    }
                />

                <IconButton
                    onClick={() => setCollapsed(!collapsed)}
                    className={collapsed ? classes.expanded : classes.collapsed}
                    aria-expanded={!collapsed}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </ListItem>

            <Grid container>
                {!collapsed &&
                    territorySelection.map((territory) => {
                        return (
                            <Grid key={territory.id} item xs={4} lg={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(territories[region.id] || []).includes(
                                                territory.id,
                                            )}
                                            onChange={() => handleUpdateTerritories(territory.id)}
                                            name={territory.name}
                                            color="primary"
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={territory.name}
                                />
                            </Grid>
                        );
                    })}
            </Grid>
        </List>
    );
};

const DatesAndClaimMethods = ({
    isEditing,
    selectedStartDate,
    handleStartDateChange,
    selectedEndDate,
    handleEndDateChange,
    errorOperations,
    dateErrMsg,
    isPerformer,
    selectedCmoId,
    cmoIssuedIds,
    issuedId,
    setIssuedId,
    storeName,
    // claimMethod,
    // setClaimMethod,
    isEndDateDisabled,
    isStartDateDisabled,
    claimMethodDefault,
}) => {
    const dispatch = useDispatch();
    const cmosModel = useSelector((state) => state.fugaReducers.cmos);

    useEffect(() => {
        if (!isEditing) {
            handleStartDateChange(moment());
            handleEndDateChange(moment());
        }
    }, [isEditing]);

    useEffect(() => {
        dispatch(loadAllCmos(isPerformer));
    }, []);

    // TODO all cmoIssuedIds restricted lines will be uncommented in scope of NR-603
    useEffect(() => {
        const foundCmo = cmoIssuedIds.find((el) => {
            return el.cmoId === selectedCmoId;
        });
        if (foundCmo) {
            setIssuedId(foundCmo.cmoIssuedIdValue);
            dispatch(changeStateByNestedKey(storeName, 'hasIssuedId', true));
        } else {
            setIssuedId('');
            dispatch(changeStateByNestedKey(storeName, 'hasIssuedId', false));
        }
    }, [selectedCmoId]);

    const handleIssuedIdChange = (evt) => {
        setIssuedId(evt.currentTarget.value);
    };

    // const handleRadioChange = (event) => {
    //     setClaimMethod(event.target.value);
    // };

    const menuItemsList = cmosModel.dataFromBE.map((cmo) => ({
        title: `${cmo.cmoName} ${cmo.hometerritoryFullname}`,
        cmoId: cmo.cmoId,
    }));

    return (
        <>
            <CommonDropDownInput
                menuItemsList={menuItemsList}
                isEditing={isEditing}
                storeName={storeName}
            />
            <TextFieldWrapper
                label="CMO issued ID"
                onChange={(evt) => handleIssuedIdChange(evt)}
                name="cmoIssuedId"
                type="text"
                // Add shrink in case label overlaps the value
                // InputLabelProps={{ shrink: shrink }}
                inputProps={{ style: { width: '482px' } }}
                value={issuedId}
                style={{ margin: '0 0 32px 0' }}
                // disabled={storeName === 'rhData'}
                isDisabled={storeName === 'rhData'}
                InputProps={{
                    readOnly: storeName === 'rhData',
                }}
            />
            <br />
            <Typography variant="body2" style={{ margin: '0 14px 8.5px 0', display: 'inline' }}>
                Claim method
            </Typography>
            <Typography variant="caption">{claimMethodDefault}</Typography>
            {/* <RadioGroup value={claimMethod} onChange={handleRadioChange}>
                <FormControlLabel
                    value="agent"
                    control={<Radio size="small" color="primary" disabled={isEditing} />}
                    label={<Typography variant="body2">Agent</Typography>}
                />
                <FormControlLabel
                    value="exclusive_license"
                    control={<Radio size="small" color="primary" disabled={isEditing} />}
                    label={<Typography variant="body2">Exclusive license</Typography>}
                />
            </RadioGroup> */}
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <Grid container spacing={4} style={{ marginTop: '16px' }}>
                    <Grid item>
                        <KeyboardDatePicker
                            label="Start Date"
                            value={selectedStartDate}
                            onChange={(date) => handleStartDateChange(date)}
                            format="DD/MM/yyyy"
                            disabled={isStartDateDisabled}
                            helperText={errorOperations.dateError.start?.error ? dateErrMsg : ''}
                            error={errorOperations.dateError.start?.error}
                        />
                    </Grid>
                    <Grid item>
                        <KeyboardDatePicker
                            label="End Date"
                            value={selectedEndDate}
                            onChange={(date) => handleEndDateChange(date)}
                            format="DD/MM/yyyy"
                            helperText={errorOperations.dateError.end?.error ? dateErrMsg : ''}
                            error={errorOperations.dateError.end?.error}
                            disabled={isEndDateDisabled}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </>
    );
};

const NewCmoConnectionModal = ({
    clientId,
    showTerritoryModal,
    setShowTerritoryModal,
    territories,
    setTerritories,
    groupedTerritories,
    isEditing,
    territoriesPropModel,
    customData,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedStartDate, handleStartDateChange] = useState(moment());
    const [selectedEndDate, handleEndDateChange] = useState(moment());
    const [claimMethod, setClaimMethod] = useState('agent');
    const [dateError, setDateError] = useState({
        start: { error: false, msg: '' },
        end: { error: false, msg: '' },
    });
    const [issuedId, setIssuedId] = useState('');

    useEffect(() => {
        dispatch(getCmoIssuedIdsByClientId(clientId));
        if (isEditing) {
            handleEndDateChange(moment(customData.selectedRowDataItem.endDate));
            handleStartDateChange(moment(customData.selectedRowDataItem.startDate));
        } else {
            dispatch(
                changeStateByNestedKey(customData.selectedCmoStoreName, 'selectedCmoId', undefined),
            );
        }
    }, [customData.selectedRowDataItem, isEditing]);

    useEffect(() => {
        if (!isEditing) {
            setTerritories({});
        }
    }, []);

    useEffect(() => {
        if (Object.keys(territoriesPropModel.editableDealInfo).length > 0) {
            handleStartDateChange(moment(territoriesPropModel.editableDealInfo.startDate));
            handleEndDateChange(moment(territoriesPropModel.editableDealInfo.endDate));
        }
    }, [territoriesPropModel.editableDealInfo.startDate]);

    const handleClose = () => {
        setShowTerritoryModal(false);
        dispatch(
            changeStateByNestedKey(customData.selectedCmoStoreName, 'modalValidations', {
                msgTerritory: '',
                msgDate: '',
            }),
        );
    };

    const closeAndReloadTable = () => {
        handleClose();
        if (customData.onTableReload) {
            dispatch(customData.onTableReload.reloadCb(customData.onTableReload.params));
        }
    };

    const getStartdateValue = () => {
        // Disabled in scope of NR-1408 as functionality has been rebuilt
        // if (!isEditing) return true;
        // // else if (customData?.selectedRowDataItem?.registrationStatus === 'TO_BE_REGISTERED')
        // //     return true;
        // else return false;
        return true;
    };

    const handleSave = () => {
        if (customData?.selectedRowDataItem?.registrationStatus === 'EXCLUSIVE_LICENSE_DEAL') {
            handleClose();
        } else if (selectedStartDate && selectedEndDate && selectedStartDate > selectedEndDate) {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'End date cannot be before start date',
                    severity: 'error',
                }),
            );
        } else if (!(selectedStartDate && selectedEndDate)) {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please Fill in all the fields',
                    severity: 'error',
                }),
            );
        } else {
            let errObj = {};
            let allTerritoriesCount = 0;
            groupedTerritories.forEach((el) => (allTerritoriesCount += el.territories.length));
            const selectedTerritories = Object.values(territories)
                .map((territoriesByRegion) => territoriesByRegion)
                .flat()
                .join();
            const dealTerritoriesContent =
                selectedTerritories.split(',').length === allTerritoriesCount
                    ? 'WW'
                    : selectedTerritories;
            // // Date formates are different as they come from BE, should be fixed
            // const startDateFromDeal = moment(
            //     customData.dataModel[customData.dealsContainerName][0].start_date ||
            //         customData.dataModel[customData.dealsContainerName][0].territoryPeriod.startDate
            // );
            // const endDateFromDeal = moment(
            //     customData.dataModel[customData.dealsContainerName][0].end_date ||
            //         customData.dataModel[customData.dealsContainerName][0].territoryPeriod.endDate
            // );
            // if (selectedStartDate < startDateFromDeal) {
            //     errObj = { start: { error: true }, end: {} };
            //     setDateError(errObj);
            // } else if (selectedEndDate > endDateFromDeal) {
            //     errObj = { end: { error: true }, start: {} };
            //     setDateError(errObj);
            // }
            setDateError(errObj);
            const objToSave = {
                // skipped territoryId as requseted
                ...(getStartdateValue() && {
                    startDate: selectedStartDate.format('YYYY-MM-DD'),
                }),
                endDate: selectedEndDate.format('YYYY-MM-DD'),
                territory: dealTerritoriesContent,
                ...(isEditing && {
                    clientCmoRegistrationId:
                        // clientCmoSettingsId:
                        // customData.selectedRowDataItem.settingsSet[0].clientCmoSettingsId,
                        customData.selectedRowDataItem.cmoRegId,
                }),
                // ...(!isEditing && {
                //     clientDealId:
                //         customData.dataModel[customData.dealsContainerName][0].client_deal_id ||
                //         customData.dataModel[customData.dealsContainerName][0].clientDealId,
                // }),
                ...(!isEditing && { cmoId: customData.selectedCmoId }),
                ...(!isEditing && {
                    claimMethod: claimMethod === 'agent' ? 'AGENT' : 'EXCLUSIVE_LICENSE',
                }),
                // ...(!isEditing && {
                //     dealType: customData.isPerformer.isPerformer ? 'PERFORMER' : 'RIGHTS_HOLDER',
                // }),
                ...(!isEditing && {
                    clientId:
                        // performerOrRightsHolderId:
                        customData.dataModel.clientId,
                    // customData.dataModel.performerId || customData.dataModel.clientId,
                }),
            };
            const issuedIdBody = {
                cmoId: customData.selectedCmoId,
                clientId,
                cmoIssuedId: issuedId,
            };
            if (isEditing) {
                dispatch(
                    createOrUpdateCmoRegistration(objToSave, {
                        handleCloseCb: closeAndReloadTable,
                        update: true,
                        storeName: customData.selectedCmoStoreName,
                    }),
                );
                // TODO all cmoIssuedIds restricted lines will be uncommented in scope of NR-603
                if (customData.selectedCmoStoreName !== 'rhData') editCmoIssuedIdAPI(issuedIdBody);
            } else {
                dispatch(
                    createOrUpdateCmoRegistration([objToSave], {
                        handleCloseCb: closeAndReloadTable,
                        storeName: customData.selectedCmoStoreName,
                    }),
                );
                // TODO all cmoIssuedIds restricted lines will be uncommented in scope of NR-603
                if (customData.selectedCmoStoreName !== 'rhData') {
                    if (customData.hasIssuedId) {
                        editCmoIssuedIdAPI(issuedIdBody);
                    } else {
                        createCmoIssuedIdAPI(issuedIdBody);
                    }
                }
            }
        }
    };

    const setAllTerritories = () => {
        let newTerritories = {};
        groupedTerritories.map(
            (territoryGroup) =>
                (newTerritories = {
                    ...newTerritories,
                    [territoryGroup.region.id]: territoryGroup.territories.map((t) => t.id),
                }),
        );
        setTerritories(newTerritories);
    };

    const toggleWorld = () => {
        if (allSelected()) {
            setTerritories({});
        } else {
            setAllTerritories();
        }
    };

    const noneSelected = () => {
        return (
            Object.entries(territories).every((item) => item[1].length === 0) ||
            !customData.selectedCmoId
        );
    };

    const allSelected = () => {
        return groupedTerritories.every((gt) => {
            const matchedTerritory = Object.entries(territories).find((region) => {
                return gt.region.id === region[0];
            });
            return matchedTerritory && matchedTerritory[1].length === gt.territories.length;
        });
    };

    const anySelected = () => {
        return (
            Object.values(territories).length > 0 &&
            Object.values(territories).some((territory) => territory.length > 0)
        );
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={showTerritoryModal} fullWidth maxWidth="md">
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {`${isEditing ? 'Edit' : 'Add'} CMO to ${
                        customData.dataModel.legalCompanyName || customData.dataModel.firstName
                    } ${customData.dataModel?.lastName || ''} `}
                </DialogTitle>
                <DialogContent dividers style={{ maxHeight: '50%' }}>
                    <DatesAndClaimMethods
                        selectedCmoId={customData.selectedCmoId}
                        selectedStartDate={selectedStartDate}
                        handleStartDateChange={handleStartDateChange}
                        selectedEndDate={selectedEndDate}
                        handleEndDateChange={handleEndDateChange}
                        isEditing={isEditing}
                        errorOperations={{
                            dateError,
                            setDateError,
                        }}
                        dateErrMsg={customData.modalValidations.msgDate}
                        isPerformer={customData.isPerformer}
                        cmoIssuedIds={customData.cmoIssuedIds}
                        setIssuedId={setIssuedId}
                        issuedId={issuedId}
                        storeName={customData.selectedCmoStoreName}
                        claimMethod={claimMethod}
                        setClaimMethod={setClaimMethod}
                        isEndDateDisabled={
                            customData?.selectedRowDataItem?.lockedForEdit &&
                            customData?.selectedRowDataItem?.registrationStatus ===
                                'EXCLUSIVE_LICENSE_DEAL'
                        }
                        isStartDateDisabled={
                            // customData?.selectedRowDataItem?.lockedForEdit &&
                            // customData?.selectedRowDataItem?.registrationStatus !==
                            // 'TO_BE_REGISTERED'
                            customData?.selectedRowDataItem?.registrationStatus ===
                            'EXCLUSIVE_LICENSE_DEAL'
                        }
                        claimMethodDefault={
                            customData?.selectedRowDataItem?.claimMethod === 'EXCLUSIVE_LICENSE'
                                ? 'Exclusive License'
                                : 'Agent'
                        }
                    />
                    <Divider variant="inset" style={{ margin: '41px 0 29px 0' }} />
                    <Typography variant="subtitle1" style={{ marginBottom: '27.17px' }}>
                        Territories
                    </Typography>
                    <Alert
                        className={classes.errorBanner}
                        variant="outlined"
                        severity="error"
                        hidden={!customData.modalValidations.msgTerritory}
                    >
                        {customData.modalValidations.msgTerritory}
                    </Alert>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allSelected()}
                                onChange={() => toggleWorld()}
                                name="World"
                                color="primary"
                                indeterminate={allSelected() ? false : anySelected()}
                                disabled={customData?.selectedRowDataItem?.lockedForEdit}
                            />
                        }
                        label="World"
                    />
                    {groupedTerritories.map((territoryGroup) => (
                        <RegionItem
                            key={territoryGroup.region.id}
                            territorySelection={territoryGroup.territories}
                            territories={territories}
                            setTerritories={setTerritories}
                            region={territoryGroup.region}
                            classes={classes}
                            isDisabled={customData?.selectedRowDataItem?.lockedForEdit}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={noneSelected()}
                        variant="contained"
                        onClick={handleSave}
                        color="primary"
                    >
                        {customData?.selectedRowDataItem?.registrationStatus ===
                        'EXCLUSIVE_LICENSE_DEAL'
                            ? 'Close'
                            : 'Save & Close'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect((store) => ({
    territoriesPropModel: store.fugaReducers.territories,
}))(NewCmoConnectionModal);
