import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withRouter } from 'react-router-dom';
import { FormControl, Grid } from '@material-ui/core';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';

const PerformerBioContent = (props) => {
    const dispatch = useDispatch();
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const { byClietnId } = performersModel;
    const [dateOfBirth, handleDateOfBirthChange] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        if (byClietnId.dateOfBirth) {
            const date = moment(byClietnId.dateOfBirth);
            handleDateOfBirthChange(date.format('YYYY-MM-DD'));
        }
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                dateOfBirth:
                    performersModel.isInSaveNewMode && performersModel.selectedIndex < 0
                        ? null
                        : dateOfBirth,
                placeOfBirth: byClietnId.placeOfBirth,
                nationality: byClietnId.nationality,
                cor: byClietnId.countriesOfResidence,
                cob: byClietnId.countryOfBirth,
            }),
        );
    }, [performersModel.byClietnId]);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                dateOfBirth: dateOfBirth,
            }),
        );
    }, [dateOfBirth]);

    const handleDateChange = (date) => {
        handleDateOfBirthChange(moment(date).format('YYYY-MM-DD'));
    };

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <>
            <div className="topContainer flex-left">
                <Grid container spacing={4}>
                    <Grid item sx={6} md={6}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    label="Date of birth"
                                    value={dateOfBirth}
                                    onChange={(date) => handleDateChange(date)}
                                    format="DD/MM/yyyy"
                                    disabled={isDisabled}
                                    inputProps={{ tabIndex: '11' }}
                                />
                            </MuiPickersUtilsProvider>
                            <br />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '13' }}
                                label="Place of birth"
                                onChange={(evt) => handleInputChange(evt)}
                                name="placeOfBirth"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.placeOfBirth
                                        ? performersModel.mainDetailsInptValues.placeOfBirth
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '15' }}
                                label="Countries of residence"
                                onChange={(evt) => handleInputChange(evt)}
                                name="cor"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.cor
                                        ? performersModel.mainDetailsInptValues.cor
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={6} md={6}>
                        <FormControl
                            variant="standard"
                            style={{ textAlign: 'left', width: '100%' }}
                        >
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '12' }}
                                label="Nationality"
                                onChange={(evt) => handleInputChange(evt)}
                                name="nationality"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.nationality
                                        ? performersModel.mainDetailsInptValues.nationality
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '14' }}
                                label="Country of birth"
                                onChange={(evt) => handleInputChange(evt)}
                                name="cob"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.cob
                                        ? performersModel.mainDetailsInptValues.cob
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const withUrlPerformerBioContent = withRouter(PerformerBioContent);
export default connect((r) => r)(withUrlPerformerBioContent);
