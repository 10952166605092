import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Link,
    makeStyles,
    Typography,
} from '@material-ui/core';
import PrivacyPolicyModal from '@common/modals/PrivacyPolicyModal';

const useTermStyles = makeStyles({
    acceptCheckbox: {
        marginTop: 8,
    },
    customLink: {
        '&:hover': {
            color: '#1F1F21',
        },
    },
});

// Moving this to a separated file is quite time consuming - postpone this for later
const termsAndConditionsHTML = `
This platform is owned and operated by Independent IP B.V. d/b/a “FUGA” (an affiliate of Downtown Neighbouring Rights) and your access to and use of this platform is subject to the following terms & conditions:
<br/>
<br/>
You must keep your login details secure at all times and must not share those details with any third party without our prior written consent. You must not do (or, where relevant, fail to do) anything while using the platform that may be detrimental to the platform or to us, which includes transmitting and distributing viruses to or via the platform, reverse engineering any part of the platform, interfering with the proper working of the platform or using the platform in a fraudulent or illegal manner. You must immediately notify us at support@fuga.com of any (alleged) security breach or unauthorised use of your account, or any other security breach suffered by you which may affect the integrity and safety of the platform.
<br/>
<br/>
While using the platform, you may have access to confidential information, such as accounting statements, and you must not disclose that information without the prior written consent of its owner.
<br/>
<br/>
We provide this platform “as is” and “as available” and make no representation or warranty in connection with the same. We exclude our liability towards you to the fullest extent permitted under applicable law. 
`;

function TermsAndConditions({ onAccept, onCancel }) {
    const classes = useTermStyles();
    const [accepted, setAccepted] = useState(false);
    const [openPrivPolModal, setOpenPrivPolModal] = useState(false);

    const onClickSubmit = function () {
        onAccept();
    };

    return (
        <Dialog open>
            <DialogTitle disableTypography>
                <Typography variant="h6"> {'Terms & Conditions'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: termsAndConditionsHTML }}
                />
                <br />
                <Typography variant="body2">
                    Our Privacy Policy available{' '}
                    <Link
                        underline="always"
                        className={classes.customLink}
                        onClick={() => setOpenPrivPolModal(true)}
                    >
                        here
                    </Link>{' '}
                    applies to your use of the platform and sets out how we use, disclose and
                    protect any personal data that you may provide to us when you use the platform.
                </Typography>
                <FormControlLabel
                    value={accepted}
                    onChange={(e, value) => {
                        setAccepted(value);
                    }}
                    classes={{
                        root: classes.acceptCheckbox,
                    }}
                    control={<Checkbox color="primary" name="accept-terms-login" />}
                    label={
                        <Typography variant="body2">
                            {'I agree to the terms & conditions set out above'}
                        </Typography>
                    }
                />
                <PrivacyPolicyModal isOpen={openPrivPolModal} setOpen={setOpenPrivPolModal} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickSubmit}
                    disabled={!accepted}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default TermsAndConditions;
