import React, { useCallback, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import CommonTableGrid from '@common/CommonTableGrid';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import OverflowTip from '@common/OverflowTip';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    actionBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '18px',
        height: '18px',
        color: '#5F5F60',
    },
    inputItemContainer: {
        display: 'flex',
        width: '100%',
        marginBottom: '16px',
    },
    firsInputInRow: {
        marginRight: '32px',
    },
    textField: {
        '& .MuiFormLabel-root': {
            fontSize: '12px',
            fontWeight: '400',
            letterSpacing: '0.4px',
        },
    },
    sectionTitle: {
        marginBottom: '16px',
    },
    featuredLineUpContainer: {
        marginLeft: '32px',
    },
}));

const headers = [
    {
        title: 'Product title - version / artist',
        dataIndex: ['productTitle', 'displayArtist'],
    },
    {
        title: 'UPC',
        dataIndex: 'upc',
    },
    {
        title: 'Record label',
        dataIndex: 'recordLabel',
    },
    {
        title: 'Release date',
        dataIndex: 'releaseDate',
    },
];

export default function AssetMetaDataProductsSection({ dataItem, productsDataFromBE }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showCotributionModal, setShowCotributionModal] = useState(false);
    const [isContributorEditable, setIsContributorEditable] = useState(false);
    const [territories, setTerritories] = useState([]);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedContributorDataItem, setSelectedContributorDataItem] = useState({});

    // const validateNumericValues = (value) => {
    //     const numberRegex = /^[0-9]*\.?[0-9]$/;
    //     return numberRegex.test(value);
    // };

    // const openModal = () => {
    //     setShowCotributionModal(true);
    // };

    // const tableReloadOnSuccess = () => {
    //     dispatch(getAssetMetadataById(dataItem.assetId));
    // };

    // const handleDeleteContribution = () => {
    //     dispatch(
    //         deleteContribution(
    //             selectedContributorDataItem.performerId,
    //             dataItem.assetId,
    //             tableReloadOnSuccess
    //         )
    //     );
    // };

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle.includes('title')) {
            return (
                <>
                    <Typography variant="body2">
                        <OverflowTip>{dataItem[headerItem.dataIndex[0]]}</OverflowTip>
                    </Typography>
                    <Typography variant="caption">
                        <OverflowTip>{dataItem[headerItem.dataIndex[1]]}</OverflowTip>
                    </Typography>
                </>
            );
        } else if (coulumnTitle === '') {
            const handleEditClick = () => {
                setSelectedContributorDataItem(dataItem);
                setShowCotributionModal(true);
                setIsContributorEditable(true);
            };
            const handleDeleteClick = () => {
                setSelectedContributorDataItem(dataItem);
                setIsDeleteDialogOpen(true);
            };

            return (
                <div style={{ display: 'flex' }}>
                    <div onClick={handleEditClick}>
                        <EditOutlined
                            className={classes.actionBtn}
                            style={{ marginRight: '7px' }}
                        />
                    </div>
                    <div onClick={handleDeleteClick}>
                        <DeleteOutline className={classes.actionBtn} />
                    </div>
                </div>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
        }
    });

    return (
        <>
            <Typography variant="h6" className={classes.sectionTitle}>
                Products
            </Typography>
            <div className={`${classes.inputItemContainer}`}>
                {/* {contributionSaveResult.isSuccessful && (
                <Alert
                    style={{ marginBottom: '16px' }}
                    variant="outlined"
                    severity={contributionSaveResult.hasWarning ? 'warning' : 'success'}
                >
                    {`${
                        contributionSaveResult?.isDeleted
                            ? 'Contributor successfully deleted.'
                            : 'Contributor added and saved to the asset.'
                    }${
                        contributionSaveResult.hasWarning
                            ? ' This asset needs to be re-registered with one or more CMOs'
                            : ''
                    }`}
                </Alert>
            )} */}
            </div>
            <CommonTableGrid
                dataList={productsDataFromBE}
                headerList={headers}
                preferences={{
                    loadDataByPageCb: {
                        // no need to pass params here
                    },
                }}
                selection={{
                    isSelectable: false,
                }}
                cellDataCb={getSpecificData}
                isInSubModal={true}
                rowsPerPageOff={true}
                tablePaginationOff={true}
                // footerAddition={{
                //     itemComponent: (
                //         <Button
                //             variant="outlined"
                //             color="primary"
                //             disableElevation
                //             disableRipple
                //             // onClick={openModal}
                //         >
                //             <AddIcon style={{ margin: '0 10px 0 0' }} />
                //             Add Product
                //         </Button>
                //     ),
                // }}
            />
            {/* TBD this and all commented parts will be updated in NR-1353 NR-1358 */}
            {/* {showCotributionModal && (
                <NewContributorModal
                    showModal={showCotributionModal}
                    setShowModal={setShowCotributionModal}
                    territories={territories}
                    setTerritories={setTerritories}
                    isEditing={isContributorEditable}
                    setIsEditing={setIsContributorEditable}
                    customData={{
                        selectedRowDataItem: { ...selectedContributorDataItem, ...dataItem },
                        onTableReload: {
                            reloadCb: getAssetMetadataById,
                            params: [dataItem.assetId],
                        },
                    }}
                />
            )}
            <CommonConfirmationDialog
                isOpen={isDeleteDialogOpen}
                closeCb={setIsDeleteDialogOpen}
                title="Confirm delete contributor"
                description={`Would you like to delete '${
                    selectedContributorDataItem?.fullName || ''
                }' ?`}
                onConfirmCb={handleDeleteContribution}
                custumConfirmText="Delete"
            /> */}
        </>
    );
}
