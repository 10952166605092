import React from 'react';
import { Redirect } from 'react-router-dom';
import { roles } from '@data/constants';
import helper from '@utils/helper';
import utils from '@utils/utils';
import CerberusUtils from '@utils/cerberusUtils';
import { MAINTENANCE_LOCAL_STORAGE_NAME } from '@data/globalConstants';
import MaintenancePage from '@pages/MaintenancePage';
const { getVarFromLocalStorage } = CerberusUtils;

function ProtectedRoute({ role, pageName, redirectPath = '', children }) {
    const roleInfo = roles.find((rl) => rl.name === role);
    let redirectPathLocal = '';

    if (redirectPath) {
        redirectPathLocal = redirectPath;
    } else {
        redirectPathLocal = roleInfo.landingPage.toBeGenerated
            ? helper.generateLandingPage(roleInfo.landingPage.url)
            : roleInfo.landingPage.url;
    }

    const allowPagePerRole = utils.checkRolesAndPermissions(role, pageName);
    const isUnderMaintenance = getVarFromLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME);

    if (isUnderMaintenance) {
        return <MaintenancePage />;
    }

    if (!allowPagePerRole) {
        return <Redirect to={redirectPathLocal} />;
    }

    return children;
}

export default ProtectedRoute;
