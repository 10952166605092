import React, { useRef, useState } from 'react';
import { makeStyles, Menu, MenuItem, IconButton, Tooltip, Fade } from '@material-ui/core';
import { DeleteOutlineOutlined, EditOutlined, MoreVertSharp as MoreVert } from '@material-ui/icons';
import WarningAmberSvg from '@images/svgComponents/WarningAmberSvg';

const useStyles = makeStyles(() => ({
    moreIcon: {
        width: '18px',
        height: '18px',
        marginRight: '0',
        color: '#5F5F60',
    },
    mainIconbtn: {
        padding: '8px',
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
    iconWraper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& svg': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    textStyle: {
        marginLeft: '13px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function MoreDropDownButton({ callbacks, dataItem }) {
    const anchorRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const classes = useStyles();
    const { onEditCB, onDeleteCB, onAlertCB } = callbacks;

    const handleOpen = () => {
        setMenuOpen(true);
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                disableRipple
                className={classes.mainIconbtn}
            >
                <MoreVert className={classes.moreIcon} />
            </IconButton>
            <Menu
                anchorEl={anchorRef.current}
                open={menuOpen}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {onAlertCB && (
                    <MenuItem
                        key="alert"
                        onClick={() => {
                            onAlertCB(dataItem);
                            handleClose();
                        }}
                    >
                        <div className={classes.iconWraper}>
                            <WarningAmberSvg styles={classes.iconWraper} />
                            <div className={classes.textStyle}>Alerts</div>
                        </div>
                    </MenuItem>
                )}
                {onEditCB && (
                    <MenuItem
                        key="edit"
                        onClick={() => {
                            onEditCB(dataItem);
                            handleClose();
                        }}
                    >
                        <div className={classes.iconWraper}>
                            <EditOutlined
                                style={{ width: '24px', height: '24px', color: '#00000099' }}
                            />
                            <div className={classes.textStyle}>
                                {dataItem.claimStatus === 'EXCLUSIVE_LICENSE_DEAL'
                                    ? 'View'
                                    : 'Edit'}
                            </div>
                        </div>
                    </MenuItem>
                )}
                {onDeleteCB && (
                    <Tooltip
                        title={
                            dataItem.claimStatus !== 'EXCLUSIVE_LICENSE_DEAL'
                                ? 'This CMO registration cannot be deleted as registration action(s) already taken'
                                : 'This CMO Registration cannot be edited or deleted as it is under an exclusive license claim party'
                        }
                        disableHoverListener={!dataItem.lockedForDelete}
                    >
                        <div>
                            <MenuItem
                                key="delete"
                                onClick={() => {
                                    onDeleteCB();
                                    handleClose();
                                }}
                                disabled={dataItem.lockedForDelete}
                            >
                                <div className={classes.iconWraper}>
                                    <DeleteOutlineOutlined
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                            color: '#00000099',
                                        }}
                                    />
                                    <div className={classes.textStyle}>Delete</div>
                                </div>
                            </MenuItem>
                        </div>
                    </Tooltip>
                )}
            </Menu>
        </>
    );
}
