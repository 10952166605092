import React from 'react';

export default function FugaMarketingLogo(props) {
    return (
        <div className={props.styles}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
            >
                <path
                    d="M10.4333 10.2588L9.19995 9.87231L3.11938 21.4447L4.35271 21.8311L10.4333 10.2588Z"
                    fill="white"
                />
                <path
                    d="M19.8983 10.2588L18.665 9.87231L12.5844 21.4447L13.8177 21.8311L19.8983 10.2588Z"
                    fill="white"
                />
                <path
                    d="M29.3633 10.2588L28.13 9.87231L22.0495 21.4447L23.2828 21.8311L29.3633 10.2588Z"
                    fill="white"
                />
            </svg>
        </div>
    );
}
