import {
    CREDS_AUTH_ERR_MSG,
    EMAIL_SUCCESS_MSG,
    RESET_PASSWORD_ERR_MSG,
    roles,
    TOKEN_AUTH_ERR_MSG,
    UPDATE_PASSWORD_ERR_MSG,
    USERNAME_ERR_MSG,
} from '@data/constants';
import userData from '@data/userData';
import Fetch from '@utils/Fetch';
import { changeStateByKey } from './fugaActions';

import cerberusUtils from '@utils/cerberusUtils';
import helper from '@utils/helper';

export function getRouteFromUserRole() {
    const currentRole = cerberusUtils.getUserRole();
    const roleInfo = roles.find((role) => role.name === currentRole);

    return roleInfo
        ? roleInfo.landingPage.toBeGenerated
            ? helper.generateLandingPage(roleInfo.landingPage.url)
            : roleInfo.landingPage.url
        : '/';
}

function navigationRoute() {
    const urlToFollow = getRouteFromUserRole();

    window.location.assign(urlToFollow);
}

export function login(queryParams = { username: '', password: '' }) {
    return function (dispatch) {
        const url = `${userData.api.baseUrl}/login?username=${queryParams.username}&password=${queryParams.password}`;
        Fetch.fetchWrapperOld(url)
            .then(async (res) => {
                if (res.ok) {
                    try {
                        const role = res.headers.get('Nr-Role');
                        const result = await res.json();
                        const { access_token: token, clientId } = result.data;

                        cerberusUtils.setClientId(clientId + '');
                        cerberusUtils.setCerberusToken(token);
                        cerberusUtils.setUserRole(role.toLowerCase());

                        if (result?.data?.isTnCaccepted) {
                            navigationRoute();
                        } else {
                            dispatch(changeStateByKey('submitTermsAndConditions', true));
                        }
                    } catch (err) {
                        return Promise.reject(err);
                    }
                } else {
                    return Promise.reject(res);
                }
            })
            .catch((rejected) => {
                const error = rejected.status === 401 ? CREDS_AUTH_ERR_MSG : TOKEN_AUTH_ERR_MSG;

                console.log('Failed to send request via url: ' + url, `error msg: ${error}`);

                dispatch(changeStateByKey('loginErrorMsg', error));
            });
    };
}

export function resetPassword(query = { username: '' }) {
    return function (dispatch) {
        const url = `${userData.api.baseUrl}/password/trigger-reset?name=${query.username}`;
        Fetch.fetchWrapperOld(url, { method: 'POST' })
            .then(async (res) => {
                if (!res.ok) {
                    const result = await res.json();
                    return Promise.reject(result);
                } else {
                    dispatch(changeStateByKey('loginSuccessMsg', EMAIL_SUCCESS_MSG));
                }
            })
            .catch((rejected) => {
                const error = rejected?.data?.error || RESET_PASSWORD_ERR_MSG;

                console.log('Failed to send request via url: ' + url, `error msg: ${error}`);

                if (error === 'NOT_FOUND') {
                    dispatch(changeStateByKey('loginErrorMsg', USERNAME_ERR_MSG));
                } else {
                    dispatch(changeStateByKey('loginErrorMsg', error));
                }
            });
    };
}

export function updatePassword(query = { password: '', id: '', key: '' }) {
    return function (dispatch) {
        const url = `${userData.api.baseUrl}/password/reset?password=${query.password}&id=${query.id}&key=${query.key}`;

        Fetch.fetchWrapperOld(url, { method: 'POST' })
            .then(async (res) => {
                if (!res.ok) {
                    const result = await res.json();
                    return Promise.reject(result);
                } else {
                    dispatch(changeStateByKey('updatedPassword', true));
                }
            })
            .catch((rejected) => {
                const error = rejected?.data?.error || UPDATE_PASSWORD_ERR_MSG;

                console.log('Failed to send request via url: ' + url, `error msg: ${error}`);
                // error === EMBEDDED_COMMAND_NOT_FOUND fallback to UPDATE_PASSWORD_ERR_MSG
                dispatch(changeStateByKey('loginErrorMsg', UPDATE_PASSWORD_ERR_MSG));
            });
    };
}

export function agreeTermsAndConditions() {
    return function () {
        const url = `${userData.api.baseUrl}/terms-n-conditions/agree`;

        Fetch.fetchWrapperOld(url, { method: 'POST' })
            .then(async (res) => {
                if (!res.ok) {
                    const result = await res.json();
                    return Promise.reject(result);
                } else {
                    // follow normal follow of login
                    navigationRoute();
                }
            })
            .catch((rejected) => {
                console.log(
                    'Failed to send request via url: ' + url,
                    `error msg: ${rejected?.data?.error}`,
                );
                // clean everything redirect to /login
                cerberusUtils.setUserRole('');
                cerberusUtils.setCerberusToken('');
                window.location.assign('/login');
            });
    };
}

export function cleanLoginMessages() {
    return function (dispatch) {
        dispatch(changeStateByKey('loginErrorMsg', ''));
        dispatch(changeStateByKey('loginSuccessMsg', ''));
        dispatch(changeStateByKey('submitTermsAndConditions', false));
    };
}
