import React, { useEffect, useState } from 'react';
import { TextField, Chip, MenuItem, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    chip: {
        background: theme.palette.chips.background,
        '&:hover': {
            background: 'rgba(0,0,0,0.07)',
        },
    },
    inputLabel: {
        color: theme.palette.grey.background,
        '&.Mui-focused': {
            color: theme.palette.grey.background,
        },
    },
}));

const DEBOUNCE_DELAY = 300;
const sleep = (delay = 0) => new Promise((resolve) => setTimeout(resolve, delay));

export const ChipTextInput = ({ open, dataItem, setSelectedOptions, selectedOptions, setOpen }) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');

    const classes = useStyles();

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setText('');
        }
    }, [open]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (open && text.length > 2) {
            setLoading(true);

            loadSuggestions(signal);
        }

        return () => {
            controller.abort();
        };
    }, [open, text]);

    const loadSuggestions = async (signal) => {
        await sleep(DEBOUNCE_DELAY);

        const newOptions = await dataItem.getSuggestions(text, signal);

        if (!signal.aborted) {
            setOptions(newOptions?.data[dataItem.lookupField] || []);
            setLoading(false);
        }
    };

    const handleAddChip = (event, newValue) => {
        setSelectedOptions(newValue);
    };

    const handleDeleteChip = (chipToDelete) => () => {
        setSelectedOptions((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    return (
        <Autocomplete
            multiple
            freeSolo
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={selectedOptions}
            onChange={handleAddChip}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={options}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const tagProps = getTagProps({ index });
                    return (
                        <Chip
                            label={option}
                            {...tagProps}
                            className={classnames(tagProps.className, classes.chip)}
                            onDelete={handleDeleteChip(option)}
                        />
                    );
                })
            }
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={dataItem?.placeholder || 'Type to search...'}
                        placeholder={dataItem.placeholder || 'Type to search...'}
                        InputProps={{
                            ...params.InputProps,
                            onChange: (e) => setText(e.target.value),
                            endAdornment: (
                                <>
                                    {loading && <CircularProgress color="inherit" size={20} />}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        InputLabelProps={{
                            className: classes.inputLabel,
                        }}
                    />
                );
            }}
            renderOption={(option) => <MenuItem>{option}</MenuItem>}
        />
    );
};
