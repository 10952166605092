import { createTheme } from '@material-ui/core/styles';

//palette in different theme to reuse the palette later
// consider to use this approach for typography
const globalTheme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        error: {
            main: '#F44139',
            oulinedRestingBorder: '#EE9A9B', //design system
            background: '#FFEBEE', // design system
        },
        success: {
            main: '#00A542',
            oulinedRestingBorder: '#56CC82', //design system
            background: '#E3F6E9', //design system
        },
        text: {
            secondary: '#5F5F60',
        },
    },
});

//Keep styling from login separated.
const theme = createTheme({
    ...globalTheme,
    overrides: {
        MuiAlert: {
            outlinedError: {
                borderColor: globalTheme.palette.error.main,
                backgroundColor: globalTheme.palette.error.background,
            },
            outlinedSuccess: {
                borderColor: globalTheme.palette.success.main,
                backgroundColor: globalTheme.palette.success.background,
            },
            message: {
                textAlign: 'center',
            },
        },
        MuiLink: {
            underlineHover: {
                '&:hover': {
                    color: globalTheme.palette.text.secondary,
                },
            },
        },
    },
    // Keeping the styles from typography the same - duplicated
    // Uncertain how login page varies from the rest.
    typography: {
        fontFamily: 'Nunito Sans, sans-serif',
        color: '#1F1F21',
        body1: {
            color: '#1F1F21',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
        },
        body2: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.25px',
        },
        h6: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '27px',
            letterSpacing: '0.15px',
            textAlign: 'left',
        },
        h5: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '33px',
            letterSpacing: '0.15px',
        },
        h4: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '36px',
            lineHeight: '42px',
            letterSpacing: '0.25px',
        },
        h3: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '48px',
            lineHeight: '65px',
            letterSpacing: '0.25px',
        },
        h2: {
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '64px',
            lineHeight: '87px',
            letterSpacing: '-0.5px',
        },
        h1: {
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '96px',
            lineHeight: '131px',
            letterSpacing: '-1.5px',
        },
        subtitle1: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.15px',
        },
        subtitle2: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.1px',
        },
        caption: {
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '12px',
            letterSpacing: '0.4px',
            color: '#5F5F60',
        },
    },
});

export default theme;
