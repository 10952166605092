import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { makeStyles } from '@material-ui/core/styles';
import CommonCollapsibleTableGrid from './CommonCollapsibleTableGrid';
import StatementsBtnGroup from './StatementsBtnGroup';
import { Popover, Tooltip, Typography } from '@material-ui/core';
import OverflowTip from './OverflowTip';
import { FiberManualRecord } from '@material-ui/icons';
import InfoIconSvg from '@images/svgComponents/InfoIconSvg';
import { getStatementsByClientId } from '@actions/statements';

const useClasses = makeStyles(() => ({
    statusIcons: {
        width: '18px',
        height: '18px',
        marginRight: '21.25px',
    },
    flexHorizontal_f_s: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    activeIcon: {
        color: '#00A542',
    },
    inactiveIcon: {
        color: '#5F5F60',
    },
    pendingIcon: {
        color: '#FF8800',
    },
    titleMainColumn: {
        marginLeft: '8px',
    },
    topContainer: {
        padding: '0px 0 32px 0',
    },
    subtotalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    amberDot: {
        color: '#FF8800',
        marginLeft: '8px',
        width: '8px',
        heigth: '8px',
    },
    subtotalPopover: {
        '& .MuiPopover-paper': {
            width: '444px',
            padding: '16px 16px',
            boxSizing: 'content-box',
            marginTop: '7px',
        },
    },
    hide: {
        visibility: 'hidden',
    },
    helpIcon: {
        width: '20px',
        height: '20px',
        marginLeft: '8px',
        cursor: 'pointer',
    },
    subtotalPopoverHeadings: {
        paddingTop: '8px',
        marginBottom: '6px',
    },
    subtotalPopoverRows: {
        paddingBottom: '8px',
        paddingTop: '2px',
    },
}));

function StatementsTable() {
    const classes = useClasses();
    const dispatch = useDispatch();
    const params = useParams();
    const [tableData, setTableData] = useState({ content: [] });
    const [anchorEl, setAnchorEl] = useState(false);
    const statementsModel = useSelector((state) => state.fugaReducers.statements);
    const selectedFiles = useRef([]);

    const setSelectedFiles = (newValue) => {
        selectedFiles.current = newValue;
    };
    const excludedFiles = useRef([]);
    const setExcludedFiles = (newValue) => {
        excludedFiles.current = newValue;
    };

    useEffect(() => {
        dispatch(getStatementsByClientId(params.id || -1));
    }, []);

    // This update should be done in order to make nested rows sorting work
    useEffect(() => {
        const updatedContent = statementsModel.dataFromBE.content.map((el) => {
            el.isRowCollapsed = false;
            return el;
        });
        setTableData({ ...statementsModel.dataFromBE, content: updatedContent });
    }, [statementsModel.dataFromBE]);

    const headers = [
        {
            title: 'Statement run',
            dataIndex: 'yearQuarterKey',
        },
        {
            title: 'Subtotal',
            dataIndex: 'invoiceSummary',
            conditionIndex: 'invoiceStatus',
            hasNumericData: true,
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'File name',
            dataIndex: 'filename',
        },
    ];

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle.includes('subtotal')) {
            const content = dataItem[headerItem.dataIndex];
            const isAmberVisible = dataItem[headerItem.conditionIndex] !== 'post-recoupment';
            return (
                <div className={classes.subtotalContent}>
                    <Typography variant="body2">{content}</Typography>
                    {content && (
                        <Tooltip
                            title="Account unrecouped"
                            className={isAmberVisible ? '' : classes.hide}
                        >
                            <FiberManualRecord className={classes.amberDot} />
                        </Tooltip>
                    )}
                </div>
            );
        } else {
            const contentValue =
                dataItem[headerItem.dataIndex] === 'none' ? '' : dataItem[headerItem.dataIndex];
            return <OverflowTip>{contentValue}</OverflowTip>;
        }
    });

    const addHeaderSpecificAddition = useCallback((headerItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle.includes('subtotal')) {
            return (
                <div onClick={handleInfoClick}>
                    <InfoIconSvg styles={classes.helpIcon} />
                </div>
            );
        }
    });

    const handleInfoClick = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleInfoClose = () => {
        setAnchorEl(null);
    };

    const openSubtotalInfo = Boolean(anchorEl);

    return (
        <div>
            <StatementsBtnGroup selectedFiles={selectedFiles.current} clientId={params.id} />
            <div className={classes.topContainer}>
                <CommonCollapsibleTableGrid
                    dataList={tableData}
                    headerList={headers}
                    loading={statementsModel.tableIsLoading}
                    cellDataCb={getSpecificData}
                    additionalHeaderContent={{
                        callBack: addHeaderSpecificAddition,
                        titleWithAddition: 'Subtotal',
                    }}
                    subRowsContainerFieldName="files"
                    titleWithExpandIcon="statement run"
                    preferences={{
                        loadDataByPageCb: {
                            dispatchFn: dispatch,
                            callbackFn: getStatementsByClientId,
                            argumentsList: [params.id || -1],
                        },
                    }}
                    selection={{
                        isSelectable: true,
                        dispatchFn: dispatch,
                        callbackFn: changeStateByNestedKey,
                        selectedRows: selectedFiles.current,
                        setSelectedRows: setSelectedFiles,
                        downloadItems: statementsModel.downloadItems,
                        storeName: 'statements',
                        excludedRows: excludedFiles.current,
                        setExcludedAssets: setExcludedFiles,
                        withCheckboxColumnNames: ['statement run', 'type'],
                    }}
                />
                <Popover
                    open={openSubtotalInfo}
                    anchorEl={anchorEl}
                    onClose={handleInfoClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    className={classes.subtotalPopover}
                >
                    <Typography variant="subtitle1" className={classes.subtotalPopoverHeadings}>
                        Subtotal
                    </Typography>
                    <Typography variant="body2" className={classes.subtotalPopoverRows}>
                        Figure here is indicated earning for each period minus any charges such as
                        service and VAT, see files for full details
                    </Typography>
                    <Typography variant="subtitle1" className={classes.subtotalPopoverHeadings}>
                        {'Icon ('}
                        <FiberManualRecord
                            className={classes.amberDot}
                            style={{ position: 'relative', top: '7px', margin: 0 }}
                        />
                        {') indicates the account is in recoupment'}
                    </Typography>
                    <Typography variant="body2" className={classes.subtotalPopoverRows}>
                        The account is still in recoupment for this period, see files for full
                        details
                    </Typography>
                </Popover>
            </div>
        </div>
    );
}

export default StatementsTable;
