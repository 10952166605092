import utilsGeneral from './utilsGeneral';

class CerberusUtils {
    static setVarToLocalStorage(varName, value = '') {
        localStorage.setItem(varName, utilsGeneral.encodeBase64(value + ''));
    }

    static getVarFromLocalStorage(varName) {
        const encodedValue = localStorage.getItem(varName + '') || '';
        return utilsGeneral.decodeBase64(encodedValue);
    }

    static deleteVarFromLocalStorage(varName) {
        localStorage.removeItem(varName);
    }

    static setCerberusToken(token = '') {
        localStorage.setItem('@cerberus_token', token);
    }

    static setUserRole(role = '') {
        const encodedRole = utilsGeneral.encodeBase64(role);
        localStorage.setItem('@nr_user_role', encodedRole);
    }

    static getCerberusToken() {
        return localStorage.getItem('@cerberus_token') || '';
    }

    static getUserRole() {
        const encodedValue = localStorage.getItem('@nr_user_role') || '';
        return utilsGeneral.decodeBase64(encodedValue);
    }

    static getAuthorizationHeader() {
        const hash = Buffer.from(
            // `${window._env_.REACT_APP_CERBERUS_USER}:${window._env_.REACT_APP_CERBERUS_PASS}`
            `${import.meta.env.REACT_APP_CERBERUS_USER}:${import.meta.env.REACT_APP_CERBERUS_PASS}`
        ).toString('base64');
        return { Authorization: `Basic ${hash}` };
    }

    static deleteUserRole() {
        localStorage.removeItem('@nr_user_role');
    }

    static deleteCerberusToken() {
        localStorage.removeItem('@cerberus_token');
    }

    static setClientId(clientId) {
        const encodedId = utilsGeneral.encodeBase64(clientId);
        localStorage.setItem('@nr_client_id', encodedId);
    }

    static getClientId() {
        const encodedId = localStorage.getItem('@nr_client_id') || '';
        return utilsGeneral.decodeBase64(encodedId);
    }

    static deleteClientId() {
        localStorage.removeItem('@nr_client_id');
    }
}

export default CerberusUtils;
