import React from 'react';
import ClientCmoSelection from './ClientCmoSelection';
import ClientMainInfo from './ClientMainInfo';

function ClientCmoSelectionContainer() {
    alert('what!');
    return (
        <div>
            <ClientCmoSelection>
                <ClientMainInfo />
            </ClientCmoSelection>
        </div>
    );
}

export default ClientCmoSelectionContainer;
