import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CommonDropDownWithInput } from '@common/CommonDropDownWithInput';
import { useParams } from 'react-router-dom';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';

const useStyles = makeStyles({
    textField: {
        margin: '0 0 32px 0',
    },
});

const ClientDetailsContent = () => {
    const dispatch = useDispatch();
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const { detailsData } = rhDataModel;
    const params = useParams();
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    useEffect(() => {
        dispatch(changeStateByNestedKey('clientPage', 'isEditBtnVisible', true));
        if (params.id === '-1' || !areFieldsDisabled) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [areFieldsDisabled, params.id]);

    const tierMenuItems = [
        {
            title: '0',
            value: 0,
        },
        {
            title: '1',
            value: 1,
        },
        {
            title: '2',
            value: 2,
        },
        {
            title: '3',
            value: 3,
        },
        {
            title: '4',
            value: 4,
        },
    ];

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                clientName: detailsData.legalCompanyName,
                businessRegisteredAddress: detailsData.businessRegisteredAddress,
                businessMailingAddress: detailsData.businessMailingAddress,
                subLabels: detailsData.subLabels,
                tier: detailsData.tier,
            }),
        );
    }, [rhDataModel.detailsData]);

    const classes = useStyles();
    const [isDisabled, setIsDisabled] = useState(true);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    const handleTierValueChange = (value) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                tier: value,
            }),
        );
    };

    return (
        <Grid container spacing={4}>
            <Grid item sx={6} md={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                    <TextFieldWrapper
                        label="Client Name/Legal Company"
                        required
                        onChange={(evt) => handleInputChange(evt)}
                        name="clientName"
                        type="text"
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.clientName || ''}
                        inputProps={{ tabIndex: '1' }}
                    />
                    <br />
                    <TextFieldWrapper
                        label="Business Registered Address"
                        onChange={(evt) => handleInputChange(evt)}
                        name="businessRegisteredAddress"
                        type="text"
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.businessRegisteredAddress || ''}
                        inputProps={{ tabIndex: '2' }}
                    />
                    <br />
                    <CommonDropDownWithInput
                        menuItemsList={tierMenuItems}
                        labelName="Tier"
                        preSelectedValue={rhDataModel.mainDetailsInptValues.tier}
                        isEditing={isDisabled}
                        isDisabled={isDisabled}
                        inputProps={{ tabIndex: '4' }}
                        onValueChangeCb={handleTierValueChange}
                    />
                </FormControl>
            </Grid>
            <Grid item sx={6} md={6}>
                <FormControl variant="standard" style={{ textAlign: 'left', width: '100%' }}>
                    <TextFieldWrapper
                        label="invisible to keep shape"
                        style={{ margin: '0 0 32px 0', visibility: 'hidden' }}
                    />
                    <br />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '3' }}
                        label="Business Mailing Address"
                        onChange={(evt) => handleInputChange(evt)}
                        name="businessMailingAddress"
                        type="text"
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.businessMailingAddress || ''}
                    />
                    <br />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '5' }}
                        label="Sub-labels"
                        onChange={(evt) => handleInputChange(evt)}
                        name="subLabels"
                        type="text"
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues?.subLabels || ''}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ClientDetailsContent;
