import { makeStyles, MenuItem, Typography, Select, Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import CommonTableGrid from '@common/CommonTableGrid';
import OverflowTip from '@common/OverflowTip';
import { DeleteOutline } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { deleteArtistByIdAPI, editArtistAssetMetadataByIdAPI } from '@api/metadataController';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { NewArtistModal } from '@common/modals/NewArtistModal';

const useStyles = makeStyles(() => ({
    actionBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '18px',
        height: '18px',
        color: '#5F5F60',
    },
    inputItemContainer: {
        display: 'flex',
        width: '100%',
        marginBottom: '16px',
    },
    firsInputInRow: {
        marginRight: '32px',
    },
    textField: {
        '& .MuiFormLabel-root': {
            fontSize: '12px',
            fontWeight: '400',
            letterSpacing: '0.4px',
        },
    },
    sectionTitle: {
        marginBottom: '16px',
    },
    featuredLineUpContainer: {
        marginLeft: '32px',
    },
    addArtistButton: {
        padding: '6px 10px',
    },
    roleSelect: {
        width: '92px',
    },
}));

const headers = [
    {
        title: 'Artist',
        dataIndex: 'artistName',
    },
    {
        title: 'Role',
        dataIndex: 'isPrimary',
    },
    {
        title: '',
        dataIndex: '',
        hasIcon: true,
    },
];

export function AssetMetaDataArtistsSection({ dataItem, tableReloadOnSuccess }) {
    const classes = useStyles();

    const assetMetadataArtists = useSelector(
        (state) => state.fugaReducers.assetMetadata.artistsDataFromBE,
    );

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedContributorDataItem, setSelectedContributorDataItem] = useState(null);
    const [showAddArtistModal, setShowAddArtistModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const openAddArtistModal = (event) => {
        setAnchorEl(event.currentTarget);
        setShowAddArtistModal(true);
    };

    const closeAddArtistModal = () => {
        setAnchorEl(null);
        setShowAddArtistModal(false);
    };

    const handleRoleChange = async (event, item) => {
        const newRole = event.target.value === 'Primary';
        try {
            await editArtistAssetMetadataByIdAPI(dataItem.assetId, {
                artistId: item.artistId,
                isPrimary: newRole,
            });
            tableReloadOnSuccess();
        } catch (error) {
            console.error('Error updating artist role:', error);
        }
    };

    const handleDeleteContribution = async () => {
        await deleteArtistByIdAPI(dataItem.assetId, selectedContributorDataItem.artistId);
        tableReloadOnSuccess();
    };

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const columnTitle = headerItem.title.toLowerCase();

        if (columnTitle === '') {
            const handleDeleteClick = () => {
                setSelectedContributorDataItem(dataItem);
                setIsDeleteDialogOpen(true);
            };

            return (
                <div style={{ display: 'flex' }} onClick={handleDeleteClick}>
                    <DeleteOutline className={classes.actionBtn} />
                </div>
            );
        } else if (columnTitle === 'role') {
            return (
                <Select
                    value={dataItem.isPrimary ? 'Primary' : 'Featured'}
                    onChange={(event) => handleRoleChange(event, dataItem)}
                    className={classes.roleSelect}
                >
                    <MenuItem value="Primary">Primary</MenuItem>
                    <MenuItem value="Featured">Featured</MenuItem>
                </Select>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
        }
    });

    return (
        <>
            <Typography variant="h6" className={classes.sectionTitle}>
                Artists
            </Typography>

            <CommonTableGrid
                dataList={assetMetadataArtists}
                headerList={headers}
                preferences={{
                    loadDataByPageCb: {},
                }}
                selection={{
                    isSelectable: false,
                }}
                cellDataCb={getSpecificData}
                isInSubModal={true}
                rowsPerPageOff={true}
                tablePaginationOff={true}
                footerAddition={{
                    itemComponent: (
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                disableRipple
                                className={classes.addArtistButton}
                                onClick={openAddArtistModal}
                            >
                                Add Artist
                                <ArrowDropDownIcon />
                            </Button>
                            {showAddArtistModal && (
                                <NewArtistModal
                                    anchorEl={anchorEl}
                                    open={showAddArtistModal}
                                    onClose={closeAddArtistModal}
                                    loadArtistsData={tableReloadOnSuccess}
                                    assetId={dataItem.assetId}
                                />
                            )}
                        </div>
                    ),
                }}
            />

            <CommonConfirmationDialog
                isOpen={isDeleteDialogOpen}
                closeCb={setIsDeleteDialogOpen}
                title="Confirm delete artist"
                description={`Would you like to delete '${
                    selectedContributorDataItem?.artistName || ''
                }'?`}
                onConfirmCb={handleDeleteContribution}
                custumConfirmText="Delete"
            />
        </>
    );
}
