import React, { useState } from 'react';
import { makeStyles, Menu, MenuItem, Chip, withStyles } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

export const chipThemes = {
    redChip: {
        color: '#B7191F',
        border: '1px solid #EE9A9B',
        backgroundColor: '#FFEBEE',
    },
    greenChip: {
        color: '#006218',
        border: '1px solid #56CC82',
        backgroundColor: '#E3F6E9',
    },
    blueChip: {
        color: '#295E95',
        border: '1px solid #74C7ED',
        backgroundColor: '#E5F6FC',
    },
    orangeChip: {
        color: '#EA4B04',
        border: '1px solid #FFB44C',
        backgroundColor: '#FFF3E0',
    },
    greyChip: {
        color: 'rgba(0, 0, 0, 0.6)',
        border: '1px solid rgba(0, 0, 0, 0.54)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    purpleChip: {
        color: '#371678',
        border: '1px solid #9270BA',
        backgroundColor: '#ECE6F3',
    },
};

export const STATUSES_LIST = {
    EXCLUSIVE_LICENSE_DEAL: {
        color: chipThemes.greenChip,
    },
    DATA_UPDATED: { color: chipThemes.orangeChip },
    REGISTERED: { color: chipThemes.greenChip },
    SUBMITTED: { color: chipThemes.blueChip },
    NOT_AVAILABLE: { color: chipThemes.greyChip },
    TO_BE_REGISTERED: { color: chipThemes.orangeChip },
};

export const ASSET_STATUSES_LIST = {
    EXCLUSIVE_LICENSE_DEAL: {
        // probably removeable
        color: chipThemes.greenChip,
    },
    TO_BE_REGISTERED: { color: chipThemes.orangeChip },
    EXPORTED: { color: chipThemes.blueChip },
    REGISTERED: { color: chipThemes.greenChip },
    UNAVAILABLE: { color: chipThemes.greyChip },
    NOT_APPLICABLE: { color: chipThemes.greyChip },
    RELINQUISHED: { color: chipThemes.purpleChip },
    DATA_UPDATED: { color: chipThemes.orangeChip },
    SUBMITTED: { color: chipThemes.blueChip },
};

const FULL_STATUSES_LIST = { ...ASSET_STATUSES_LIST, ...STATUSES_LIST };

const ChipBtn = withStyles({
    root: {
        '&.Mui-disabled': {
            background: '#ececee',
        },
        height: '26px',
        borderRadius: '4px',
        borderWidth: '1px',
        padding: '4px 4px 4px 8px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '10px',
        lineHeight: '14px',
        textAlign: 'center',
        letterSpacing: '1.5px',
        justifyContent: 'normal',
        boxSizing: 'border-box',
        '&:hover': {
            background: 'rgba(0,0,0,0.07) !important',
        },
        '&:active': {
            boxShadow: 'none',
            background: 'rgba(0,0,0,0.12) !important',
        },
        position: 'relative',
    },
    icon: {
        width: '18px',
        height: '18px',
        position: 'absolute',
        right: 0,
        margin: 0,
        marginLeft: '8px',
        marginRight: '4px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    label: {
        padding: 0,
        paddingRight: '26px',
    },
    success: {},
})(Chip);

export const ChipLabel = withStyles({
    root: {
        '&.Mui-disabled': {
            background: '#ececee',
        },
        height: '26px',
        borderRadius: '4px',
        borderWidth: '1px',
        padding: '4px 8px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '10px',
        lineHeight: '14px',
        textAlign: 'center',
        letterSpacing: '1.5px',
        justifyContent: 'normal',
        '&:hover': {
            background: 'rgba(0,0,0,0.07) !important',
        },
        '&:focus': {
            background: 'rgba(0,0,0,0.12) !important',
        },
        '&:active': {
            boxShadow: 'none',
            background: 'rgba(0,0,0,0.12)',
        },
    },
    label: {
        padding: 0,
        overflow: 'unset',
    },
})(Chip);

const useClasses = makeStyles(() => ({
    menuItem: {
        padding: '8px 16px',
    },
    alertRestBanner: {
        '& .MuiAlert-icon': {
            color: '#5F5F60',
            marginRight: '14px',
        },
        backgroundColor: '#FFF',
        border: '1px solid #5F5F60',
        color: '#5F5F60',
        textWrap: 'wrap',
        padding: '12px 16px',
        '& .MuiAlert-message': {
            width: '223px',
            textAlign: 'initial',
            padding: 0,
            fontWeight: 400,
            lineHeight: '18px',
        },
    },
}));

const RestrictedAlert = () => {
    const classes = useClasses();

    return (
        <div style={{ width: '255px' }}>
            <Alert severity="info" variant="outlined" className={classes.alertRestBanner}>
                To change the status first resolve all alerts.
            </Alert>
        </div>
    );
};

export default function CommonDropDownChips({ chipTitle, menuItemsList, customStyles, hasAlerts }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useClasses();

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onItemClick = (item) => {
        handleClose();
        if (item.onClickClb) item.onClickClb(...item.CbArgs);
    };
    const getChipBtnStyle = () => {
        return FULL_STATUSES_LIST[chipTitle.split(' ').join('_')].color;
    };

    const generateContentFromMenuItemsList = () => {
        return (
            menuItemsList &&
            menuItemsList.map((item, ind) => (
                <MenuItem className={classes.menuItem} key={`${ind}-${item.title}`}>
                    <ChipLabel
                        onClick={() => onItemClick(item)}
                        label={item.title}
                        style={item.color}
                        disableRipple
                    />
                </MenuItem>
            ))
        );
    };

    return (
        <>
            <ChipBtn
                variant={customStyles?.variant ? customStyles.variant : 'text'}
                onClick={(e) => handleOpen(e)}
                color="primary"
                label={chipTitle}
                icon={<ArrowDropDown />}
                style={getChipBtnStyle()}
                disableRipple
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {hasAlerts ? (
                    <MenuItem className={classes.menuItem}>
                        <RestrictedAlert />
                    </MenuItem>
                ) : (
                    generateContentFromMenuItemsList()
                )}
            </Menu>
        </>
    );
}
