import React, { useState, useEffect } from 'react';
import {
    Button,
    DialogTitle,
    makeStyles,
    TextField,
    Popover,
    ClickAwayListener,
    Box,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    titile: {
        padding: '19px 16px',
    },
    actoinBtns: {
        marginTop: '32px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginLeft: '24px',
        },
    },
    input: {
        width: '100%',
    },
    box: {
        padding: '24px 24px 8px',
        width: '335px',
        minHeight: '147px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    textStyle: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.25px',
        height: '100% !important',
    },
}));

function FilterMenuDialogWithInput({ isOpen, closeCb, title, dataItem, onConfirm, anchorEl }) {
    const classes = useStyles();
    const [open, setOpen] = useState(isOpen);
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const [inputErrorMessage, setInputErrorMessage] = useState('');
    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    useEffect(() => {
        const preSetValue = filtersModel[dataItem.filterField];
        if (preSetValue) setInputValue(preSetValue);
    }, [open]);

    useEffect(() => {
        if (filtersModel[dataItem.filterField] === '') {
            setInputValue('');
        }
    }, [filtersModel[dataItem.filterField]]);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
        setInputValue('');
        setInputError(false);
        setInputErrorMessage('');
    };

    const handleConfirm = () => {
        const validationReason =
            typeof dataItem.validation === 'function' ? dataItem.validation(inputValue) : null;

        if (validationReason) {
            setInputError(true);
            if (typeof validationReason === 'string') {
                setInputErrorMessage(validationReason);
            }
        } else {
            onConfirm(inputValue);
            handleClose();
        }
    };

    const handleChange = (evt) => {
        setInputValue(evt.currentTarget.value);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Box className={classes.box}>
                    {title && (
                        <DialogTitle onClose={handleClose} className={`${classes.titile}`}>
                            {title}
                        </DialogTitle>
                    )}
                    <div>
                        <TextField
                            focused={true}
                            className={`${classes.input}`}
                            label={dataItem.inputLabel || ''}
                            placeholder={dataItem.placeholder || ''}
                            value={inputValue}
                            onChange={handleChange}
                            multiline
                            maxRows={4}
                            InputProps={{
                                classes: {
                                    input: classes.textStyle,
                                },
                            }}
                            helperText={
                                inputError
                                    ? inputErrorMessage !== ''
                                        ? inputErrorMessage
                                        : 'Invalid data'
                                    : ''
                            }
                            error={inputError}
                        />
                    </div>
                    <div className={classes.actoinBtns}>
                        <Button
                            onClick={handleClose}
                            variant="text"
                            color="primary"
                            disableElevation
                        >
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleConfirm}
                            disabled={!inputValue}
                        >
                            apply
                        </Button>
                    </div>
                </Box>
            </ClickAwayListener>
        </Popover>
    );
}

export default FilterMenuDialogWithInput;
