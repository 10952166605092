import React from 'react';
import WarningOrangeSvg from '@images/svgComponents/WarningOrangeSvg';
import InfoIconSmallBlueSvg from '@images/svgComponents/InfoIconSmallBlueSvg';
import { ErrorOutline } from '@material-ui/icons';

const getFiltersToSend = (filtersModel) => {
    const newFilters = {};

    for (let key in filtersModel) {
        switch (key) {
            case 'lastModified':
                newFilters.lastModifiedAfterDate =
                    filtersModel[key].valuesToSend?.lastModifiedAfterDate;
                newFilters.lastModifiedBeforeDate =
                    filtersModel[key].valuesToSend?.lastModifiedBeforeDate;
                break;
            default:
                newFilters[key] = filtersModel[key].valuesToSend;
                break;
        }
    }

    return newFilters;
};

const manualAlertsItemsList = [
    {
        title: 'Dispute action required',
        beValue: 'DISPUTE',
        coloredIcon: () => (
            <ErrorOutline style={{ width: '18px', height: '18px', fill: '#F44139' }} />
        ),
        color: '#F44139',
    },
    {
        title: 'Evidence action required',
        beValue: 'EVIDENCE_REQUIRED',
        coloredIcon: (styles = {}) => <WarningOrangeSvg styles={styles} />,
        color: '#FF8800',
    },
    {
        title: 'Action required',
        beValue: 'ACTION_REQUIRED',
        coloredIcon: (styles = {}) => <InfoIconSmallBlueSvg styles={styles} />,
        color: '#45A2DD',
    },
    {
        title: 'CMO Validation',
        beValue: 'CMO_VALIDATION',
        coloredIcon: (styles = {}) => <WarningOrangeSvg styles={styles} />,
        color: '#FF8800',
    },
];

export { getFiltersToSend, manualAlertsItemsList };
