import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CommonDropDownWithInput } from '@common/CommonDropDownWithInput';
import { getClientsList } from '@actions/apiActions';
import SuggesstionInput from '@common/SuggestionInput';
import { Alert } from '@material-ui/lab';
import { getAllUsers } from '@actions/fugaActions';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { createContactAPI, editContactAPI } from '@api/contactController';

const useStyles = makeStyles((theme) => ({
    title: {
        padding: '19px 24px',
    },
    content: {
        padding: '10px 24px',
    },
    textField: {
        margin: '0 0 32px 0',
    },
    hide: {
        visibility: 'hidden',
    },
    commentCounter: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dialogContainerPaper: {
        '& .MuiDialog-paper': {
            width: '615px',
        },
        zIndex: `${theme.zIndex.drawer - 100} !important`,
    },
}));

const nrpAccssMenuItems = [
    {
        title: 'No access',
        roleName: 'NO_ACCESS',
        value: 0,
    },
    {
        title: 'Admin',
        roleName: 'ADMIN',
        value: 1,
    },
    {
        title: 'Internal',
        roleName: 'INTERNAL',
        value: 2,
    },
    {
        title: 'RH standard',
        roleName: 'RH_ST',
        value: 3,
    },
    {
        title: 'Performer standard',
        roleName: 'PER_ST',
        value: 4,
    },
    {
        title: 'RH & Performer standard',
        roleName: 'RH_PER_ST',
        value: 5,
    },
];

export default function ContactDetailsModal({
    isOpen,
    setOpen,
    modalData,
    isEditing,
    setIsEditing,
    dataItem,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errorTextForEmail, setErrorTextForEmail] = useState('');
    const [warningText, setWarningText] = useState('');
    const [commentSymCount, setCommentSymCount] = useState(0);
    const [mainDetailsInptValues, setMainDetailsInptValues] = useState({});
    const [selectedContactForClients, setSelectedContactForClient] = useState([]);
    const [selectedPortalAccForClients, setSelectedPortalAccForClients] = useState([]);
    const allClientsListModel = useSelector((state) => state.fugaReducers.allClientsList);
    const warningRef = useRef(<></>);

    const handleClose = () => {
        setOpen(false);
        setMainDetailsInptValues({ role: nrpAccssMenuItems[0].roleName });
        setSelectedContactForClient([]);
        setSelectedPortalAccForClients([]);
        setErrorText('');
        setErrorTextForEmail('');
        setIsEditing(false);
    };

    const onError = (errMsg) => {
        if (errMsg === 'This email address already exists for a Contact') {
            setErrorTextForEmail(errMsg);
        } else {
            setErrorText(errMsg);
        }
    };

    const onSuccess = () => {
        handleClose();
        dispatch(getAllUsers());
    };

    const handleSave = async () => {
        const dataToSend = { ...mainDetailsInptValues };

        if (dataToSend.role === 'NO_ACCESS') {
            delete dataToSend.fugaUsername;
        }

        if (isEditing) {
            dataToSend.fugaId = dataItem.fugaId;
            dataToSend.contactId = dataItem.contactId;
            dataToSend.nrpAccess = dataToSend.role;
            delete dataToSend.role;
            if (dataToSend.role === 'NO_ACCESS') {
                delete dataToSend.usersForContact;
            }
        }

        try {
            if (isEditing) {
                await editContactAPI(dataToSend);
            } else {
                await createContactAPI(dataToSend);
            }
            onSuccess();
        } catch (e) {
            onError(e.message);
        }
    };

    useEffect(() => {
        dispatch(getClientsList());
    }, []);

    const getSuggestionListForClient = (preIdsFromBE, isPortalAcc = false) => {
        if (isEditing) {
            const preSelectedContactIds = preIdsFromBE || [];
            const preSelectedContacts = allClientsListModel
                .filter((client) => preSelectedContactIds.includes(client.clientId))
                .map((client) => ({ id: client.clientId, name: client.legalCompanyName }));
            if (isPortalAcc) setSelectedPortalAccForClients(preSelectedContacts);
            else setSelectedContactForClient(preSelectedContacts);
            return preSelectedContactIds;
        } else {
            return null;
        }
    };

    const getNrpAccess = (isInitialValue = false) => {
        const fieldName = isInitialValue ? 'title' : 'roleName';
        const access = modalData?.nrpAccess;
        if (access) {
            return nrpAccssMenuItems.find((item) => item.roleName === access)[fieldName];
        } else {
            return nrpAccssMenuItems[0][fieldName];
        }
    };

    useEffect(() => {
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            firstName: modalData?.firstName || '',
            email: modalData?.email || '',
            role: getNrpAccess(),
            lastName: modalData?.lastName || '',
            phone: modalData?.phone || '',
            comment: modalData?.comment || '',
            fugaUsername: modalData?.fugaUserName || '',
            contactRole: modalData?.contactRole || '',
            clientsForContact:
                getSuggestionListForClient(modalData?.clientsForContact) ||
                selectedContactForClients,
            usersForContact:
                getSuggestionListForClient(modalData?.usersForContact, true) ||
                selectedPortalAccForClients,
        });
    }, [modalData]);

    const handleInputChange = (event) => {
        const value = event.currentTarget.value;
        const name = event.target.name;
        // if section bellow for counting and restricting more than 1000 symbols in comment input field
        if (name === 'comment') {
            if (mainDetailsInptValues.comment !== value) {
                const symbolsCount = value.length;
                if (symbolsCount > 1000) return;
                setCommentSymCount(symbolsCount);
            }
        }
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            [name]: value,
        });
    };

    const handlePortalAccForClients = (value) => {
        setSelectedPortalAccForClients(value);
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            usersForContact: value.map((el) => el.id),
        });
    };

    const handleContactForClients = (value) => {
        setSelectedContactForClient(value);
        setSelectedPortalAccForClients(value);
        const selectedIds = value.map((el) => el.id);
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            clientsForContact: selectedIds,
            usersForContact: selectedIds,
        });
    };

    const handleNrpAccessValueChange = (value) => {
        if (isEditing) {
            if (isNoAccessSelected(value)) {
                setWarningText(
                    "Warning, access to Neighbouring Rights Platform will be removed for this user when 'Save Contact' is selected",
                );
                warningRef.current.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                setWarningText('');
            }
        }
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            role: value,
        });
    };

    const isNrpSectionVisible = (role = '') => {
        const roleToCheck = role || mainDetailsInptValues.role;
        const nrpId = nrpAccssMenuItems.find((nrp) => nrp.roleName === roleToCheck)?.value;
        if (nrpId > 2) {
            return true;
        }
        return false;
    };

    const isNoAccessSelected = (role = '') => {
        const roleToCheck = role || mainDetailsInptValues.role;
        const nrpId = nrpAccssMenuItems.find((nrp) => nrp.roleName === roleToCheck)?.value;
        if (nrpId === 0) return true;
        return false;
    };

    const isAdminOrInternalSelected = (role = '') => {
        const roleToCheck = role || mainDetailsInptValues.role;
        const nrpId = nrpAccssMenuItems.find((nrp) => nrp.roleName === roleToCheck)?.value;
        if (nrpId === 1 || nrpId === 2) return true;
        return false;
    };

    const areReqFieldsFilled = () => {
        if (mainDetailsInptValues.firstName && mainDetailsInptValues.lastName) {
            if (isNoAccessSelected()) {
                return true;
            }
            if (isAdminOrInternalSelected() && mainDetailsInptValues.fugaUsername) {
                return true;
            }
            if (isNrpSectionVisible()) {
                if (
                    selectedPortalAccForClients.length > 0 &&
                    selectedContactForClients.length > 0 &&
                    mainDetailsInptValues.fugaUsername
                )
                    return true;
            } else {
                return false;
            }
        }
        return false;
    };

    return (
        <Dialog
            onClose={handleClose}
            open={isOpen}
            maxWidth="md"
            className={classes.dialogContainerPaper}
        >
            <DialogTitle onClose={handleClose} className={`${classes.title}`}>
                <Typography variant="h6">{`${isEditing ? 'Edit' : 'Add'} Contact`}</Typography>
            </DialogTitle>
            <DialogContent className={`${classes.content}`} ref={warningRef}>
                {(warningText || errorText) && (
                    <Alert
                        variant="outlined"
                        severity="error"
                        style={{ marginBottom: '32px', width: '552px' }}
                    >
                        {warningText || errorText}
                    </Alert>
                )}
                <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}
                >
                    Contact details
                </Typography>
                <Grid container spacing={4}>
                    <Grid item sx={6} md={6}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <TextFieldWrapper
                                label="First name(s)"
                                required
                                className={classes.textField}
                                onChange={(evt) => handleInputChange(evt)}
                                name="firstName"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={mainDetailsInptValues.firstName || ''}
                                inputProps={{ tabIndex: '1' }}
                            />
                            <br />
                            <TextFieldWrapper
                                helperText={errorTextForEmail && errorTextForEmail}
                                error={!!errorTextForEmail}
                                label="Email"
                                onChange={(evt) => handleInputChange(evt)}
                                name="email"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={mainDetailsInptValues.email || ''}
                                className={classes.textField}
                                inputProps={{ tabIndex: '3' }}
                            />
                            <br />
                            <TextFieldWrapper
                                label="Role"
                                onChange={(evt) => handleInputChange(evt)}
                                name="contactRole"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={mainDetailsInptValues.contactRole || ''}
                                className={classes.textField}
                                inputProps={{ tabIndex: '5' }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={6} md={6}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <TextFieldWrapper
                                label="Surname"
                                required
                                onChange={(evt) => handleInputChange(evt)}
                                name="lastName"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={mainDetailsInptValues.lastName || ''}
                                className={classes.textField}
                                inputProps={{ tabIndex: '2' }}
                            />
                            <br />
                            <TextFieldWrapper
                                label="Phone"
                                onChange={(evt) => handleInputChange(evt)}
                                name="phone"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={mainDetailsInptValues.phone || ''}
                                className={classes.textField}
                                inputProps={{ tabIndex: '4' }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <SuggesstionInput
                    inputProps={{ tabIndex: '6' }}
                    suggestionsWithoutSelectedFilter={{ list: allClientsListModel }}
                    idFieldName="clientId"
                    contentFieldName="legalCompanyName"
                    title="Contacts for clients"
                    selectedValues={selectedContactForClients}
                    setSelectedValues={handleContactForClients}
                />
                <TextFieldWrapper
                    label="Comment"
                    onChange={(evt) => handleInputChange(evt)}
                    name="comment"
                    type="text"
                    isDisabled={isDisabled}
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    multiline
                    maxRows={4}
                    value={mainDetailsInptValues.comment || ''}
                    inputProps={{ tabIndex: '7' }}
                    style={{ width: '552px' }}
                />
                <div className={classes.commentCounter}>
                    <FormHelperText>{commentSymCount}/1000</FormHelperText>
                </div>
                <br />
                <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}
                >
                    NR Portal credentials
                </Typography>
                <Grid container spacing={4}>
                    <Grid item sx={6} md={6}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <CommonDropDownWithInput
                                menuItemsList={nrpAccssMenuItems}
                                labelName="NRP access level"
                                preSelectedValue={getNrpAccess(true)}
                                isEditing={isEditing}
                                inputProps={{ tabIndex: '8' }}
                                onValueChangeCb={handleNrpAccessValueChange}
                                fieldToSend="roleName"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={6} md={6}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <TextFieldWrapper
                                label="FUGA user name"
                                required
                                onChange={(evt) => handleInputChange(evt)}
                                name="fugaUsername"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={mainDetailsInptValues.fugaUsername || ''}
                                className={`${classes.textField} ${
                                    isNoAccessSelected() ? classes.hide : ''
                                }`}
                                inputProps={{ tabIndex: '9' }}
                                helperText={errorText && errorText}
                                error={!!errorText}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {isNrpSectionVisible() && (
                    <SuggesstionInput
                        inputProps={{ tabIndex: '10' }}
                        suggestionsWithoutSelectedFilter={{
                            list: selectedContactForClients,
                            isFromBE: true,
                        }}
                        idFieldName="clientId"
                        contentFieldName="legalCompanyName"
                        title="Portal access for clients"
                        selectedValues={selectedPortalAccForClients}
                        setSelectedValues={handlePortalAccForClients}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="text" color="primary" disableElevation>
                    CANCEL
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={!areReqFieldsFilled()}
                >
                    SAVE CONTACT
                </Button>
            </DialogActions>
        </Dialog>
    );
}
