import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeStateByNestedKey } from '@actions/fugaActions';
import DropDownButton from '@common/DropDownButton';
import LoadingScreen from '@common/LoadingScreen';
import { Button, Typography } from '@material-ui/core';
import CommonTableGrid from '@common/CommonTableGrid';
import OverflowTip from '@common/OverflowTip';
import helper from '@utils/helper';
import DropDownButtonKebabMenu from '@common/DropDownButtonKebabMenu';
import { validateAssetMetadataById } from '@actions/apiActions';
import utils from '@utils/utils';
import { items } from '@data/constants';
import AssetMetaDataEditModal from '@common/AssetMetaDataEdit/AssetMetaDataEditModal';
import CommonMoreDropDownButton from '@common/CommonMoreDropDownButton';
import { EditOutlined } from '@material-ui/icons';
import CommonFiltersContainer from '@common/CommonFiltersContainer';
import TableFilters from '@common/TableFilters';
import { getAssetByPerformerId } from '@actions/performers';
import { uploadSpotifyDataFileAPI } from '@api/clientController';
import { exportSpecificRepertoireAPI } from '@api/export';

const headers = [
    {
        title: 'Artist(s)',
        dataIndex: 'artistList',
    },
    {
        title: 'Track / Version',
        dataIndex: ['trackTitle', 'versionTitle'],
    },
    {
        title: 'ISRC',
        dataIndex: 'isrcCode',
    },
    {
        title: 'Contr. Cat.',
        dataIndex: 'contributionCategory',
    },
    {
        title: 'Instruments',
        dataIndex: 'contributionInstruments',
    },
    {
        title: 'Product',
        dataIndex: 'albumTitle',
    },
    {
        title: 'Release Date',
        dataIndex: 'releaseDate',
        hasNumericData: true,
    },
    {
        title: 'Last modified',
        dataIndex: 'lastModified',
    },
    {
        title: 'Ingested',
        dataIndex: 'createdDate',
    },
    {
        title: '',
        dataIndex: '',
    },
];

function PerformerRepertoires(props) {
    const dispatch = useDispatch();
    const [openAssetMetadataModal, setOpenAssetMetadataModal] = useState(false);
    const [clickedDataItem, setClickedDataItem] = useState({});
    const performersRepertoireModel = useSelector(
        (state) => state.fugaReducers.performersRepertoire,
    );
    const isSpotifyIdsPresentModel = useSelector(
        (state) => state.fugaReducers.performers.byClietnId.isSpotifyIdsPresent,
    );

    const [isExportDisabled, setIsExportDisabled] = useState(false);

    useEffect(() => {
        dispatch(getAssetByPerformerId(props.match.params.id));
    }, [performersRepertoireModel]);

    useEffect(() => {
        setIsExportDisabled(isDisabled());
    }, [performersRepertoireModel.dataFromBE]);

    const openModal = () => {
        setOpenAssetMetadataModal(true);
    };

    const onFilterClearAll = () => {
        dispatch(getAssetByPerformerId(props.match.params.id));
    };

    const onFilterTableReload = (additionalFilters) => {
        dispatch(
            getAssetByPerformerId(props.match.params.id, {
                ...(additionalFilters && { ...additionalFilters }),
            }),
        );
    };

    const getClientSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle.includes('track')) {
            return (
                <>
                    <Typography variant="body2">
                        <OverflowTip>{dataItem[headerItem.dataIndex[0]]}</OverflowTip>
                    </Typography>
                    <Typography variant="caption">
                        <OverflowTip>{dataItem[headerItem.dataIndex[1]]}</OverflowTip>
                    </Typography>
                </>
            );
        } else if (coulumnTitle === '') {
            const menuItems = [
                {
                    title: 'Track details',
                    onClickClb: () => {
                        setClickedDataItem(dataItem);
                        openModal();
                    },
                    icon: (
                        <EditOutlined
                            style={{ width: '24px', height: '24px', color: '#00000099' }}
                        />
                    ),
                },
            ];
            return (
                <>
                    <CommonMoreDropDownButton menuItems={menuItems} />
                </>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
        }
    });

    const handleExportRepertoire = () => {
        const isPerformer = true;
        helper.exportRepertoire(props.match.params.id, dispatch, isPerformer);
    };

    const handleExportSpecificRepertoire = async () => {
        await exportSpecificRepertoireAPI(props.match.params.id);
    };

    const isDisabled = () => {
        const data = performersRepertoireModel.dataFromBE.content;
        if (data) {
            return data.length === 0;
        } else {
            return true;
        }
    };

    const getSpotify = async () => {
        await uploadSpotifyDataFileAPI(props.match.params.id);
    };

    return (
        <div className="topContainer" style={{ padding: '32px 0' }}>
            <div className="flex-horizontal">
                <div className="topContainer flex-left" style={{ width: '30%' }}></div>
                <div className="container flex-right btn-group" style={{ width: '70%' }}>
                    <Button
                        variant="outlined"
                        onClick={handleExportSpecificRepertoire}
                        disabled={isExportDisabled}
                    >
                        EXPORT REPERTOIRE
                    </Button>
                    {/* TODO lines below commented in scope of nr-401 */}
                    {/* <Button variant="outlined" style={{marginRight: '5px'}}>Bulk Edit</Button>
                    <Button variant="contained" color="primary" style={{marginRight: '5px'}}>Make a claim</Button> */}
                    {utils.checkItemRolesAndPermissions(
                        items.btns.performerRepertoireAddFromSource,
                    ) && <DropDownButton />}
                    {utils.checkItemRolesAndPermissions(items.btns.performerRepertoire) && (
                        <DropDownButtonKebabMenu
                            isSpotifyIdPresent={isSpotifyIdsPresentModel}
                            callbacks={{ getSpotifyCb: getSpotify }}
                            handleExportRepertoire={handleExportRepertoire}
                            disabled={isExportDisabled}
                        />
                    )}
                </div>
            </div>
            <CommonFiltersContainer
                innerFiltersComponent={
                    <TableFilters
                        tableReloadCb={onFilterTableReload}
                        onClearAll={onFilterClearAll}
                    />
                }
            />
            <CommonTableGrid
                dataList={performersRepertoireModel.dataFromBE}
                headerList={headers}
                cellDataCb={getClientSpecificData}
                loading={performersRepertoireModel.tableIsLoading}
                sortPrefs={{
                    storeName: 'performersRepertoire',
                    storeSubName: 'sortValue',
                    value: performersRepertoireModel.sortValue,
                }}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: getAssetByPerformerId,
                        argumentsList: [props.match.params.id],
                    },
                }}
                paginationBE
                selection={{
                    isSelectable: false,
                    dispatchFn: dispatch,
                    callbackFn: changeStateByNestedKey,
                }}
            />
            <LoadingScreen />
            <AssetMetaDataEditModal
                isOpen={openAssetMetadataModal}
                setOpen={setOpenAssetMetadataModal}
                dataItem={clickedDataItem}
                onSuccessCb={validateAssetMetadataById}
                isPerformer={true}
            />
        </div>
    );
}

const withUrlPerformerRepertoires = withRouter(PerformerRepertoires);
export default withUrlPerformerRepertoires;
