import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { useHistory, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';
import DeletePerformerDialog from '@common/DeletePerformerDialog';
import { createClient, editRightHolder } from '@actions/rightHolders';

const useStyles = makeStyles(() => ({
    btn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '24px',
        height: '24px',
        color: '#5F5F60',
        marginLeft: '21.02px',
    },
    btnInGroup: {
        marginLeft: '16px',
    },
}));

function ClientEditButtonGroup(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const clientPageModel = useSelector((state) => state.fugaReducers.clientPage);
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);

    const { isInSaveNewMode, isInEditMode } = clientPageModel;

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const history = useHistory();

    const getAliases = (aliases) => {
        if (!aliases.includes(',')) {
            return [aliases];
        } else {
            const regex = /(^([^,]+, )+[^,]+$)/;
            const arrayOfAliases = (aliases || '').split(', ');

            if (regex.test(aliases)) {
                return arrayOfAliases;
            } else {
                dispatch(
                    changeStateByNestedKey(
                        'rhData',
                        'aliasesError',
                        'Multiple entries should be separated with a comma and a space',
                    ),
                );

                dispatch(
                    changeStateByKey('infoMessage', {
                        isVisible: true,
                        message: 'Royalties Contract Reference(s) error',
                        severity: 'error',
                    }),
                );
                return '';
            }
        }
    };

    const existingFields = [
        { titleToRead: 'businessRegisteredAddress', titleToSend: 'businessRegisteredAddress' },
        { titleToRead: 'businessMailingAddress', titleToSend: 'businessMailingAddress' },
        { titleToRead: 'subLabels', titleToSend: 'subLabels' },
        { titleToRead: 'tier', titleToSend: 'tier' },
        { titleToRead: 'bankAccNum', titleToSend: 'bankAccountNumber' },
        { titleToRead: 'bankCity', titleToSend: 'bankCity' },
        { titleToRead: 'iban', titleToSend: 'bankIbanNumber' },
        { titleToRead: 'bankName', titleToSend: 'bankName' },
        { titleToRead: 'bankAccPayee', titleToSend: 'bankAccountPayee' },
        { titleToRead: 'bankCountry', titleToSend: 'bankCountry' },
        { titleToRead: 'bankSwiftNumber', titleToSend: 'bankSwiftNumber' },
        { titleToRead: 'comments', titleToSend: 'comments' },
        { titleToRead: 'advance', titleToSend: 'advance' },
        { titleToRead: 'rateAfterRec', titleToSend: 'rateAfterRecoupment' },
        { titleToRead: 'rateDuringRec', titleToSend: 'rateDuringRecoupment' },
        { titleToRead: 'accounting', titleToSend: 'accountingFrequency' },
        { titleToRead: 'postTerm', titleToSend: 'postTerm' },
        { titleToRead: 'currency', titleToSend: 'currency' },
        { titleToRead: 'fugaOrgId', titleToSend: 'fugaOrgId' },
        { titleToRead: 'otherIdentifiers', titleToSend: 'otherIdentifiers' },
        { titleToRead: 'royaltiesClientName', titleToSend: 'royaltiesClientName' },
        { titleToRead: 'clientAliases', titleToSend: 'clientAliases' },
        { titleToRead: 'ddexPartyId', titleToSend: 'ddexPartyId' },
    ];

    const addMissingFields = () => {
        // getting all the fields which are not empty and ready so send
        const fieldsToSend = existingFields.filter((field) => {
            const valueTosend = rhDataModel.mainDetailsInptValues[field.titleToRead];
            return (
                (valueTosend || valueTosend === 0) && {
                    [field.titleToSend]: rhDataModel.mainDetailsInptValues[field.titleToRead],
                }
            );
        });
        const formatedFieldsToSend = {};
        for (const item of fieldsToSend) {
            formatedFieldsToSend[item.titleToSend] =
                rhDataModel.mainDetailsInptValues[item.titleToRead];
        }
        return formatedFieldsToSend;
    };

    const handleNewClientSave = async () => {
        if (!isInEditMode && !isInSaveNewMode) {
            dispatch(changeStateByNestedKey('clientPage', 'isInEditMode', true));
            dispatch(changeStateByNestedKey('clientPage', 'areFieldsDisabled', false));
        } else {
            if (!rhDataModel.mainDetailsInptValues.clientName) {
                dispatch(
                    changeStateByKey('infoMessage', {
                        isVisible: true,
                        message: 'Name field is required',
                        severity: 'error',
                    }),
                );
            } else {
                const missingFilesList = addMissingFields();

                const clientInfo = {
                    companyName: rhDataModel.mainDetailsInptValues.clientName,
                    ...(rhDataModel.mainDetailsInptValues.advance && {
                        advance: rhDataModel.mainDetailsInptValues.advance,
                    }),
                    ...(rhDataModel.mainDetailsInptValues.dealRate && {
                        dealRate: rhDataModel.mainDetailsInptValues.dealRate,
                    }),
                    ...(rhDataModel.mainDetailsInptValues.contactName && {
                        contactName: rhDataModel.mainDetailsInptValues.contactName,
                    }),
                    ...(rhDataModel.mainDetailsInptValues.contactEmail && {
                        contactEmail: rhDataModel.mainDetailsInptValues.contactEmail,
                    }),
                    ...(rhDataModel.mainDetailsInptValues.contactPhone && {
                        contactPhone: rhDataModel.mainDetailsInptValues.contactPhone,
                    }),
                    ...(rhDataModel.mainDetailsInptValues.businessRegisteredAddress && {
                        businessRegisteredAddress:
                            rhDataModel.mainDetailsInptValues.businessRegisteredAddress,
                    }),
                    ...(rhDataModel.mainDetailsInptValues.clientPermissions && {
                        permissions: rhDataModel.mainDetailsInptValues.clientPermissions,
                    }),
                    ...(rhDataModel.mainDetailsInptValues.clientAliases && {
                        clientAliases: getAliases(rhDataModel.mainDetailsInptValues.clientAliases),
                    }),
                    ...missingFilesList,
                };

                if (
                    !rhDataModel.mainDetailsInptValues.clientAliases ||
                    (rhDataModel.mainDetailsInptValues.clientAliases &&
                        getAliases(rhDataModel.mainDetailsInptValues.clientAliases))
                ) {
                    if (!isInSaveNewMode && isInEditMode) {
                        clientInfo.clientId = rhDataModel.detailsData.clientId;
                        clientInfo.rightsHolderId = rhDataModel.detailsData.rhId;
                        // updating field name here as it is different for edit endpoint
                        const infoForEditing = {
                            ...clientInfo,
                            ...(clientInfo.businessRegisteredAddress && {
                                businessRegisteredAddress: clientInfo.businessRegisteredAddress,
                            }),
                            ...(clientInfo.companyName && {
                                legalCompanyName: clientInfo.companyName,
                            }),
                            ...(rhDataModel.mainDetailsInptValues.clientAliases && {
                                clientAliases: getAliases(
                                    rhDataModel.mainDetailsInptValues.clientAliases,
                                ),
                            }),
                        };

                        delete infoForEditing.companyName;
                        dispatch(editRightHolder(infoForEditing));
                    } else {
                        await dispatch(
                            createClient(
                                rhDataModel.mainDetailsInptValues.clientName,
                                clientInfo,
                                clientPageModel.selectedIndex,
                                history.push,
                            ),
                        );
                    }
                    dispatch(changeStateByNestedKey('rhData', 'aliasesError', ''));
                }
            }
        }
    };

    const handleCancel = () => {
        if (isInSaveNewMode) history.push('/clients');
        dispatch(changeStateByNestedKey('clientPage', 'isInEditMode', false));
        dispatch(changeStateByNestedKey('clientPage', 'isInSaveNewMode', false));
        dispatch(changeStateByNestedKey('clientPage', 'areFieldsDisabled', true));
    };

    return (
        <div>
            <div className="flex-horizontal">
                {(isInEditMode || isInSaveNewMode) && (
                    <Button
                        // disabled={isDisabled}
                        color="primary"
                        variant="outlined"
                        className={classes.btnInGroup}
                        onClick={handleCancel}
                    >
                        CANCEL
                    </Button>
                )}
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.btnInGroup}
                    onClick={handleNewClientSave}
                >
                    {!(isInEditMode || isInSaveNewMode) ? 'EDIT' : 'SAVE'}
                </Button>
                {!isInSaveNewMode && clientPageModel.isDeletable && (
                    <DeleteOutline onClick={() => setIsDialogOpen(true)} className={classes.btn} />
                )}
            </div>
            <DeletePerformerDialog
                isRh={true}
                isOpen={isDialogOpen}
                closeCb={setIsDialogOpen}
                clientId={props.match.params.id}
            />
        </div>
    );
}

const withUrlClientEditButtonGroup = withRouter(ClientEditButtonGroup);
export default connect((r) => r)(withUrlClientEditButtonGroup);
