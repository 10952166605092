import userData from '@data/userData';
import Fetch from './Fetch';
import moment from 'moment';
import { changeStateByKey } from '@actions/fugaActions';
import cerberusUtils from './cerberusUtils';

class Helpers {
    /**
     * Function for forming client deals data for deals table
     * @param {Array} clientInfoList - clientDealDTO0Set clients list from response
     * @returns {Array} - formed array
     */
    getDealsInfo(clientInfoList) {
        if (clientInfoList) {
            return clientInfoList.map((data) => {
                const startDate = data.territoryPeriod.startDate;
                const endDate = data.territoryPeriod.endDate;
                return {
                    clientDealId: data.client_deal_id,
                    territoryCodes: data.territoryPeriod.territoryDTO.territoryCodes,
                    territories: data.territoryPeriod.territoryDTO.formattedTerritory,
                    claimMethod: data.claimMethod,
                    startDate,
                    endDate,
                    status: moment() < moment(endDate) && moment(startDate) < moment(),
                    id: data.clientDealId,
                    locked: data.locked,
                };
            });
        } else {
            return [];
        }
    }

    /**
     * Function for forming client deals data for deals table for performers
     * @param {Array} clientInfoList - client_deals list from response
     * @returns {Object} - Object with content kay contining formed array
     */
    getDealsInfoPerformer(clientInfoList) {
        if (clientInfoList) {
            return {
                content: clientInfoList.map((data) => {
                    const startDate = data.start_date;
                    const endDate = data.end_date;
                    return {
                        territories: data.territories,
                        territoryCodes: data.territoryCodes,
                        claimMethod: data.claim_method,
                        startDate,
                        endDate,
                        status: moment() < moment(endDate) && moment(startDate) < moment(),
                        id: data.client_deal_id,
                        locked: data.locked,
                    };
                }),
            };
        } else {
            return { content: [] };
        }
    }

    /**
     * Function for download a readable stream from http request response
     * @param {ReadableStream} blobFileContent - readable stream from response (with the header contentDesposition/attachment)
     * @param {String} contentDisposition - conten-desposition header content
     */
    downloadFileFromResponse(blobFileContent, contentDisposition) {
        const fileName = contentDisposition.split('filename=')[1];
        const downloadUrl = window.URL.createObjectURL(blobFileContent);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        link.click();
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(downloadUrl);
        }, 100);
    }
    /**
     * Function for formatting territories array in readable for territories modal format
     * @param {Array} unformattedTerritories - array of unformatted territories from endpoint response
     * @param {Boolean} formatChecked - true if array needs to be formed as selected territories value
     * @returns {Array} - array of grouped territories
     */
    formatTerritoriesForModal(unformattedTerritories, formatChecked) {
        const groupedTerritories = [];
        if (formatChecked) {
            for (let ctyGroup of unformattedTerritories) {
                groupedTerritories[ctyGroup.region.toUpperCase()] = ctyGroup.territories.map(
                    (el) => el.code,
                );
            }
        } else {
            for (let ctyGroup of unformattedTerritories) {
                groupedTerritories.push({
                    territories: ctyGroup.territories.map((el) => ({ ...el, id: el.code })),
                    region: { id: ctyGroup.region.toUpperCase(), name: ctyGroup.region },
                });
            }
        }
        return groupedTerritories;
    }

    /**
     * Function for send respons and download an asset file per specific RH/Performer
     * @param {string} clientId - id of client which needs to be exported
     * @param {callback} dispatch - dispatching callback
     * @param {boolean} isPerformer - value for url
     */
    exportRepertoire(clientId, dispatch, isPerformer = false) {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        const urlPerfOrHR = isPerformer ? 'performer' : 'rh';
        const url = `${userData.api.baseAssetUrl()}/export/${urlPerfOrHR}/${clientId}`;
        dispatch(
            changeStateByKey('infoMessage', {
                isVisible: true,
                message: 'Export in progress',
                severity: 'success',
            }),
        );
        Fetch.fetchWrapper(url, options)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    res.blob().then((result) => {
                        this.downloadFileFromResponse(
                            result,
                            res.headers.get('content-disposition'),
                        );
                        dispatch(
                            changeStateByKey('infoMessage', {
                                isVisible: true,
                                message: 'Export complete',
                                severity: 'success',
                            }),
                        );
                    });
                } else {
                    res.json()
                        .then((result) => {
                            dispatch(
                                changeStateByKey('infoMessage', {
                                    isVisible: true,
                                    message: result.error || result?.data?.error,
                                    severity: 'error',
                                }),
                            );
                        })
                        .catch((err) => {
                            dispatch(
                                changeStateByKey('infoMessage', {
                                    isVisible: true,
                                    message: 'An error occurred while exporting',
                                    severity: 'error',
                                }),
                            );
                        });
                }
            })
            .catch((error) => {
                console.log('Failed to send request via url: ' + url, `error msg: ${error}`);
            });
    }

    /**
     * Function to get performer info by fullname
     * @param {String} fullname
     * @param {Callback} dispatch
     */
    getPerformerInfoByName(fullname, dispatch) {
        const url = `${userData.api.basePerformerUrl()}?fullname=${fullname}`;
        return Fetch.fetchWrapper(url).then((res) => {
            if (res.status === 200 || res.status === 201) {
                return res.json().then((response) => {
                    return response.data;
                });
            } else {
                res.json()
                    .then((result) => {
                        dispatch(
                            changeStateByKey('infoMessage', {
                                isVisible: true,
                                message: result.error || result.data.error,
                                severity: 'error',
                            }),
                        );
                    })
                    .catch((error) => {
                        console.log(
                            'Failed to send request via url: ' + url,
                            `error msg: ${error}`,
                        );
                        dispatch(
                            changeStateByKey('infoMessage', {
                                isVisible: true,
                                message: 'An error has occured',
                                severity: 'error',
                            }),
                        );
                    });
            }
        });
    }

    /**
     * Function for inserting id in certain place in given url
     * @param {String} url - url needs to be provided with following format 'someStr<string_to_be_replaced>someOtherStr'
     * @param {String} id - id that needs to be replaced
     * @returns string with replaced id value
     */
    generateLandingPage(url = '', id = '') {
        let idToInsert = id;
        if (!id) {
            idToInsert = cerberusUtils.getClientId();
        }
        const replacingRegex = /<.*>/g;
        return url.replace(replacingRegex, idToInsert);
    }

    /**
     * Function for validate a ISRC
     * @param {String} isrc - isrc code to be validated
     * @return {boolean} - valid true or false
     */
    validateISRC(isrc) {
        return !isrc || !!isrc.match(/^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/);
    }

    /**
     * Function adds or removes item to given list based on given index
     @param {Array} dataArray - array to look into
     @param {Any} value -  value to be added or removed
     @param {Number} selectedIndex - index of given value inside the list if it exists
     * @returns - new copy of array with updated value
     */
    updateList(dataArray, value, selectedIndex) {
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(dataArray, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(dataArray.slice(1));
        } else if (selectedIndex === dataArray.length - 1) {
            newSelected = newSelected.concat(dataArray.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                dataArray.slice(0, selectedIndex),
                dataArray.slice(selectedIndex + 1),
            );
        }
        return newSelected;
    }
}

export default new Helpers();
