import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllPerformerNamesForMetadata,
    getAllRolesAndInstrumentsForMetadata,
    getAssetMetadataById,
} from '@actions/apiActions';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { Alert } from '@material-ui/lab';
import { changeStateByNestedKey } from '@actions/fugaActions';
import AssetMetaDataRightsHoldersSection from './AssetMetaDataRightsHoldersSection';
import AssetMetaDataContributorsSection from './AssetMetaDataContributorsSection';
import AssetMetaDataProductsSection from './AssetMetaDataProductsSection';
import { AssetMetaDataArtistsSection } from '@common/AssetMetaDataEdit/AssetMetaDataArtistsSection';

const useStyles = makeStyles(() => ({
    title: {
        padding: '32px 32px 16px 32px',
        marginBottom: '16px',
    },
    content: {
        padding: '32px',
    },
    textField: {
        paddingBottom: 0,
        '& .MuiInputBase-input': {
            fontSize: '14px',
            lineHeight: '18px',
        },
    },
    commentCounter: {
        display: 'flex',
        paddingBottom: '21px',
        justifyContent: 'flex-end',
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    inputItemContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '100%',
        marginBottom: '16px',
    },
    firsInputInRow: {
        marginRight: '32px',
    },
    alertText: {
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    section: {
        marginTop: '32px',
    },
    sectionTitle: {
        marginBottom: '16px',
    },
    dialogPaper: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '964px',
        },
    },
}));

export default function AssetMetaDataEditModal({
    isOpen,
    setOpen,
    dataItem,
    onSuccessCb,
    isPerformer,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [mainDetailsInptValues, setMainDetailsInptValues] = useState({});
    const [isEditable, setIsEditable] = useState(true);
    const assetMetadataModel = useSelector((state) => state.fugaReducers.assetMetadata);
    const {
        dataObjFromBE,
        rhOwnershipDataFromBE,
        rhOwnershipSaveResult,
        contributorsDataFromBE,
        contributionSaveResult,
        productsDataFromBE,
    } = assetMetadataModel;
    const regularTextErrorMsg = 'Incorrect value, see message above';
    const isrcTextErrorMsg = 'Incorrect value';
    const cmoErrors = assetMetadataModel.validations?.cmoErrors || [];
    const generalWarnings = Object.entries(assetMetadataModel.validations?.warning || {});
    const generalError = Object.entries(assetMetadataModel.validations?.errors || {});

    const warningFieldNameMapping = [
        {
            fieldFromBe: 'track-title',
            displayName: 'Track Title', // same value as TextField label
            uiFieldName: 'assetTrackTitle',
        },
        {
            fieldFromBe: 'asset-title',
            displayName: 'Track version',
            uiFieldName: 'assetTrackVersion',
        },
        {
            fieldFromBe: 'display-artist',
            displayName: 'Display artists(s)',
            uiFieldName: 'assetDisplayArtists',
        },
        {
            fieldFromBe: 'isrc',
            displayName: 'ISRC',
            uiFieldName: 'assetIsrc',
        },
    ];

    useEffect(() => {
        if (isOpen) {
            dispatch(getAssetMetadataById(dataItem.assetId));
            dispatch(changeStateByNestedKey('assetMetadata', 'validations', {}));
            dispatch(getAllRolesAndInstrumentsForMetadata());
            dispatch(getAllPerformerNamesForMetadata());
        }
    }, [isOpen]);

    useEffect(() => {
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            // main section
            assetTrackTitle: dataObjFromBE.assetTrackTitle,
            assetTrackVersion: dataObjFromBE.assetTrackVersion,
            assetIsrc: dataObjFromBE.assetIsrc,
            assetFugaId: dataObjFromBE.assetFugaId,
            assetDisplayArtists: dataObjFromBE.assetDisplayArtists,
            // contributors section
            numberOfFeaturedContr: dataObjFromBE.numberOfFeaturedContr,
            numberOfNonFeaturedContr: dataObjFromBE.numberOfNonFeaturedContr,
            lineUpComplete: dataObjFromBE.lineUpComplete + '',
            // add new fields per each section
        });
    }, [dataObjFromBE]);

    const tableReloadOnSuccess = () => {
        dispatch(getAssetMetadataById(dataItem.assetId));
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            setMainDetailsInptValues({});
            dispatch(
                changeStateByNestedKey('assetMetadata', 'rhOwnershipDataFromBE', { content: [] }),
            );
            dispatch(
                changeStateByNestedKey('assetMetadata', 'contributorsDataFromBE', { content: [] }),
            );
            dispatch(
                changeStateByNestedKey('assetMetadata', 'rhOwnershipSaveResult', {
                    isSuccessful: false,
                    hasWarning: false,
                    isDeleted: false,
                }),
            );
            dispatch(
                changeStateByNestedKey('assetMetadata', 'contributionSaveResult', {
                    isSuccessful: false,
                    hasWarning: false,
                    isDeleted: false,
                }),
            );
        }
    };

    const handleSave = () => {
        if (onSuccessCb) {
            dispatch(
                onSuccessCb(
                    dataItem.assetId,
                    mainDetailsInptValues,
                    isPerformer,
                    {
                        isUpdateble: true,
                        arrayName: 'content',
                        idFieldName: 'assetId',
                        id: dataItem.assetId,
                        // update this list once new input field will be added to UI
                        // this fieald and also warningFieldNameMapping and fieldsMapping in editAssetMetadataById
                        // and reset obj with content in handleClose
                        fieldNamesListToChange: [
                            'assetTrackTitle',
                            'assetIsrc',
                            'assetDisplayArtists',
                            'assetTrackVersion',
                            'lastModified',
                            'numberOfFeaturedContr',
                            'numberOfNonFeaturedContr',
                            'lineUpComplete',
                        ],
                        isPerformer,
                    },
                    handleClose,
                ),
            );
        }
    };

    const castValueByitsType = (value, valueType) => {
        let finalValue = '';
        if (valueType.isNumber) {
            finalValue = parseInt(value);
        } else if (valueType.isBool) {
            const checkedValue = value === 'false' ? '' : value;
            finalValue = Boolean(checkedValue);
        } else {
            finalValue = value;
        }
        return finalValue;
    };

    const handleInputChange = (event, valueType = { isNumber: false, isBool: false }) => {
        const value = event.currentTarget.value;
        const name = event.target.name;
        const valueToSet = castValueByitsType(value, valueType);
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            [name]: valueToSet,
        });
    };

    const areAllRequiredFieldsFilled = () => {
        const requiredFieldsNameList = ['assetTrackTitle', 'assetIsrc', 'assetDisplayArtists'];
        return requiredFieldsNameList.every(
            (fieldName) => mainDetailsInptValues[fieldName]?.length > 0,
        );
    };

    const getInfoObjByBeFieldName = (beFieldName) => {
        return (
            warningFieldNameMapping.find((warnInfo) => warnInfo.fieldFromBe === beFieldName) || {}
        );
    };

    const validateInput = (currentFieldName) => {
        const allIssues = [...generalWarnings, ...generalError];

        return allIssues.find((warningData) => {
            const [fieldName] = warningData;
            const inputWithIssue = getInfoObjByBeFieldName(fieldName).uiFieldName;
            return currentFieldName === inputWithIssue;
        });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md" className={classes.dialogPaper}>
            <DialogTitle onClose={handleClose} className={`${classes.title}`}>
                <Typography variant="h5">Asset: {dataItem.trackTitle}</Typography>
            </DialogTitle>
            <DialogContent className={`${classes.content}`}>
                {generalError.length > 0 && (
                    <Alert
                        className={classes.alertText}
                        variant="outlined"
                        severity="error"
                        color="error"
                        style={{ marginBottom: '32px' }}
                    >
                        {generalError.map((errorData) => {
                            const [fieldName, warnMsg] = errorData;
                            return (
                                <div>
                                    {`'${fieldName}:' ${warnMsg}`}
                                    <br />
                                </div>
                            );
                        })}
                    </Alert>
                )}
                {generalWarnings.length > 0 && (
                    <Alert
                        className={classes.alertText}
                        variant="outlined"
                        severity="warning"
                        color="warning"
                        style={{ marginBottom: '32px' }}
                    >
                        {generalWarnings.map((warningData) => {
                            const [fieldName, warnMsg] = warningData;
                            const fieldDisplayName = getInfoObjByBeFieldName(fieldName).displayName;
                            return (
                                <div>
                                    {`'${fieldDisplayName}:' ${warnMsg}`}
                                    <br />
                                </div>
                            );
                        })}
                    </Alert>
                )}
                {cmoErrors.length > 0 && (
                    <Alert
                        className={classes.alertText}
                        variant="outlined"
                        severity="warning"
                        color="warning"
                        style={{ marginBottom: '32px' }}
                    >
                        {cmoErrors.map((cmoError) => {
                            return (
                                <div>
                                    {`'${cmoError.cmoValidationMessage.fieldName}' ${
                                        cmoError.cmoValidationMessage.validationMessage
                                    }. This is required for ${cmoError.cmoNames.join(', ')}`}
                                    <br />
                                </div>
                            );
                        })}
                    </Alert>
                )}
                <Typography variant="h6" className={classes.sectionTitle}>
                    Main details
                </Typography>
                <div className={classes.inputsContainer}>
                    <div className={`${classes.inputItemContainer}`}>
                        <TextFieldWrapper
                            label="Track Title"
                            onChange={(evt) => handleInputChange(evt)}
                            name="assetTrackTitle"
                            type="text"
                            required
                            multiline
                            maxRows={10}
                            value={mainDetailsInptValues.assetTrackTitle || ''}
                            classNameOpt={`${classes.textField} ${classes.firsInputInRow}`}
                            style={{ minWidth: '622px' }}
                            isDisabled={!isEditable}
                            InputProps={{
                                readOnly: !isEditable,
                            }}
                            helperText={validateInput('assetTrackTitle') && regularTextErrorMsg}
                            error={validateInput('assetTrackTitle')}
                        />
                        <TextFieldWrapper
                            label="Track version"
                            onChange={(evt) => handleInputChange(evt)}
                            name="assetTrackVersion"
                            type="text"
                            multiline
                            maxRows={10}
                            value={mainDetailsInptValues.assetTrackVersion || ''}
                            classNameOpt={classes.textField}
                            style={{ minWidth: '226px' }}
                            isDisabled={!isEditable}
                            InputProps={{
                                readOnly: !isEditable,
                            }}
                            helperText={validateInput('assetTrackVersion') && regularTextErrorMsg}
                            error={validateInput('assetTrackVersion')}
                        />
                    </div>
                    <div className={`${classes.inputItemContainer}`}>
                        <TextFieldWrapper
                            label="ISRC"
                            onChange={(evt) => handleInputChange(evt)}
                            name="assetIsrc"
                            type="text"
                            required
                            multiline
                            maxRows={10}
                            value={mainDetailsInptValues.assetIsrc || ''}
                            classNameOpt={`${classes.textField} ${classes.firsInputInRow}`}
                            style={{ width: '121px' }}
                            isDisabled={!isEditable}
                            InputProps={{
                                readOnly: !isEditable,
                            }}
                            helperText={validateInput('assetIsrc') && isrcTextErrorMsg}
                            error={validateInput('assetIsrc')}
                        />
                        <TextFieldWrapper
                            label="Display artists(s)"
                            onChange={(evt) => handleInputChange(evt)}
                            name="assetDisplayArtists"
                            type="text"
                            required
                            multiline
                            maxRows={10}
                            value={mainDetailsInptValues.assetDisplayArtists || ''}
                            classNameOpt={classes.textField}
                            style={{ width: '743px' }}
                            isDisabled={!isEditable}
                            InputProps={{
                                readOnly: !isEditable,
                            }}
                            helperText={validateInput('assetDisplayArtists') && regularTextErrorMsg}
                            error={validateInput('assetDisplayArtists')}
                        />
                    </div>
                </div>
                <div className={classes.section}>
                    <AssetMetaDataArtistsSection
                        dataItem={dataItem}
                        tableReloadOnSuccess={tableReloadOnSuccess}
                    />
                </div>
                <div className={classes.section}>
                    <AssetMetaDataContributorsSection
                        dataItem={dataItem}
                        contributorsDataFromBE={contributorsDataFromBE}
                        dataObjFromBE={dataObjFromBE}
                        inputOperations={{
                            handleInputChange,
                            mainDetailsInptValues,
                        }}
                        tableReloadOnSuccess={tableReloadOnSuccess}
                        contributionSaveResult={contributionSaveResult}
                    />
                </div>
                <div className={classes.section}>
                    <AssetMetaDataProductsSection
                        dataItem={dataItem}
                        productsDataFromBE={productsDataFromBE}
                    />
                </div>
                <div className={classes.section}>
                    <AssetMetaDataRightsHoldersSection
                        dataItem={dataItem}
                        rhOwnershipDataFromBE={rhOwnershipDataFromBE}
                        rhOwnershipSaveResult={rhOwnershipSaveResult}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary" disableElevation>
                    cancel
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={!areAllRequiredFieldsFilled()}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
