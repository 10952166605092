import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeStateByNestedKey } from '@actions/fugaActions';
import DropDownButton from '@common/DropDownButton';
import LoadingScreen from '@common/LoadingScreen';
import { Button, Popover, Typography, makeStyles } from '@material-ui/core';
import CommonTableGrid from '@common/CommonTableGrid';
import helper from '@utils/helper';
import OverflowTip from '@common/OverflowTip';
import utils from '@utils/utils';
import { items } from '@data/constants';
import InfoIconSvg from '@images/svgComponents/InfoIconSvg';
import { EditOutlined } from '@material-ui/icons';
import CommonMoreDropDownButton from '@common/CommonMoreDropDownButton';
import AssetMetaDataEditModal from '@common/AssetMetaDataEdit/AssetMetaDataEditModal';
import { validateAssetMetadataById } from '@actions/apiActions';
import CommonFiltersContainer from '@common/CommonFiltersContainer';
import TableFilters from '@common/TableFilters';
import { getRHAssetsById } from '@actions/rightHolders';

const useClasses = makeStyles(() => ({
    helpIcon: {
        width: '20px',
        height: '20px',
        marginLeft: '8px',
        cursor: 'pointer',
    },
    infoPopover: {
        '& .MuiPopover-paper': {
            width: '444px',
            padding: '16px 16px',
            boxSizing: 'content-box',
            marginTop: '7px',
        },
    },
    infoPopoverHeadings: {
        paddingTop: '8px',
        marginBottom: '6px',
    },
    infoPopoverRows: {
        paddingBottom: '8px',
        paddingTop: '2px',
    },
}));

function Repertoires(props) {
    const dispatch = useDispatch();
    const classes = useClasses();
    const [anchorEl, setAnchorEl] = useState(false);
    const [infoPopupText, setInfoPopupText] = useState({ title: '', text: '' });
    const [openAssetMetadataModal, setOpenAssetMetadataModal] = useState(false);
    const [clickedDataItem, setClickedDataItem] = useState({});
    const repertoiresModel = useSelector((state) => state.fugaReducers.repertoires);

    useEffect(() => {
        dispatch(getRHAssetsById(props.match.params.id));
    }, [repertoiresModel]);

    const openModal = () => {
        setOpenAssetMetadataModal(true);
    };

    const onFilterClearAll = () => {
        dispatch(getRHAssetsById(props.match.params.id));
    };

    const onFilterTableReload = (additionalFilters) => {
        dispatch(
            getRHAssetsById(props.match.params.id, {
                ...(additionalFilters && { ...additionalFilters }),
            }),
        );
    };

    const getClientSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle.includes('track')) {
            return (
                <>
                    <Typography variant="body2">
                        <OverflowTip>{dataItem[headerItem.dataIndex[0]]}</OverflowTip>
                    </Typography>
                    <Typography variant="caption">
                        <OverflowTip>{dataItem[headerItem.dataIndex[1]]}</OverflowTip>
                    </Typography>
                </>
            );
        } else if (coulumnTitle === '') {
            const menuItems = [
                {
                    title: 'Track details',
                    onClickClb: () => {
                        setClickedDataItem(dataItem);
                        openModal();
                    },
                    icon: (
                        <EditOutlined
                            style={{ width: '24px', height: '24px', color: '#00000099' }}
                        />
                    ),
                },
            ];
            return (
                <>
                    <CommonMoreDropDownButton menuItems={menuItems} />
                </>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
        }
    });

    const startDateHeaderSpecificAddition = useCallback((headerItem) => {
        return (
            <div onClick={(e) => handleInfoClick(e, headerItem)}>
                <InfoIconSvg styles={classes.helpIcon} />
            </div>
        );
    });

    const territoriesHeaderSpecificAddition = useCallback((headerItem) => {
        return (
            <div onClick={(e) => handleInfoClick(e, headerItem)}>
                <InfoIconSvg styles={classes.helpIcon} />
            </div>
        );
    });

    const headers = [
        {
            title: 'Artist(s)',
            dataIndex: 'artistList',
        },
        {
            title: 'Track / Version',
            dataIndex: ['trackTitle', 'versionTitle'],
        },
        {
            title: 'ISRC',
            dataIndex: 'isrcCode',
        },
        {
            title: 'PYEAR',
            dataIndex: 'assetPYear',
            hasNumericData: true,
        },
        {
            title: 'Product',
            dataIndex: 'albumTitle',
        },
        {
            title: 'Territories',
            dataIndex: 'ownershipTerritories',
            additionalHeaderContent: {
                headerCallBack: territoriesHeaderSpecificAddition,
            },
        },
        {
            title: 'Start Date',
            dataIndex: 'ownershipStartDate',
            additionalHeaderContent: {
                headerCallBack: startDateHeaderSpecificAddition,
            },
        },
        ...(utils.checkItemRolesAndPermissions(items.tableColiumns.repertoireEndDate)
            ? [
                  {
                      title: 'End Date',
                      dataIndex: 'ownershipEndDate',
                  },
              ]
            : []),
        {
            title: 'Last modified',
            dataIndex: 'lastModified',
        },
        {
            title: 'Ingested',
            dataIndex: 'createdDate',
        },
        {
            title: '',
            dataIndex: '',
        },
    ];

    const handleInfoClick = (evt, headerItem) => {
        const { currentTarget } = evt;
        setInfoPopupText({
            title: headerItem.title === 'Territories' ? 'Territories' : 'Dates',
            text:
                headerItem.title === 'Territories'
                    ? 'These are the territories of rights holder ownership of the asset, these may be different to the territories of the deal terms.'
                    : 'These are the dates of rights holder ownership of the asset, these may be different to the dates of the deal terms.',
        });
        setAnchorEl(currentTarget);
    };

    const handleInfoClose = () => {
        setAnchorEl(null);
    };

    const handleExportRepertoire = () => {
        helper.exportRepertoire(props.match.params.id, dispatch);
    };

    const openInfoPopover = Boolean(anchorEl);

    return (
        <div className="topContainer" style={{ padding: '32px 0' }}>
            <div className="flex-horizontal">
                <div className="topContainer flex-left" style={{ width: '30%' }}></div>
                <div className="container flex-right btn-group" style={{ width: '70%' }}>
                    <Button variant="outlined" onClick={handleExportRepertoire}>
                        EXPORT REPERTOIRE
                    </Button>
                    {utils.checkItemRolesAndPermissions(items.btns.rhRepertoireAddFromSource) && (
                        <DropDownButton />
                    )}
                </div>
            </div>
            <CommonFiltersContainer
                innerFiltersComponent={
                    <TableFilters
                        tableReloadCb={onFilterTableReload}
                        onClearAll={onFilterClearAll}
                    />
                }
            />
            <CommonTableGrid
                dataList={repertoiresModel.dataFromBE}
                headerList={headers}
                cellDataCb={getClientSpecificData}
                loading={repertoiresModel.tableIsLoading}
                sortPrefs={{
                    storeName: 'repertoires',
                    storeSubName: 'sortValue',
                    value: repertoiresModel.sortValue,
                    sortOnly: true,
                }}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: getRHAssetsById,
                        argumentsList: [props.match.params.id],
                    },
                }}
                paginationBE={true}
                selection={{
                    isSelectable: false,
                    dispatchFn: dispatch,
                    callbackFn: changeStateByNestedKey,
                }}
            />
            <Popover
                open={openInfoPopover}
                anchorEl={anchorEl}
                onClose={handleInfoClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.infoPopover}
            >
                <Typography variant="subtitle1" className={classes.infoPopoverHeadings}>
                    {infoPopupText.title}
                </Typography>
                <Typography variant="body2" className={classes.infoPopoverRows}>
                    {infoPopupText.text}
                </Typography>
            </Popover>
            <LoadingScreen />
            <AssetMetaDataEditModal
                isOpen={openAssetMetadataModal}
                setOpen={setOpenAssetMetadataModal}
                dataItem={clickedDataItem}
                onSuccessCb={validateAssetMetadataById}
                isPerformer={false}
            />
        </div>
    );
}

const withUrlRepertoires = withRouter(Repertoires);
export default withUrlRepertoires;
