import React, { useEffect } from 'react';
import { Table, DatePicker, Checkbox, Select } from 'antd';
import '@/styles.css';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import ClientRegistration from './ClientRegistration';
import { withRouter } from 'react-router-dom';

import { connect, useSelector, useDispatch } from 'react-redux';
import {
    changeStateByNestedKey,
    appendToStateByNestedKey,
    changeStateByKey,
} from '@actions/fugaActions';
import { Typography, Button } from '@material-ui/core';
import { addRow, loadState, sendSelectionToSave } from '@actions/clientCmoSelection';

const Types = ({ values }) => {
    return (
        <>
            {values.map((genre, idx) => {
                return (
                    <span key={idx} className="badge">
                        {genre}
                        <br />
                    </span>
                );
            })}
        </>
    );
};

function ClientCmoSelection(props) {
    const dispatch = useDispatch();
    const cmoClientModel = useSelector((state) => state.fugaReducers.cmoClient);

    useEffect(() => {
        dispatch(loadState(cmoClientModel, props.match.params.id));
    }, []);

    const handleChange = (value, record, types) => {
        record.cmo_name = value;
        record.rights = types;
        dispatch(changeStateByNestedKey('cmoClient', 'flag', false));
    };

    const onChangeStartDate = (date, dateString, record) => {
        record.startDate = date.format('YYYY-MM-DD');
        dispatch(changeStateByNestedKey('cmoClient', 'flag', false));
    };
    const onChangeEndDate = (date, dateString, record) => {
        record.endDate = date.format('YYYY-MM-DD');
        dispatch(changeStateByNestedKey('cmoClient', 'flag', false));
    };

    const filterDropdownTypes = (dropdownItems, value) => {
        const values = dropdownItems;
        const filteredItem = values.filter((item) => item.value === value);
        return filteredItem[0].rights;
    };

    const renderSelect = (text, record) => {
        const { dropdownItems } = cmoClientModel;
        return (
            <Select
                defaultValue={text}
                onChange={(value) =>
                    handleChange(value, record, filterDropdownTypes(dropdownItems, value))
                }
                style={{ width: '100%' }}
            >
                {dropdownItems.map((item, index) => (
                    <Select.Option value={item.value} key={`${item.value}`}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
        );
    };

    const renderActive = (record, initialFlag) => {
        if (initialFlag.isActive === true) {
            return <CheckOutlined style={{ fontSize: '16px', color: '#65c741' }} />;
        } else if (initialFlag.isActive === false) {
            return isActive(record) ? (
                <CheckOutlined style={{ fontSize: '16px', color: '#65c741' }} />
            ) : (
                <CloseOutlined style={{ fontSize: '16px', color: '#D51010FF' }} />
            );
        }
    };

    const isActive = (record) => {
        return (
            record.startDate &&
            record.endDate &&
            moment() < moment(record.endDate) &&
            moment(record.startDate) < moment()
        );
    };

    const clickCheckbox = (record) => {
        // TODO not click checkbox but check it(select)
        if (record.startDate && record.endDate) {
            if (moment(record.startDate) < moment(record.endDate)) {
                var itemToSave = {
                    startDate: record.startDate,
                    endDate: record.endDate,
                    territory: record.territoryCode,
                    cmoId: record.cmoId || record.cmo_name,
                    clientDealId: cmoClientModel.clientDealId,
                    dealType: cmoClientModel.dealType,
                    performerOrRightsHolderId: cmoClientModel.rightsHolderId,
                    claimMethod: cmoClientModel.claimMethod,
                };

                dispatch(appendToStateByNestedKey('cmoClient', 'dataToSend', itemToSave));
            } else {
                dispatch(
                    changeStateByKey('infoMessage', {
                        isVisible: true,
                        message: 'Start date should be before end date',
                        severity: 'error',
                    }),
                );
            }
        } else {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: "Mandatory data hasn't been set",
                    severity: 'error',
                }),
            );
        }
    };

    const saveSelections = () => {
        // this is using depricated method
        dispatch(
            sendSelectionToSave(cmoClientModel.dataToSend, cmoClientModel, props.match.params.id),
        );
    };

    const renderDate = (text, record) => {
        return (
            <DatePicker
                defaultValue={text ? moment(text, 'YYYY-MM-DD') : ''}
                onChange={(date, dateString) => onChangeStartDate(date, dateString, record)}
            />
        );
    };

    const renderTypes = (text, record) => {
        return <Types values={text} />;
    };

    const columns = [
        {
            title: 'Territory',
            dataIndex: 'territory',
            key: 'territory',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Start date',
            key: 'startDate',
            dataIndex: 'startDate',
            width: 350,
            render: (text, record) => renderDate(text, record),
        },
        {
            title: 'End date',
            key: 'endDate',
            dataIndex: 'endDate',
            width: 350,
            render: (text, record) => (
                <DatePicker
                    defaultValue={record.endDate ? moment(record.endDate, 'YYYY-MM-DD') : ''}
                    onChange={(date, dateString) => onChangeEndDate(date, dateString, record)}
                />
            ),
        },
        {
            title: 'Active',
            key: 'active',
            render: (text, record) => renderActive(record, text),
        },
        {
            title: 'Deal claim method',
            dataIndex: 'deal_method',
            key: 'deal_method',
        },
        {
            title: 'CMO name',
            key: 'cmo_name',
            dataIndex: 'cmo_name',
            render: (text, record) => renderSelect(text, record),
        },
        {
            title: 'Rights',
            dataIndex: 'rights',
            key: 'rights',
            render: (text, record) => renderTypes(text, record),
        },
        {
            title: 'Client status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <PlusOutlined onClick={() => dispatch(addRow(record, cmoClientModel))} />
            ),
        },
        {
            title: 'Select',
            key: 'select',
            render: (text, record) => <Checkbox onClick={() => clickCheckbox(record)} />,
        },
    ];

    function handleRowError(record, index) {
        const row = cmoClientModel.errorRows[0];
        let errRowMatched = true;
        if (row) {
            for (const key in row) {
                if (record[key] && row[key] !== record[key]) {
                    errRowMatched = false;
                    break;
                }
            }
        } else {
            errRowMatched = false;
        }
        return errRowMatched ? 'error-row' : '';
    }

    return (
        <div className="mainComponent">
            <div className="flex-horizontal" style={{ justifyContent: 'space-between' }}>
                <div className="topContainer flex-left" style={{ width: '70%' }}>
                    {props.children}
                    <Typography variant="body1">
                        <b>Client deal type:</b> {cmoClientModel.dealType}
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        <b>Claim method in the deal:</b> {cmoClientModel.claimMethod}
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        <b>Deal terms:</b> {cmoClientModel.territoryPeriodStart} -{' '}
                        {cmoClientModel.territoryPeriodEnd}
                    </Typography>
                    <br />
                </div>
                <ClientRegistration style={{ width: '30%' }} />
            </div>

            <Table
                columns={columns}
                dataSource={cmoClientModel.dataFromBE}
                loading={cmoClientModel.tableIsLoading}
                rowClassName={handleRowError}
            />

            <div className="flex-right">
                <Button variant="outlined" onClick={() => saveSelections()}>
                    {' '}
                    Save{' '}
                </Button>
            </div>
        </div>
    );
}

const withUrlDataClientCmoSelection = withRouter(ClientCmoSelection);
export default connect((r) => r)(withUrlDataClientCmoSelection);
