import React, { useState } from 'react';
import { Box, Button, ClickAwayListener, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    box: {
        padding: '24px',
        width: '400px',
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    actionBtns: {
        marginTop: '32px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginLeft: '24px',
        },
    },
}));

export const FilterMenuDialogWithDatepicker = ({ onConfirm, anchorEl, closeCb, isOpen }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const classes = useStyles();

    const handleClose = () => {
        closeCb(false);
    };

    const handleConfirm = () => {
        const parsedStartDate = startDate ? startDate.toISOString().split('T')[0] : '';
        const parsedEndDate = endDate ? endDate.toISOString().split('T')[0] : '';
        onConfirm({
            valuesToShow: [parsedStartDate, parsedEndDate],
            valuesToSend: {
                lastModifiedAfterDate: parsedStartDate,
                lastModifiedBeforeDate: parsedEndDate,
            },
        });
        handleClose();
    };

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box className={classes.box}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="start-date-picker"
                            label="Modified start date"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={setStartDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change start date',
                            }}
                            fullWidth
                        />
                        <KeyboardDatePicker
                            margin="normal"
                            id="end-date-picker"
                            label="Modified end date"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={setEndDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change end date',
                            }}
                            fullWidth
                        />
                        <div className={classes.actionBtns}>
                            <Button
                                onClick={handleClose}
                                variant="text"
                                color="primary"
                                disableElevation
                            >
                                cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={handleConfirm}
                                disabled={!startDate || !endDate}
                            >
                                apply
                            </Button>
                        </div>
                    </Box>
                </MuiPickersUtilsProvider>
            </ClickAwayListener>
        </Popover>
    );
};
