import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import StatementsGuideSvg from '@images/svgComponents/StatementsGuideSvg';
import DownloadIconStatementsSvg from '@images/svgComponents/DownloadIconStatementsSvg';
import classNames from 'classnames';
import { downloadStatementsByIdsAPI, downloadStatementsGuideAPI } from '@api/statementsController';

const useClasses = makeStyles(() => ({
    infoIcon: {
        marginRight: '8px',
        height: '21px',
    },
    downloadStatementsIcon: {
        marginRight: '8px',
    },
    downloadStatementsIconEnabled: {
        '& svg': {
            fill: '#FFFFFF',
        },
    },
    downloadStatementsIconDisabled: {
        '& svg': {
            fill: '#A6A6A6',
        },
    },
    flexHor: {
        display: 'flex',
        justifyContent: 'spaceAround',
        alignItems: 'center',
    },
    firstContainer: {
        width: '30%',
        alignItems: 'flex-start',
    },
    secondContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '70%',
        padding: '16px 0',
    },
}));

const StatementSourceEnum = {
    RASA: 'RASA',
    CURVE: 'CURVE',
};

export default function StatementsBtnGroup({ selectedFiles, clientId }) {
    const classes = useClasses();
    const dispatch = useDispatch();

    const downloadStatements = async () => {
        const statementIds = selectedFiles
            .filter((file) => file.statementSource === StatementSourceEnum.RASA)
            .map((file) => file.id);

        const curveStatementIds = selectedFiles
            .filter((file) => file.statementSource === StatementSourceEnum.CURVE)
            .map((file) => file.id);

        await downloadStatementsByIdsAPI({ clientId, statementIds, curveStatementIds });
    };

    const downloadStatementGuide = async () => {
        await downloadStatementsGuideAPI();
    };

    return (
        <div>
            <div className={classes.flexHor}>
                <div className={classes.firstContainer}></div>
                <div className={classes.secondContainer}>
                    <Button
                        variant="text"
                        color="primary"
                        disableRipple
                        disableElevation
                        onClick={downloadStatementGuide}
                        style={{ marginRight: '16px' }}
                    >
                        <StatementsGuideSvg styles={classes.infoIcon} />
                        PDF GUIDE
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disableRipple
                        disableElevation
                        disabled={selectedFiles.length === 0}
                        onClick={downloadStatements}
                    >
                        <DownloadIconStatementsSvg
                            styles={classNames(classes.downloadStatementsIcon, {
                                [classes.downloadStatementsIconEnabled]: selectedFiles.length > 0,
                                [classes.downloadStatementsIconDisabled]:
                                    selectedFiles.length === 0,
                            })}
                        />{' '}
                        STATEMENTS
                    </Button>
                </div>
            </div>
        </div>
    );
}
