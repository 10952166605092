import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { withRouter } from 'react-router-dom';
import { FormControl, Grid, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PerformerDealsTable from './PerformerDealsTable';
import TerritoriesPerformersModal from '@common/modals/TerritoriesPerformersModal';
import { CommonDropDownWithInput } from '@common/CommonDropDownWithInput';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';

const currencyMenuItems = [
    {
        title: 'EUR',
        value: 'EUR',
    },
    {
        title: 'GBP',
        value: 'GBP',
    },
    {
        title: 'USD',
        value: 'USD',
    },
];

const PerformerDealTermsContent = (props) => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true);
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const { byClietnId } = performersModel;

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                advance:
                    performersModel.isInSaveNewMode && performersModel.selectedIndex < 0
                        ? ''
                        : byClietnId.advance || '',
                rateAfterRec:
                    byClietnId.rateAfterRec + '' !== 'undefined' ? byClietnId.rateAfterRec : '',
                accounting: byClietnId.accountingFrequency || '',
                rateDuringRec:
                    byClietnId.rateDuringRec + '' !== 'undefined' ? byClietnId.rateDuringRec : '',
                postTerm: byClietnId.postTerm || '',
                currency: byClietnId.currency || '',
            }),
        );
    }, [performersModel.byClietnId]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };
    const handleCurrencyValueChange = (value) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                currency: value,
            }),
        );
    };

    const handleAddDeal = () => {
        if (props.match.params.id === '-1') {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please save this Client before adding their Deal terms',
                    severity: 'warning',
                }),
            );
        } else {
            setShowTerritoryModal(true);
        }
    };

    return (
        <>
            <div className="topContainer flex-left">
                <Grid container spacing={4}>
                    <Grid item sx={6} md={6}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <TextFieldWrapper
                                label="Advance"
                                onChange={(evt) => handleInputChange(evt)}
                                name="advance"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.advance
                                        ? performersModel.mainDetailsInptValues.advance
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                                inputProps={{ tabIndex: '16' }}
                            />
                            <br />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '18' }}
                                label="Rate (after recoupment)"
                                onChange={(evt) => handleInputChange(evt)}
                                name="rateAfterRec"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.rateAfterRec
                                        ? performersModel.mainDetailsInptValues.rateAfterRec
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <br />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '20' }}
                                label="Accounting Frequency"
                                onChange={(evt) => handleInputChange(evt)}
                                name="accounting"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.accounting
                                        ? performersModel.mainDetailsInptValues.accounting
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <br />
                        </FormControl>
                    </Grid>
                    <Grid item sx={6} md={6}>
                        <FormControl
                            variant="standard"
                            style={{ textAlign: 'left', width: '100%' }}
                        >
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '17' }}
                                label="Rate (during recoupment)"
                                onChange={(evt) => handleInputChange(evt)}
                                name="rateDuringRec"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.rateDuringRec
                                        ? performersModel.mainDetailsInptValues.rateDuringRec
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <br />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '19' }}
                                label="Post Term"
                                onChange={(evt) => handleInputChange(evt)}
                                name="postTerm"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.postTerm
                                        ? performersModel.mainDetailsInptValues.postTerm
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <br />
                            <CommonDropDownWithInput
                                menuItemsList={currencyMenuItems}
                                labelName="Currency"
                                preSelectedValue={
                                    performersModel.mainDetailsInptValues.currency
                                        ? performersModel.mainDetailsInptValues.currency
                                        : ''
                                }
                                isEditing={isDisabled}
                                isDisabled={isDisabled}
                                inputProps={{ tabIndex: '4' }}
                                onValueChangeCb={handleCurrencyValueChange}
                            />
                            <br />
                        </FormControl>
                    </Grid>
                </Grid>
                <div
                    className="flex-horizontal"
                    style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        marginBottom: '13.5px',
                    }}
                >
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        Deals
                    </Typography>
                    <Button variant="outlined" onClick={handleAddDeal}>
                        <AddIcon style={{ margin: '0 10px 0 0' }} />
                        ADD DEAL
                    </Button>
                </div>
                <PerformerDealsTable />
                <TerritoriesPerformersModal
                    showTerritoryModal={showTerritoryModal}
                    setShowTerritoryModal={setShowTerritoryModal}
                    territories={territories}
                    setTerritories={setTerritories}
                    groupedTerritories={territoriesModel.groupedTerritories}
                    clientId={props.match.params.id}
                    isEditing={false}
                />
            </div>
        </>
    );
};

const withUrlPerformerDealTermsContent = withRouter(PerformerDealTermsContent);
export default connect((r) => r)(withUrlPerformerDealTermsContent);
