import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseStatementsUrl();
const baseFileStorageUrl = userData.api.baseFileStorageUrl();

function getPeriodsForStatementsAPI() {
    const url = `${CONTROLLER_URL}/periods`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get periods',
    });
}

function exportStatementsUserInfoAPI(periodsArr) {
    const url = `${CONTROLLER_URL}/export-stmUserInfo`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(periodsArr),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        downloadResp: true,
        successMessage: 'Exporting Statements User Info...',
        errorMessage: 'Failed to export Statements User Info',
        pendingMessage: 'Exporting Statements User Info...',
    });
}

function downloadStatementsByIdsAPI({ clientId, statementIds, curveStatementIds }) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ statementIds, curveStatementIds }),
    };

    const url = `${CONTROLLER_URL}/download-statements?clientId=${clientId}`;

    return Fetch.fetchWithToasts({
        url,
        options,
        downloadResp: true,
        pendingMessage: 'Preparing statements for download...',
        successMessage: 'Statements download initiated. Check your downloads folder.',
        errorMessage: 'Failed to download statements. Please try again later.',
    });
}

function downloadStatementsGuideAPI() {
    return function (dispatch) {
        const url = `${baseFileStorageUrl}/Neighbouring_Rights_Statements_guide`;

        return Fetch.fetchWithToasts({
            url,
            dispatch,
            downloadResp: true,
            successMessage: 'Download initiated. Check your downloads folder.',
            errorMessage: 'Failed to download the guide. Please try again later.',
            pendingMessage: 'Preparing guide for download...',
        });
    };
}

export {
    getPeriodsForStatementsAPI,
    exportStatementsUserInfoAPI,
    downloadStatementsByIdsAPI,
    downloadStatementsGuideAPI,
};
