import React, { useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import ChipInput from 'material-ui-chip-input';
import { makeStyles, MenuItem } from '@material-ui/core';
import { Typography } from 'antd';

const useStyles = makeStyles(() => ({
    suggestionContainer: {
        '& ul': {
            padding: 0,
            margin: 0,
        },
        zIndex: '9999',
        overflowY: 'scroll',
        borderRadius: '4px',
        background: '#FFFFFF',
        width: '395px',
        maxHeight: '250px',
        boxShadow: `0px 6px 6px rgba(0, 0, 0, 0.26),
          0px 10px 20px rgba(0, 0, 0, 0.19)`,
    },
    suggestion: {
        listStyleType: 'none',
        height: '48px',
        '&:hover, &:focus': {
            cursor: 'pointer',
        },
    },
    chipInInput: {
        '& .MuiChip-root': {
            margin: '0 8px 4px 0',
        },
        '& .MuiChip-label': {
            overflow: 'unset',
        },
    },
}));

export default function SuggestionInput({
    suggestionsWithoutSelectedFilter = { itemsList: [], isFromBE: false },
    idFieldName,
    contentFieldName,
    selectedValues = [],
    setSelectedValues,
    title,
}) {
    const styles = useStyles();
    const [value, setValue] = useState('');
    const [initialSuggestionsList, setInitialSuggestionsList] = useState([]);
    const [suggestionsList, setSuggestionsList] = useState([]);
    const [shrink, setShrink] = useState(false);
    const suggestInputRef = useRef(<></>);

    useEffect(() => {
        let formatedList = [];
        if (!suggestionsWithoutSelectedFilter.isFromBE) {
            formatedList = suggestionsWithoutSelectedFilter.list.map((item) => {
                return { id: item[idFieldName], name: item[contentFieldName] };
            });
        } else {
            formatedList = suggestionsWithoutSelectedFilter.list;
        }

        setSuggestionsList(formatedList);
        setInitialSuggestionsList(formatedList);
    }, [suggestionsWithoutSelectedFilter.list]);

    useEffect(() => {
        suggestInputRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (selectedValues.length > 0) setShrink(true);
    }, [selectedValues]);

    const theme = {
        suggestionsContainerOpen: styles.suggestionContainer,
        suggestion: styles.suggestion,
        suggestionFocused: styles.suggestionFocused,
    };

    const onInputChange = (e) => {
        const value = e.target.value;
        setValue(value);
    };

    const getSuggestionValue = (suggestion) => {
        return suggestion.name;
    };

    const inputProps = {
        value: value + '',
        onChange: onInputChange,
        style: {
            width: '100%',
        },
    };

    const onSuggestionSelected = (event, { suggestion = null }) => {
        const newSelectedValue = [...selectedValues, suggestion];
        setSelectedValues(newSelectedValue);
        setValue('');
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        const lowerSugesions = initialSuggestionsList.map((suggestion) => {
            return { ...suggestion, nameLower: suggestion.name.toLowerCase() };
        });
        const filteredSuggestions = lowerSugesions.filter((suggestion) => {
            return suggestion.nameLower.includes(value.toLowerCase());
        });

        const filterOutSelecteds = filteredSuggestions.filter((item) => {
            const allNames = selectedValues.map((el) => el.name);
            return !allNames.includes(item.name);
        });

        const valuesToSuggest =
            selectedValues.length > 0 ? filterOutSelecteds : filteredSuggestions;
        setSuggestionsList(valuesToSuggest);
    };

    const removeFilter = (targetToRemove) => {
        const itemIndex = selectedValues.indexOf(targetToRemove);
        const selectedValuesCp = [...selectedValues];
        if (itemIndex > -1) {
            selectedValuesCp.splice(itemIndex, 1);
            setSelectedValues(selectedValuesCp);
            if (selectedValues.length <= 1) {
                setShrink(false);
            }
        }
    };

    const renderSuggestion = (suggestion) => {
        return (
            <MenuItem style={{ height: '100%' }}>
                <Typography variant="body1">{suggestion.name}</Typography>
            </MenuItem>
        );
    };

    const onChipInputBlur = () => {
        if (selectedValues.length <= 0 && value === '') {
            setShrink(false);
        }
    };

    return (
        <div style={{ marginBottom: '32px', maxWidth: '552px' }} ref={suggestInputRef}>
            <Autosuggest
                suggestions={suggestionsList}
                inputProps={inputProps}
                theme={theme}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderInputComponent={(inputProps) => {
                    return (
                        <div>
                            <ChipInput
                                className={styles.chipInInput}
                                label={title || ''}
                                innerRef={inputProps.ref}
                                InputProps={{
                                    ...inputProps,
                                    inputProps: {
                                        onBlur: onChipInputBlur,
                                        onFocus: () => setShrink(true),
                                    },
                                }}
                                InputLabelProps={{ shrink }}
                                onUpdateInput={onInputChange}
                                fullWidth={true}
                                onDelete={removeFilter}
                                value={selectedValues}
                                dataSourceConfig={{ text: 'name', value: 'id' }}
                            />
                        </div>
                    );
                }}
                onSuggestionSelected={onSuggestionSelected}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            />
        </div>
    );
}
