import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseClientUrl();

function getClientsListAPI() {
    const url = `${CONTROLLER_URL}/all`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get clients list',
    });
}

function getCmoIssuedIdsByClientIdAPI(clientId) {
    const url = `${CONTROLLER_URL}/client-cmo-issued-ids/${clientId}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get cmo issued ids list',
    });
}

function getCmoConnectionsByClientIdAPI(clientId) {
    const url = `${CONTROLLER_URL}/cmo-connection-by-client-id/${clientId}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get cmo connections',
    });
}

function uploadSpotifyDataFileAPI(id) {
    const url = `${CONTROLLER_URL}/spotify-export?clientId=${id}`;

    return Fetch.fetchWithToasts({
        url,
        downloadResp: true,
        successMessage: 'Spotify data file successfully exported',
        errorMessage: 'Failed to export Spotify data file',
        pendingMessage: 'Exporting Spotify data file...',
    });
}

function getStatementsByClientIdAPI(clientId) {
    const url = `${userData.api.baseStatementsUrl()}/by-client?clientId=${clientId}`;

    return Fetch.fetchWithErrorToast({ url, errorMessage: 'Failed to get statements list' });
}

function getRhClientInfoByIdAPI(clientId) {
    const url = `${CONTROLLER_URL}/main-details/${clientId}`;

    return Fetch.fetchWithErrorToast({ url, errorMessage: 'Failed to get client info' });
}

function createInnerClientAPI(content, id, isPerformer) {
    const endpoint = isPerformer ? 'create-performer-client' : 'create-rh-client';
    const url = `${CONTROLLER_URL}/${endpoint}`;
    const contendData = isPerformer ? content.createPerfClient : content;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ...contendData,
            [isPerformer ? 'performerId' : 'rightsHolderId']: id,
        }),
    };

    const errorMessage = isPerformer
        ? 'Failed to create performer client'
        : 'Failed to create rights holder client';

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage,
    });
}

function createCmoIssuedIdAPI(body) {
    const url = `${CONTROLLER_URL}/client-cmo-issued-id`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to create cmo issued id',
    });
}

function editCmoIssuedIdAPI(body) {
    const url = `${CONTROLLER_URL}/edit-client-cmo-issued-id`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to edit cmo issued id',
    });
}

function deleteClientByIdAPI(clientId) {
    const url = `${CONTROLLER_URL}/${clientId}`;

    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Client was deleted successfully',
        errorMessage: 'Failed to delete client',
        pendingMessage: 'Deleting client...',
    });
}

export {
    getClientsListAPI,
    getStatementsByClientIdAPI,
    getCmoIssuedIdsByClientIdAPI,
    getRhClientInfoByIdAPI,
    getCmoConnectionsByClientIdAPI,
    createInnerClientAPI,
    createCmoIssuedIdAPI,
    uploadSpotifyDataFileAPI,
    editCmoIssuedIdAPI,
    deleteClientByIdAPI,
};
