import React, { useEffect, useState } from 'react';
import {
    IconButton,
    InputAdornment,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    iconBtn: {
        padding: '0',
        margin: '0',
        '&:hover': {
            background: 'none',
        },
    },
}));

export function CommonDropDownWithInput({
    menuItemsList,
    isEditing,
    labelName,
    preSelectedValue,
    onValueChangeCb,
    inputProps,
    fieldToSend = 'title',
    isDisabled,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState('');
    const [shrink, setShrink] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (!!preSelectedValue || preSelectedValue === 0) {
            setValue(preSelectedValue);
            setShrink(true);
        }
    }, [preSelectedValue]);

    useEffect(() => {
        if (!isEditing) {
            setValue(menuItemsList[0].title);
        }
    }, []);

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onItemClick = (item) => {
        handleClose();
        setValue(item.title);
        onValueChangeCb(item[fieldToSend]);
        setShrink(true);
    };

    return (
        <>
            <TextField
                style={{ marginBottom: '32px' }}
                value={value}
                label={labelName}
                disabled={isDisabled}
                inputProps={inputProps}
                InputLabelProps={{ shrink: shrink }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                className={`${classes.iconBtn}`}
                                disableRipple
                                onClick={(e) => handleOpen(e)}
                            >
                                <ArrowDropDown style={{ marginRight: '0' }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {menuItemsList &&
                    menuItemsList.map((item, ind) => (
                        <MenuItem
                            key={`${ind}-${item.title}`}
                            onClick={() => onItemClick(item)}
                            style={{ padding: '0px 15px' }}
                        >
                            {item.icon ? item.icon : <></>}
                            <Typography variant="body1" style={{ margin: '14px 0 5px 0' }}>
                                {item.title}
                            </Typography>
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
}
