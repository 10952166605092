import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { FormControl, Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';

const useStyles = makeStyles(() => ({
    textField: {
        margin: '0 0 32px 0',
    },
    rootSection: {
        width: '100%',
    },
}));

const ClientCommentsContent = () => {
    const dispatch = useDispatch();
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const { detailsData } = rhDataModel;
    const params = useParams();
    const classes = useStyles();
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    useEffect(() => {
        if (params.id === '-1' || !areFieldsDisabled) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [areFieldsDisabled, params.id]);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                comments: detailsData.comments,
            }),
        );
    }, [rhDataModel.detailsData]);

    const [isDisabled, setIsDisabled] = useState(true);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <Grid container spacing={4}>
            <Grid item sx={6} md={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '27' }}
                        multiline={true}
                        label="comments"
                        onChange={(evt) => handleInputChange(evt)}
                        name="comments"
                        type="text"
                        value={rhDataModel.mainDetailsInptValues.comments || ''}
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ClientCommentsContent;
