import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseUrl;

function getAllRightsHoldersAPI(queryParams = {}) {
    const url = new URL(`${CONTROLLER_URL}/rh-client/all`);

    url.search = new URLSearchParams({
        ...(queryParams.page ? { page: queryParams.page } : {}),
        ...(queryParams.size ? { size: queryParams.size } : {}),
        ...(queryParams.searchTerm ? { search: queryParams.searchTerm } : {}),
        ...(queryParams.cmo ? { cmo: queryParams.cmo } : {}),
        ...(queryParams.statuses ? { statuses: queryParams.statuses } : {}),
        ...(queryParams.alerts ? { alerts: queryParams.alerts } : {}),
    });

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get rights holders list',
    });
}

export { getAllRightsHoldersAPI };
