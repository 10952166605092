import { MAINTENANCE_LOCAL_STORAGE_NAME } from '@data/globalConstants';
import cerberusUtils from './cerberusUtils';
import helper from './helper';
import { toast } from 'react-toastify';

const {
    getUserRole,
    setUserRole,
    getCerberusToken,
    setCerberusToken,
    setVarToLocalStorage,
    getVarFromLocalStorage,
} = cerberusUtils;

function showToastError(errorMessage) {
    toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

class Fetch {
    static fetchWrapper(url, options = {}) {
        if (!import.meta.env.REACT_APP_BASE_URL.includes('localhost')) {
            options.credentials = 'include';
        }

        const token = getCerberusToken();
        if (token) {
            options.headers = {
                ...options.headers,
                'x-nr-cerberus-token': token,
                Origin: 'http://localhost:3000',
            };
        }
        return fetch(url, options)
            .then((res) => {
                if (res.status === 401) {
                    setUserRole(''); // remove role.
                    setCerberusToken(''); // is fetch unauthorized remove token.
                    window.location.assign('/login');
                } else if (res.status === 503) {
                    const isOnMaintenance = getVarFromLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME);
                    if (!isOnMaintenance) {
                        setVarToLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME, true);
                        window.location.assign('/maintenance');
                    }
                }
                let role = res.headers.get('Nr-Role');
                if (role) {
                    role = role.toLocaleLowerCase();
                    const decodedRole = getUserRole();
                    if (decodedRole !== role) {
                        setUserRole(role);
                        // TODO need to reload page to make this change work
                    }
                }

                return res;
            })
            .catch((err) => {
                if (err.name === 'AbortError') {
                    throw new Error('FetchAborted');
                } else {
                    console.error('Failed to fetch, reason: ', err);
                }
            });
    }

    static fetchWrapperOld(url, options = {}) {
        if (!import.meta.env.REACT_APP_BASE_URL.includes('localhost')) {
            options.credentials = 'include';
        }
        const isLoginUrl = url.includes('/login');
        const token = getCerberusToken();
        if (token) {
            options.headers = {
                ...options.headers,
                ...(!isLoginUrl && { 'x-nr-cerberus-token': token }),
                Origin: 'http://localhost:3000',
            };
        }
        return fetch(url, options).then((res) => {
            if (res.status === 503) {
                const isOnMaintenance = getVarFromLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME);
                if (!isOnMaintenance) {
                    setVarToLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME, true);
                    window.location.assign('/maintenance');
                }
            }
            return res;
        });
    }

    static async fetchWithErrorToast({
        url,
        options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        },
        downloadResp = false,
        errorMessage = 'An error has occurred',
        signal,
    }) {
        try {
            const res = await this.fetchWrapper(url, { ...options, signal });

            if (res.ok) {
                let result;
                if (downloadResp) {
                    result = await res.blob();
                    helper.downloadFileFromResponse(result, res.headers.get('content-disposition'));
                } else {
                    const text = await res.text();
                    result = text ? JSON.parse(text) : { ok: true };
                }

                return result;
            } else if (res.status === 503) {
                const isOnMaintenance = getVarFromLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME);
                if (!isOnMaintenance) {
                    setVarToLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME, true);
                    window.location.assign('/maintenance');
                }
            } else {
                showToastError(errorMessage);
            }
        } catch (error) {
            if (error.message !== 'FetchAborted') {
                showToastError(errorMessage);
                throw error;
            } else {
                console.error('Fetch request was cancelled: ', error);
            }
        }
    }

    static async fetchWithToasts({
        url,
        options = {},
        onSuccessCb,
        onFailCb,
        downloadResp = false,
        failFullResp = false,
        pendingMessage = 'Loading, please wait...',
        successMessage = 'Request completed successfully',
        errorMessage = 'An error occurred during the request',
    }) {
        try {
            return await toast.promise(
                (async () => {
                    const res = await this.fetchWrapper(url, options);

                    if (!res.ok) {
                        if (res.status === 503) {
                            const isOnMaintenance = getVarFromLocalStorage(
                                MAINTENANCE_LOCAL_STORAGE_NAME,
                            );
                            if (!isOnMaintenance) {
                                setVarToLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME, true);
                                window.location.assign('/maintenance');
                            }
                        }
                        const errorResult = await res.json();
                        const errorToReturn = failFullResp
                            ? errorResult
                            : errorResult?.data?.error || '';
                        if (onFailCb) onFailCb(errorToReturn);
                        throw new Error(
                            errorToReturn ||
                                errorResult.error ||
                                errorResult.data.error ||
                                errorMessage,
                        );
                    }

                    let result = { ok: true };
                    if (downloadResp) {
                        result = await res.blob();
                        helper.downloadFileFromResponse(
                            result,
                            res.headers.get('content-disposition'),
                        );
                    } else {
                        const text = await res.text();
                        if (text) result = JSON.parse(text);
                    }

                    if (onSuccessCb) onSuccessCb(result);
                    return result;
                })(),
                {
                    pending: pendingMessage,
                    success: successMessage,
                    error: {
                        render({ data }) {
                            return `${errorMessage}: ${data.message}`;
                        },
                    },
                },
            );
        } catch (error) {
            console.error('Request failed:', error);
            throw error;
        }
    }

    static async fetchWithToastsCustomSuccess({
        url,
        options = {},
        downloadResp = false,
        failFullResp = false,
        pendingMessage = 'Loading, please wait...',
        errorMessage = 'An error occurred during the request',
    }) {
        let toastId = null;

        toastId = toast.loading(pendingMessage);

        try {
            const res = await this.fetchWrapper(url, options);

            if (!res.ok) {
                const errorResult = await res.json();
                const errorToReturn = failFullResp ? errorResult : errorResult?.data?.error || '';
                toast.update(toastId, {
                    render: `${errorMessage}: ${errorToReturn || errorResult.error || errorResult.data.error}`,
                    type: 'error',
                    isLoading: false,
                    autoClose: 5000,
                });
                return Promise.reject(
                    new Error(
                        errorToReturn ||
                            errorResult.error ||
                            errorResult.data.error ||
                            errorMessage,
                    ),
                );
            }

            let result = { ok: true };
            if (downloadResp) {
                result = await res.blob();
                helper.downloadFileFromResponse(result, res.headers.get('content-disposition'));
            } else {
                const text = await res.text();
                if (text) result = JSON.parse(text);
            }

            return { data: result.data, toastId };
        } catch (error) {
            console.log('WHAT', error.message);
            console.error('Request failed:', error);
            toast.update(toastId, {
                render: `${errorMessage}: ${error.message}`,
                type: 'error',
                isLoading: false,
                autoClose: 5000,
            });
            throw error;
        }
    }
}

export default Fetch;
