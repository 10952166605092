import React, { useState } from 'react';
import { Button as MuiButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { ExportModal } from './modals/ExportModal';
import { useSelector } from 'react-redux';
import { getFiltersToSend } from '@pages/AssetsCmoPage/common/common';
import { exportToCmoTemplate, exportToGenericXLS, exportToMatchingTool } from '@api/export';

const ModalTitles = {
    EXPORT_TO_CMO: 'Export to CMO',
    EXPORT_TO_GENERIC_XLS: 'Export to Generic Xls',
    EXPORT_TO_MATCHING_TOOL: 'Export Matching Tool',
};

const useStyles = makeStyles((theme) => ({
    arrowDropDownIcon: {
        width: '18px',
        height: '18px',
        margin: '0 0 0 8px',
    },
    exportBtn: {
        marginLeft: theme.spacing(2),
        color: theme.palette.grey.content,
        backgroundColor: theme.palette.grey.background,

        '&:hover': {
            backgroundColor: theme.palette.grey.hover,
        },
    },
}));

export default function BulkActionsDropDownExportButton({
    cmoId,
    queryParams,
    hasSpecificTemplate,
    getAssetData,
    isPerformer,
}) {
    const [anchorElExport, setAnchorElExport] = useState(null);
    const [open, setOpen] = useState(false);
    const [exportFunction, setExportFunction] = useState(false);
    const [modalTitle, setModalTitle] = useState(ModalTitles.EXPORT_TO_CMO);
    const classes = useStyles();

    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    const handleOpenExport = ({ currentTarget }) => {
        setAnchorElExport(currentTarget);
    };

    const handleCloseExport = () => {
        setAnchorElExport(null);
    };

    const handleExportCMOClick = () => {
        setAnchorElExport(null);
        setOpen(true);
        setExportFunction(() => exportCmoTemplate);
        setModalTitle(ModalTitles.EXPORT_TO_CMO);
    };

    const handleExportGenericXls = () => {
        setAnchorElExport(null);
        setOpen(true);
        setExportFunction(() => exportGenericXls);
        setModalTitle(ModalTitles.EXPORT_TO_GENERIC_XLS);
    };

    const exportData = async (exportFunction, getAssetData, needExport) => {
        const requestParams = {
            ...(needExport !== undefined && { needExport }),
        };

        if (!isPerformer) {
            requestParams.claimMethod = queryParams.claimMethod;
        }

        const bodyParams = {
            ...getFiltersToSend(filtersModel),
            includeAssetIds: queryParams.assetsList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            })),
            excludeAssetIds: queryParams.excludeList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            })),
            downloadAll: queryParams.downloadAll,
            searchString: queryParams.searchTerm,
        };

        setOpen(false);

        await exportFunction(cmoId, requestParams, bodyParams, isPerformer);

        if (needExport) {
            await getAssetData();
        }
    };

    const exportCmoTemplate = (needExport) => {
        return exportData(exportToCmoTemplate, getAssetData, needExport);
    };

    const exportGenericXls = (needExport) => {
        return exportData(exportToGenericXLS, getAssetData, needExport);
    };

    const exportMatchingTool = () => {
        setAnchorElExport(null);
        return exportData(exportToMatchingTool, getAssetData);
    };

    return (
        <div>
            <MuiButton
                variant="outlined"
                onClick={handleOpenExport}
                color="primary"
                className={classes.exportBtn}
                disableElevation
            >
                EXPORT
                <ArrowDropDown className={classes.arrowDropDownIcon} />
            </MuiButton>
            <Menu
                anchorEl={anchorElExport}
                open={Boolean(anchorElExport)}
                onClose={handleCloseExport}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItem
                    key="export-generic-xls"
                    onClick={handleExportGenericXls}
                    disabled={queryParams.assetsList.length === 0}
                >
                    Export generic XLS
                </MenuItem>
                <MenuItem
                    key="export-cmo-template"
                    onClick={handleExportCMOClick}
                    disabled={queryParams.assetsList.length === 0 || !hasSpecificTemplate}
                >
                    Export to CMO template
                </MenuItem>
                <MenuItem
                    key="export-matching-tool"
                    onClick={exportMatchingTool}
                    disabled={queryParams.assetsList.length === 0 || !hasSpecificTemplate}
                >
                    Export to matching tool
                </MenuItem>
            </Menu>
            <ExportModal
                title={modalTitle}
                open={open}
                onClick={exportFunction}
                onCancel={() => setOpen(false)}
            />
        </div>
    );
}
