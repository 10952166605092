import userData from '@data/userData';
import Fetch from '@utils/Fetch';
import utils from '@utils/utils';
import { changeStateByKey, changeStateByNestedKey, forceUpdateByNestedKey } from './fugaActions';

function loadState(cmoClientModel, clientId) {
    if (clientId) {
        let innerCmoClientModel = [];
        return function (dispatch) {
            const clientDetailsUrl = `${userData.api.baseClientUrl()}/${clientId}`;

            Fetch.fetchWrapper(clientDetailsUrl).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    res.json().then((result) => {
                        dispatch(changeStateByNestedKey('cmoClient', 'tableIsLoading', true));
                        const clientDealDTO0Set = result.data.clientDealDTO0Set
                            ? result.data.clientDealDTO0Set[0]
                            : {};
                        let territoriesToUse = [];
                        if (clientDealDTO0Set?.territoryPeriod?.territoryDTO?.territoryCodes) {
                            territoriesToUse =
                                clientDealDTO0Set?.territoryPeriod?.territoryDTO?.territoryCodes;
                        } else if (
                            clientDealDTO0Set?.territoryPeriod?.territoryDTO?.territoryMap &&
                            Object.values(
                                clientDealDTO0Set?.territoryPeriod?.territoryDTO?.territoryMap,
                            ).find((terr) => terr.includes('World'))
                        ) {
                            territoriesToUse = ['World'];
                        }
                        const cmoClient = {
                            ...cmoClientModel,
                            clientData: result.data,
                            clientDealId: clientDealDTO0Set.clientDealId,
                            dealType: clientDealDTO0Set.dealType,
                            claimMethod: clientDealDTO0Set.claimMethod,
                            territories: territoriesToUse,
                            territoryPeriodStart: clientDealDTO0Set?.territoryPeriod?.startDate,
                            territoryPeriodEnd: clientDealDTO0Set?.territoryPeriod?.endDate,
                            rightsHolderId: clientDealDTO0Set?.rightsHolderId,
                        };
                        dispatch(changeStateByKey('cmoClient', cmoClient));

                        if (cmoClient.clientDealId) {
                            const countriesWithSelections = [];
                            const getExistingCmoConnectionsUrl = `${userData.api.baseClientUrl()}/cmoConnection/${
                                cmoClient.clientDealId
                            }`;
                            Fetch.fetchWrapper(getExistingCmoConnectionsUrl)
                                .then((res) => res.json())
                                .then((result) => {
                                    if (result.data) {
                                        const temp = result.data.map((item, index) => ({
                                            key: index,
                                            cmoId: item.cmo.cmo_id,
                                            cmo_name: item.cmo.name,
                                            territory:
                                                item.territoryPeriodSet.territoryDTO
                                                    .territoryCodes[0],
                                            territoryCode:
                                                item.territoryPeriodSet.territoryDTO
                                                    .territoryCodes[0],
                                            startDate: item.territoryPeriodSet.startDate,
                                            endDate: item.territoryPeriodSet.endDate,
                                            deal_method: cmoClient.claimMethod,
                                            rights: item.cmo.explType,
                                            status: item.clientCmoStatus,
                                            isActive: utils.isActiveInterval(
                                                item.territoryPeriodSet.startDate,
                                                item.territoryPeriodSet.endDate,
                                            ),
                                            clientCmoSettingsId: item.clientCmoSettingsId,
                                        }));

                                        temp.sort((el1, el2) =>
                                            el1.territory.localeCompare(el2.territory),
                                        );

                                        const temp2 = temp.map((el, i, arr) => {
                                            return el.territory === arr[i - 1]?.territory
                                                ? { ...el, territory: '' }
                                                : el;
                                        });

                                        innerCmoClientModel = temp2;
                                        result.data.map((item) =>
                                            countriesWithSelections.push(
                                                item.territoryPeriodSet.territoryDTO
                                                    .territoryCodes[0],
                                            ),
                                        );
                                    }

                                    var countriesToAdd = cmoClient.territories.filter(
                                        (item) => !countriesWithSelections.includes(item),
                                    );
                                    const updatedCountries = [];
                                    countriesToAdd.forEach((item, index) => {
                                        updatedCountries.push(
                                            utils.addMissingCountryRow(
                                                {
                                                    dataFromBE: cmoClientModel.dataFromBE,
                                                    claimMethod: cmoClient.claimMethod,
                                                },
                                                item,
                                                innerCmoClientModel.length + index,
                                            ),
                                        );
                                    });
                                    innerCmoClientModel =
                                        innerCmoClientModel.concat(updatedCountries);
                                    dispatch(
                                        changeStateByNestedKey(
                                            'cmoClient',
                                            'dataFromBE',
                                            innerCmoClientModel,
                                        ),
                                    );
                                    dispatch(
                                        changeStateByNestedKey(
                                            'cmoClient',
                                            'tableIsLoading',
                                            false,
                                        ),
                                    );
                                });
                        } else {
                            dispatch(changeStateByNestedKey('cmoClient', 'tableIsLoading', false));
                        }
                        Fetch.fetchWrapper(`${userData.api.baseCmoUrl()}/all`)
                            .then((res) => res.json())
                            .then((dropdownData) => {
                                var allData = dropdownData.data.map((item) => ({
                                    value: item.cmoId,
                                    label: item.cmoName,
                                    type: item.cmoType,
                                    rights: item.exploitationTypes,
                                }));
                                var filtered = allData.filter(
                                    (item) => item.type === cmoClient.dealType,
                                );
                                dispatch(
                                    changeStateByNestedKey('cmoClient', 'dropdownItems', filtered),
                                );
                            });
                    });
                } else {
                    res.json().then((result) => {
                        dispatch(
                            changeStateByKey('infoMessage', {
                                isVisible: true,
                                message: result.error || result?.data?.error,
                                severity: 'error',
                            }),
                        );
                    });
                }
            });
        };
    }
}

function sendSelectionToSave(
    connection,
    cmoClientModel,
    id,
    performerConf = { isPerfomrmer: false, update: false, storeName: 'performers' },
) {
    // THIS method is deprecated replaced with createOrUpdateCmoRegistration in apiActions NR-985
    return function (dispatch) {
        const url = performerConf?.update
            ? `${userData.api.baseCmoUrl()}/update-cmo-territory-connection`
            : `${userData.api.baseClientUrl()}/create-cmo-territory-connections`;
        const requestMethod = performerConf?.update ? 'PATCH' : 'POST';
        const options = {
            method: requestMethod,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(connection),
        };
        Fetch.fetchWrapper(url, options).then((res) => {
            if (res.status === 200 || res.status === 201) {
                res.json().then((result) => {
                    if (!performerConf.isPerfomrmer) dispatch(loadState(cmoClientModel, id));
                    dispatch(
                        changeStateByKey('infoMessage', {
                            isVisible: true,
                            message: result.message || 'Connection created successfully',
                            severity: 'success',
                        }),
                    );
                    dispatch(
                        changeStateByNestedKey(performerConf.storeName, 'modalValidations', {
                            msgTerritory: '',
                            msgDate: '',
                        }),
                    );
                    if (performerConf.handleCloseCb) dispatch(performerConf.handleCloseCb);
                });
            } else {
                res.json()
                    .then((result) => {
                        if (!performerConf.isPerfomrmer) {
                            dispatch(changeStateByNestedKey('cmoClient', 'errorRows', connection));
                            dispatch(forceUpdateByNestedKey('cmoClient', 'dataFromBE'));
                        } else {
                            const errors = {
                                territories: {
                                    msg: 'is outside of supported territories for this Client Deal',
                                    msgToShow: 'Your Territories are outside of the Client Deal',
                                },
                                dates: {
                                    msg: 'Territory Period is outside Client Deal term',
                                    msgToShow: 'Dates outside of client deal',
                                },
                                clush: {
                                    msg: 'Input territory period is covered by exploitation types from other CMO',
                                    msgToShow: 'This Date and Territory combination already exists',
                                },
                                cmoIds: {
                                    msg: 'has been already connected to client deal id',
                                    msgToShow:
                                        'This CMO, Date and Territory combination already exists for the Claim Party',
                                },
                            };
                            if (!result.data.error) {
                                let matched = false;
                                for (const [type, messages] of Object.entries(errors)) {
                                    if (
                                        result.data[0].message &&
                                        result.data[0].message.includes(messages.msg)
                                    ) {
                                        if (type === 'clush' || type === 'cmoIds') {
                                            dispatch(
                                                changeStateByKey('infoMessage', {
                                                    isVisible: true,
                                                    message: messages.msgToShow,
                                                    severity: 'error',
                                                }),
                                            );
                                            matched = true;
                                            break;
                                        } else if (type === 'dates') {
                                            dispatch(
                                                changeStateByNestedKey(
                                                    performerConf.storeName,
                                                    'modalValidations',
                                                    {
                                                        msgTerritory: '',
                                                        msgDate: messages.msgToShow,
                                                    },
                                                ),
                                            );
                                            matched = true;
                                            break;
                                        } else {
                                            dispatch(
                                                changeStateByNestedKey(
                                                    performerConf.storeName,
                                                    'modalValidations',
                                                    { msgTerritory: messages.msgToShow },
                                                ),
                                            );
                                            matched = true;
                                            break;
                                        }
                                    }
                                }
                                if (!matched) {
                                    dispatch(
                                        changeStateByNestedKey(
                                            performerConf.storeName,
                                            'modalValidations',
                                            {
                                                msgTerritory: '',
                                            },
                                        ),
                                    );
                                    dispatch(
                                        changeStateByKey('infoMessage', {
                                            isVisible: true,
                                            message: result.data.map((data) => data.message).join(),
                                            severity: 'error',
                                        }),
                                    );
                                }
                            } else {
                                dispatch(
                                    changeStateByKey('infoMessage', {
                                        isVisible: true,
                                        message: result.data.error,
                                        severity: 'error',
                                    }),
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(
                            'Failed to send request via url: ' + url,
                            `error msg: ${error}`,
                        );
                    });
            }
            dispatch(changeStateByNestedKey('cmoClient', 'dataToSend', []));
        });
    };
}

function registerCmoRelationship(clientIds, cmoClientModel, id = '') {
    return function (dispatch) {
        const registerUrl = `${userData.api.baseCmoUrl()}/register-connections?clientCmoConnectionIds=${clientIds.join(
            ',',
        )}`;
        Fetch.fetchWrapper(registerUrl).then((res) => {
            if (res.status === 200 || res.status === 201) {
                res.json().then((result) => {
                    dispatch(
                        changeStateByKey('infoMessage', {
                            isVisible: true,
                            message:
                                result.message || 'Selected records was successfully registered',
                            severity: 'success',
                        }),
                    );
                    dispatch(changeStateByNestedKey('clientRegistration', 'isModalVisible', false));
                    dispatch(loadState(cmoClientModel, id));
                });
            } else {
                res.json().then((result) => {
                    const errorMsgs = result.data
                        .map((el) => `Failed to register cmo with id: ${el.id} --- '${el.message}'`)
                        .join('\n');
                    dispatch(
                        changeStateByKey('infoMessage', {
                            isVisible: true,
                            message: errorMsgs,
                            severity: 'error',
                        }),
                    );
                });
            }
            dispatch(changeStateByNestedKey('cmoClient', 'dataToSend', []));
        });
    };
}

function addRow(record, cmoClientModel) {
    return function (dispatch) {
        const index = cmoClientModel.dataFromBE.findIndex((el) => el.key === record.key);

        var dataToPush = {
            key: cmoClientModel.dataFromBE.length,
            territoryCode: record.territoryCode,
            deal_method: cmoClientModel.claimMethod,
            rights: ['All rights'],
            isActive: false,
        };

        const tmp = [...cmoClientModel.dataFromBE];
        tmp.splice(index + 1, 0, dataToPush);
        dispatch(changeStateByNestedKey('cmoClient', 'dataFromBE', tmp));
    };
}

export { loadState, sendSelectionToSave, registerCmoRelationship, addRow };
