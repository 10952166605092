import React from 'react';
import {
    CircularProgress,
    Dialog,
    Box
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';

function LoadingScreen() {
    const loadScreenModel = useSelector(state => state.fugaReducers.loadScreen)

    return (
        <Dialog fullScreen
            open={loadScreenModel.isLoading}
            style={{
                backgroundColor: 'gray', opacity: '0.5',
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ width: '100%', height: '100%' }}
            >
                <CircularProgress size={70} />
            </Box>
        </Dialog>
    );
}

export default connect(r => r)(LoadingScreen)
