import React, { useState } from 'react';
import { Modal, Radio, Space, Table } from 'antd';
import { connect, useSelector, useDispatch } from 'react-redux';
import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { registerCmoRelationship } from '@actions/clientCmoSelection';

function ClientRegistration(props) {
    const dispatch = useDispatch();
    const cmoClientModel = useSelector((state) => state.fugaReducers.cmoClient);
    const clientRegistrationModel = useSelector((state) => state.fugaReducers.clientRegistration);
    const [countries, setCountries] = useState(['']);
    const [checkedCmo, setCheckedCmo] = useState('');

    const showModal = () => {
        dispatch(changeStateByNestedKey('clientRegistration', 'isModalVisible', true));
    };

    const handleOk = () => {
        dispatch(changeStateByNestedKey('clientRegistration', 'isModalVisible', false));
    };

    const handleCancel = () => {
        dispatch(changeStateByNestedKey('clientRegistration', 'isModalVisible', false));
    };
    const setChekedValue = (cmo) => {
        const territories = cmoClientModel.dataFromBE
            .filter((el) => el.cmo_name === cmo)
            .map((el) => el.territoryCode);
        setCheckedCmo(cmo);
        setCountries(territories);
    };

    const registerRelationship = () => {
        if (checkedCmo) {
            const cmosToSend = cmoClientModel.dataFromBE.filter((el) => el.cmo_name === checkedCmo);
            dispatch(
                registerCmoRelationship(
                    cmosToSend.map((el) => el.clientCmoSettingsId),
                    cmoClientModel,
                    props.match.params.id,
                ),
            );
        } else {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please select a CMO',
                    severity: 'error',
                }),
            );
        }
    };

    const data = [
        {
            key: '1',
            action: 'Register',
            cmos: Array.from(
                new Set(
                    cmoClientModel.dataFromBE.filter((el) => el.cmo_name).map((el) => el.cmo_name),
                ),
            ),
            territories: countries.join(', '),
        },
    ];
    const columns = [
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <p>{text}</p>,
        },
        {
            title: 'CMOs available for this Client',
            key: 'select-cmo',
            dataIndex: 'cmos',
            render: (cmos) => {
                return (
                    <Radio.Group>
                        <Space direction="vertical">
                            {cmos.map((cmo, ind) => {
                                return (
                                    <Radio
                                        value={cmo}
                                        key={`${cmo}${ind}`}
                                        onChange={(e) => setChekedValue(e.target.value)}
                                    >
                                        {cmo.toUpperCase()}
                                    </Radio>
                                );
                            })}
                        </Space>
                    </Radio.Group>
                );
            },
        },
        {
            title: 'Territories',
            dataIndex: 'territories',
            key: 'territories',
            render: (text, record) => {
                return <p>{text}</p>;
            },
        },
    ];

    return (
        <>
            <Button variant="contained" color="primary" onClick={showModal}>
                CMO actions
            </Button>
            <Modal
                title="Update Client CMO Relationship"
                visible={clientRegistrationModel.isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button variant="outlined" onClick={() => registerRelationship()}>
                        {'Save >>>'}
                    </Button>,
                ]}
            >
                <Table columns={columns} dataSource={data} pagination={false} />
            </Modal>
        </>
    );
}

const withUrlDataClientCmoSelection = withRouter(ClientRegistration);
export default connect((r) => r)(withUrlDataClientCmoSelection);
