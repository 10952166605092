import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseCommentUrl();

function getCommentsByCmoRegIdAPI(cmoRegId) {
    const url = `${CONTROLLER_URL}/${cmoRegId}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get comments',
    });
}

function createCommentByCmoRegIdAPI(content) {
    const url = `${CONTROLLER_URL}/add-edit-comment`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Comment created successfully',
        errorMessage: 'Failed to create comment',
        pendingMessage: 'Creating comment...',
    });
}

export { getCommentsByCmoRegIdAPI, createCommentByCmoRegIdAPI };
