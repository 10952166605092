import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import DowntownMarketingLogo from '@images/svgComponents/DowntownMarketingLogo';
import FoundEeMarketingLogo from '@images/svgComponents/FoundEeMarketingLogo';
import FugaMarketingLogo from '@images/svgComponents/FugaMarketingLogo';
import SongtrustMarketingLogo from '@images/svgComponents/SongtrustMarketingLogo';
import curveLogo from '@images/branding/curve_logo_mark.png';

const useStyles = makeStyles(() => {
    return {
        background: {
            backgroundImage: (props) => `url(${props.image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            height: 'fit-content',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            padding: '32px 64px',
        },
        marketingModalContainer: {
            background: '#00000080',
            padding: '16px',
            borderRadius: '10px',
            height: 'max-content',
            backdropFilter: 'blur(10px)',
        },
        whiteText: {
            color: '#FFFFFF',
        },
        whiteTextOpacity: {
            color: '#FFFFFF8A',
        },
        captionsLineHeight: {
            lineHeight: '13px',
        },
        innerContainer: {
            background: 'rgba(0, 0, 0, 0.5)',
            marginTop: '12px',
            borderRadius: '10px',
            padding: '8px',
        },
        subLabelsContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        linksContainer: {
            padding: '14px 0 0 0',
            width: '100%',
        },
        navLink: {
            marginBottom: '4px',
        },
        iconLinkRow: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '6px',
            borderRadius: '10px',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                cursor: 'pointer',
            },
        },
        rowWithBottomMargin: {
            marginBottom: '8px',
        },
        lastRowWithBottomMargin: {
            marginBottom: '8px',
        },
        speciicLogo: {
            paddingLeft: '6px',
            paddingRight: '8px',
        },
        defaultLogo: {
            paddingLeft: '6px',
            paddingRight: '16px',
        },
        curveImage: {
            height: '31px',
        },
    };
});

export default function MarketingModule({ image }) {
    const classes = useStyles({ image });

    const pagesList = [
        {
            sectionTitle: 'Distribution & Marketing Services',
            linksList: [
                {
                    title: 'FUGA',
                    description:
                        'Empowering independent music companies with forward-thinking distribution, technology, and global marketing',
                    link: 'http://fuga.com/',
                    logo: <FugaMarketingLogo styles={classes.defaultLogo} />,
                    generalStyle: classes.rowWithBottomMargin,
                },
                {
                    title: 'found.ee',
                    description:
                        'All the tools you need to create ads, engage audiences and find new fans',
                    link: 'https://found.ee/super',
                    logo: <FoundEeMarketingLogo styles={classes.speciicLogo} />,
                    generalStyle: classes.rowWithBottomMargin,
                },
                {
                    title: 'Licensease',
                    description:
                        "The world's most advanced sync license verification, claims maintenance, asset storage, and performance tracking system for YouTube and other social media platforms",
                    link: 'https://www.licensease.com/',
                    logo: <FugaMarketingLogo styles={classes.defaultLogo} />,
                    generalStyle: classes.lastRowWithBottomMargin,
                },
            ],
        },
        {
            sectionTitle: 'Publishing',
            linksList: [
                {
                    title: 'Downtown Music Publishing',
                    description:
                        'A premium global publishing company that collects directly from more sources than anyone else',
                    link: 'http://downtownmusicpublishing.com/',
                    logo: <DowntownMarketingLogo styles={classes.speciicLogo} />,
                    generalStyle: classes.rowWithBottomMargin,
                },
                {
                    title: 'Songtrust',
                    description: 'Building a better music publishing business for creators',
                    link: 'https://www.songtrust.com/about-songtrust',
                    logo: <SongtrustMarketingLogo styles={classes.defaultLogo} />,
                    generalStyle: classes.lastRowWithBottomMargin,
                },
            ],
        },
        {
            sectionTitle: 'Financial Services',
            linksList: [
                {
                    title: 'Curve Royalty Systems',
                    description: "A royalty system purpose-built for today's music industry",
                    link: 'https://www.curveroyaltysystems.com/',
                    logo: (
                        <img
                            src={curveLogo}
                            alt="curve_logo"
                            className={`${classes.curveImage} ${classes.defaultLogo}`}
                        />
                    ),
                    generalStyle: classes.rowWithBottomMargin,
                },
                {
                    title: 'FUGA Royalty Accounting',
                    description: 'A robust royalty accounting tool for financial administrators',
                    link: 'https://fuga.com/products-services/royalty-accounting-software/',
                    logo: <FugaMarketingLogo styles={classes.defaultLogo} />,
                    generalStyle: classes.lastRowWithBottomMargin,
                },
            ],
        },
    ];

    const handleTitleClick = (link) => {
        window.open(link, '_blank');
    };

    return (
        <div className={classes.background}>
            <div className={classes.marketingModalContainer}>
                <Typography variant="h6" className={classes.whiteText}>
                    Other products and services
                </Typography>
                {pagesList.map((pageItem) => (
                    <div key={pageItem.sectionTitle} className={classes.innerContainer}>
                        <Typography variant="subtitle1" className={classes.whiteTextOpacity}>
                            {pageItem.sectionTitle}
                        </Typography>
                        <div className={classes.subLabelsContainer}>
                            <div className={classes.linksContainer}>
                                {pageItem.linksList.map((item) => (
                                    <div
                                        key={item.title}
                                        className={`${classes.iconLinkRow} ${item.generalStyle}`}
                                    >
                                        <div>{item.logo}</div>
                                        <div>
                                            <Typography
                                                variant="subtitle2"
                                                className={`${classes.whiteText} ${classes.navLink}`}
                                                onClick={() => handleTitleClick(item.link)}
                                            >
                                                {item.title}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                className={`${classes.whiteTextOpacity} ${classes.captionsLineHeight}`}
                                            >
                                                {item.description}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
