import React, { useState, useEffect } from 'react';
import {
    Button,
    DialogTitle,
    makeStyles,
    Popover,
    ClickAwayListener,
    Box,
    Checkbox,
} from '@material-ui/core';
import helper from '@utils/helper';

const useStyles = makeStyles(() => ({
    titile: {
        padding: '19px 16px',
    },
    actoinBtns: {
        marginTop: '13px',
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginLeft: '24px',
        },
        background: '#FFF',
        width: '94%',
        marginBottom: '8px',
    },
    input: {
        width: '100%',
    },
    box: {
        padding: '8px 0 8px 5px',
        width: '335px',
        minHeight: '147px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
    },
    textStyle: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.25px',
    },
    checkboxesContainer: {
        maxHeight: '550px',
    },
    itemsListContainer: {
        overflow: 'auto',
    },
    checkboxRow: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export function AssetsCmoFilterMenuDialogWithCheckboxes({
    isOpen,
    closeCb,
    title,
    dataItem,
    onConfirm,
    anchorEl,
    itemsList = [],
    itemFieldName = 'id',
    filtersModel,
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(isOpen);
    const [selectedValues, setSelectedValues] = useState({ valuesToSend: [], valuesToShow: [] }); // array of ids from itemsList
    const filtersFromModel = filtersModel[dataItem.filterField] || [];

    const getValuesToShow = (listOfSelected) => {
        return listOfSelected.map((selectedValue) => {
            const foundItem =
                itemsList.find((menuItem) => menuItem[itemFieldName] === selectedValue) || {};
            return foundItem.title;
        });
    };

    useEffect(() => {
        if (filtersFromModel?.valuesToSend?.length > 0) {
            const itemsListToShow = getValuesToShow(filtersFromModel.valuesToSend);
            setSelectedValues({
                valuesToSend: [...filtersFromModel.valuesToSend],
                valuesToShow: [...itemsListToShow],
            });
        } else {
            setSelectedValues({ valuesToSend: [], valuesToShow: [] });
        }
    }, [isOpen]);

    useEffect(() => {
        if (filtersModel[dataItem.filterField] === '') {
            setSelectedValues({ valuesToSend: [], valuesToShow: [] });
        }
    }, [filtersModel[dataItem.filterField]]);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
    };

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm(selectedValues);
        }
        handleClose();
    };

    const handleCheckboxClick = (clickedItem) => {
        const selectedId = selectedValues.valuesToSend.findIndex(
            (item) => item === clickedItem[itemFieldName],
        );
        const updatedSelectedList = helper.updateList(
            selectedValues.valuesToSend,
            clickedItem[itemFieldName],
            selectedId,
        );
        const updatedItemsToShow = getValuesToShow(updatedSelectedList);
        setSelectedValues({
            valuesToSend: updatedSelectedList,
            valuesToShow: [...updatedItemsToShow],
        });
    };

    const isCheckboxSelected = (currentSelecedValue) => {
        return selectedValues.valuesToSend.includes(currentSelecedValue);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            className={classes.checkboxesContainer}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Box className={classes.box}>
                    {title && (
                        <DialogTitle onClose={handleClose} className={`${classes.titile}`}>
                            {title}
                        </DialogTitle>
                    )}
                    <div className={classes.itemsListContainer}>
                        {itemsList.map((item) => {
                            return (
                                <div key={item.id} className={classes.checkboxRow}>
                                    <Checkbox
                                        color="primary"
                                        disableRipple
                                        onClick={() => handleCheckboxClick(item)}
                                        checked={isCheckboxSelected(item[itemFieldName])}
                                    />{' '}
                                    {item.title}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.actoinBtns}>
                        <Button
                            onClick={handleClose}
                            variant="text"
                            color="primary"
                            disableElevation
                        >
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleConfirm}
                            disabled={selectedValues.valuesToSend.length <= 0}
                            style={{ marginRight: '8px' }}
                        >
                            apply
                        </Button>
                    </div>
                </Box>
            </ClickAwayListener>
        </Popover>
    );
}
