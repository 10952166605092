import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { withRouter } from 'react-router-dom';
import { FormControl, Grid } from '@material-ui/core';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';

const PerformerCommentsContent = (props) => {
    const dispatch = useDispatch();
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const { byClietnId } = performersModel;
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                comments: byClietnId.comments,
            }),
        );
    }, [performersModel.byClietnId]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <>
            <div className="topContainer flex-left">
                <Grid container spacing={4}>
                    <Grid item sx={6} md={6}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '28' }}
                                multiline={true}
                                label="comments"
                                onChange={(evt) => handleInputChange(evt)}
                                name="comments"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.comments
                                        ? performersModel.mainDetailsInptValues.comments
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const withUrlPerformerCommentsContent = withRouter(PerformerCommentsContent);
export default connect((r) => r)(withUrlPerformerCommentsContent);
