import React, { useState, useEffect, memo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Close as CancelIcon } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { TextField, makeStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    iconBtn: {
        padding: '0',
        margin: '0',
        '&:hover': {
            background: 'none',
        },
    },
    iconPosiotion: {
        margin: '0',
    },
    searchIcon: {
        color: '#5F5F60',
        width: '21px',
        height: '21px',
    },
    cancelIcon: {
        color: '#5F5F60',
        width: '17.48px',
        height: '17.48px',
    },
    foromControl: {
        margin: '0',
    },
    inputField: {
        marginRight: '10px',

        '& input': {
            padding: '9px 8px',
        },

        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.grey.border,
            },
        },
    },
    inputAdornmentIconContainer: {
        margin: 0,
    },
}));

let CommonSearchInput = function ({ onSearch, term, placeHolderText, onClearCb }) {
    const [value, setValue] = useState('');
    const [initialSearchTerm, setInitialSearchTerm] = useState('');
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const classes = useStyles();

    const handleTooltipOpen = () => {
        if (term) {
            setIsTooltipOpen(true);
        }
    };

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    const onClearSearch = () => {
        if (!value) return;
        setValue('');
        if (onClearCb) onClearCb();
    };

    const showClearIcon =
        (value && value.length > 0) || (initialSearchTerm && initialSearchTerm.length > 0);

    useEffect(() => {
        const _term = term ? term : '';
        setInitialSearchTerm(_term);
        setValue(_term);
    }, [term]);

    const onClientSearch = () => {
        onSearch(value);
    };

    const handleChange = (e) => setValue(e.target.value);

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            onClientSearch(value);
        }
    };

    return (
        <FormControl key="form-search" className={classes.foromControl}>
            <Tooltip
                title={value}
                open={isTooltipOpen}
                onOpen={handleTooltipOpen}
                onClose={handleTooltipClose}
            >
                <TextField
                    value={value}
                    variant="outlined"
                    placeholder={placeHolderText}
                    disabled={!!term}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    className={classes.inputField}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {showClearIcon && (
                                    <IconButton
                                        onClick={onClearSearch}
                                        className={`${classes.iconBtn}`}
                                    >
                                        <CancelIcon
                                            className={`${classes.iconPosiotion} ${classes.cancelIcon}`}
                                        />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment
                                className={classes.inputAdornmentIconContainer}
                                position="start"
                            >
                                <IconButton className={classes.iconBtn} disableRipple>
                                    <SearchIcon
                                        className={classNames(
                                            classes.iconPosiotion,
                                            classes.searchIcon
                                        )}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Tooltip>
        </FormControl>
    );
};

CommonSearchInput = memo(CommonSearchInput);

export default CommonSearchInput;
