import React from 'react';

export default function SongtrustMarketingLogo(props) {
    return (
        <div className={props.styles}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
            >
                <path
                    d="M15.6016 14.5286C14.7857 14.5288 13.9927 14.2595 13.3456 13.7625C12.6986 13.2655 12.2339 12.5686 12.0238 11.7803C11.8136 10.992 11.8698 10.1563 12.1836 9.40317C12.4973 8.65005 13.0511 8.02168 13.7588 7.61575C14.4665 7.20981 15.2885 7.04905 16.097 7.15845C16.9055 7.26785 17.6552 7.64128 18.2296 8.2207C18.804 8.80012 19.1709 9.55306 19.2732 10.3625C19.3755 11.1719 19.2076 11.9925 18.7955 12.6966H26.908V4.64746H4.70312V16.9569H16.0096C16.8254 16.9567 17.6185 17.226 18.2655 17.723C18.9125 18.22 19.3772 18.9168 19.5873 19.7051C19.7975 20.4935 19.7413 21.3291 19.4275 22.0822C19.1138 22.8353 18.56 23.4637 17.8523 23.8696C17.1446 24.2755 16.3226 24.4363 15.5141 24.3269C14.7057 24.2175 13.956 23.8441 13.3816 23.2647C12.8072 22.6853 12.4403 21.9323 12.338 21.1229C12.2357 20.3135 12.4036 19.493 12.8157 18.7889H4.70312V26.8524H26.908V14.5286H15.6016Z"
                    fill="white"
                />
            </svg>
        </div>
    );
}
