import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Button,
    Dialog,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Typography,
    RadioGroup,
    Radio,
    Divider,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { changeStateByKey } from '@actions/fugaActions';
import { Alert } from '@material-ui/lab';
import { createClientDeal, editClientDealPerfOrRH } from '@actions/territories';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
    },
    collapsed: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    listItem: {
        borderBottom: '1px solid #DEDEE0',
    },
    checkboxes: {
        padding: '0 8px 0 0',
    },
    alertText: {
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
}));

const RegionItem = ({
    territorySelection,
    territories,
    setTerritories,
    region,
    classes,
    isDisabled,
}) => {
    const [collapsed, setCollapsed] = useState(true);

    const countSelected = () => {
        if (territories.length === 0 && Object.keys(territories).some((key) => key.includes('0')))
            return 0;
        return territorySelection.filter((territory) => {
            return (territories[region.id] || []).includes(territory.id);
        }).length;
    };

    const handleUpdateTerritories = (territoryCode) => {
        if ((territories[region.id] || []).includes(territoryCode)) {
            setTerritories({
                ...territories,
                [region.id]: (territories[region.id] || []).filter(
                    (territory) => territory !== territoryCode,
                ),
            });
        } else {
            setTerritories({
                ...territories,
                [region.id]: (territories[region.id] || []).concat(territoryCode),
            });
        }
    };

    const selectAllTerritories = (event) => {
        if (event.target.checked) {
            let allForRegion = territorySelection.map((territory) => territory.id);

            setTerritories({ ...territories, [region.id]: allForRegion });
        } else {
            setTerritories({ ...territories, [region.id]: [] });
        }
    };

    return (
        <List key={region.id} component="div" disablePadding>
            <ListItem disableGutters className={classes.listItem}>
                <Checkbox
                    className={classes.checkboxes}
                    color="primary"
                    checked={territorySelection.every((territory) =>
                        (territories[region.id] || []).includes(territory.id),
                    )}
                    indeterminate={
                        territorySelection.some((territory) =>
                            (territories[region.id] || []).includes(territory.id),
                        ) && (territories[region.id] || []).length !== territorySelection.length
                    }
                    onClick={selectAllTerritories}
                    disabled={isDisabled}
                />
                <ListItemText
                    primary={
                        <Typography variant="subtitle2">
                            {`${region.name} (${
                                territorySelection.length
                            } items, ${countSelected()} selected)`}
                        </Typography>
                    }
                />

                <IconButton
                    onClick={() => setCollapsed(!collapsed)}
                    className={collapsed ? classes.expanded : classes.collapsed}
                    aria-expanded={!collapsed}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </ListItem>

            <Grid container>
                {!collapsed &&
                    territorySelection.map((territory) => {
                        return (
                            <Grid key={territory.id} item xs={4} lg={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(territories[region.id] || []).includes(
                                                territory.id,
                                            )}
                                            onChange={() => handleUpdateTerritories(territory.id)}
                                            name={territory.name}
                                            color="primary"
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={territory.name}
                                />
                            </Grid>
                        );
                    })}
            </Grid>
        </List>
    );
};

const DatesAndClaimMethods = ({
    isEditing,
    claimMethod,
    setClaimMethod,
    selectedStartDate,
    handleStartDateChange,
    selectedEndDate,
    handleEndDateChange,
    setShowRenewalUpdate,
}) => {
    useEffect(() => {
        if (!isEditing) {
            handleStartDateChange(moment());
            handleEndDateChange(moment());
        }
    }, [isEditing]);

    const handleRadioChange = (event) => {
        setClaimMethod(event.target.value);
    };

    const handleEndDateFocus = () => {
        if (isEditing) setShowRenewalUpdate(true);
    };
    return (
        <>
            <Typography variant="subtitle1" style={{ marginBottom: '21px' }}>
                Dates & claim methods
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '8.5px' }}>
                Claim methods
            </Typography>
            <RadioGroup value={claimMethod} onChange={handleRadioChange}>
                <FormControlLabel
                    value="agent"
                    control={<Radio size="small" color="primary" disabled={isEditing} />}
                    label={<Typography variant="body2">Agent</Typography>}
                />
                <FormControlLabel
                    value="exclusive_license"
                    control={<Radio size="small" color="primary" disabled={isEditing} />}
                    label={<Typography variant="body2">Exclusive license</Typography>}
                />
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <Grid container spacing={4} style={{ marginTop: '.5px' }}>
                        <Grid item>
                            <KeyboardDatePicker
                                label="Start Date"
                                value={selectedStartDate}
                                onChange={(date) => handleStartDateChange(date)}
                                format="DD/MM/yyyy"
                                disabled={isEditing}
                            />
                        </Grid>
                        <Grid item>
                            <KeyboardDatePicker
                                label="End Date"
                                value={selectedEndDate}
                                onChange={(date) => handleEndDateChange(date)}
                                onFocus={handleEndDateFocus}
                                format="DD/MM/yyyy"
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </RadioGroup>
        </>
    );
};

const TerritoriesModal = ({
    clientId,
    showTerritoryModal,
    setShowTerritoryModal,
    territories,
    setTerritories,
    groupedTerritories,
    isEditing,
    territoriesPropModel,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [claimMethod, setClaimMethod] = useState('exclusive_license');
    const [selectedStartDate, handleStartDateChange] = useState(moment());
    const [selectedEndDate, handleEndDateChange] = useState(moment());
    const [showRenewalUpdate, setShowRenewalUpdate] = useState(false);

    useEffect(() => {
        if (Object.keys(territoriesPropModel.editableDealInfo).length > 0) {
            handleStartDateChange(moment(territoriesPropModel.editableDealInfo.startDate));
            handleEndDateChange(moment(territoriesPropModel.editableDealInfo.endDate));
            setClaimMethod(
                territoriesPropModel.editableDealInfo.claimMethod.toLowerCase() === 'agent'
                    ? 'agent'
                    : 'exclusive_license',
            );
        }
    }, [
        territoriesPropModel.editableDealInfo.startDate,
        territoriesPropModel.editableDealInfo.endDate,
    ]);

    const handleClose = () => {
        setShowTerritoryModal(false);
        setShowRenewalUpdate(false);
    };

    const handleSave = () => {
        if (selectedStartDate && selectedEndDate && selectedStartDate > selectedEndDate) {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'End date cannot be before start date',
                    severity: 'error',
                }),
            );
        } else if (!(selectedStartDate && selectedEndDate)) {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please Fill in all the fields',
                    severity: 'error',
                }),
            );
        } else {
            let allTerritoriesCount = 0;
            groupedTerritories.forEach((el) => (allTerritoriesCount += el.territories.length));
            const selectedTerritories = Object.values(territories)
                .map((territoriesByRegion) => territoriesByRegion)
                .flat()
                .join();
            const dealTerritoriesContent =
                selectedTerritories.split(',').length === allTerritoriesCount
                    ? 'WW'
                    : selectedTerritories;
            const objToSave = {
                ...(!isEditing && { claimMethod: claimMethod.toUpperCase() }),
                ...(!isEditing
                    ? { clientId: parseInt(clientId) }
                    : { clientDealId: parseInt(territoriesPropModel.editableDealInfo.id) }),
                clientId: parseInt(clientId),
                ...(!isEditing
                    ? { dealTerritories: dealTerritoriesContent }
                    : { territories: dealTerritoriesContent }),
                ...(!isEditing && { startDate: selectedStartDate.format('YYYY-MM-DD') }),
                endDate: selectedEndDate.format('YYYY-MM-DD'),
            };
            handleClose();
            if (isEditing) {
                dispatch(editClientDealPerfOrRH(objToSave, false));
            } else {
                dispatch(createClientDeal(objToSave));
            }
        }
    };

    const setAllTerritories = () => {
        let newTerritories = {};
        groupedTerritories.map(
            (territoryGroup) =>
                (newTerritories = {
                    ...newTerritories,
                    [territoryGroup.region.id]: territoryGroup.territories.map((t) => t.id),
                }),
        );
        setTerritories(newTerritories);
    };

    const toggleWorld = () => {
        if (allSelected()) {
            setTerritories({});
        } else {
            setAllTerritories();
        }
    };

    const noneSelected = () => Object.entries(territories).every((item) => item[1].length === 0);

    const allSelected = () => {
        return groupedTerritories.every((gt) => {
            const matchedTerritory = Object.entries(territories).find((region) => {
                return gt.region.id === region[0];
            });
            return matchedTerritory && matchedTerritory[1].length === gt.territories.length;
        });
    };

    const anySelected = () => {
        return (
            Object.values(territories).length > 0 &&
            Object.values(territories).some((territory) => territory.length > 0)
        );
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={showTerritoryModal} fullWidth maxWidth="md">
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {`${isEditing ? 'Edit' : 'Add'} Deal terms`}
                </DialogTitle>
                <DialogContent dividers style={{ maxHeight: '50%' }}>
                    {isEditing && territoriesPropModel.editableDealInfo.locked && (
                        <Alert
                            variant="outlined"
                            severity="warning"
                            color="warning"
                            style={{ marginBottom: '16px' }}
                        >
                            {
                                'Only End Date can be edited as one or more registration actions have already taken place under this deal.'
                            }
                        </Alert>
                    )}
                    {showRenewalUpdate && (
                        <Alert
                            className={classes.alertText}
                            variant="outlined"
                            severity="warning"
                            color="warning"
                            style={{ marginBottom: '32px' }}
                        >
                            {
                                "Attention - changes to deal 'End Date'. Extensions: any CMO Registrations with the current end date will also be extended. Reductions: any CMO Registrations currently beyond the new date will be reduced to match."
                            }
                        </Alert>
                    )}
                    <DatesAndClaimMethods
                        claimMethod={claimMethod}
                        setClaimMethod={setClaimMethod}
                        selectedStartDate={selectedStartDate}
                        handleStartDateChange={handleStartDateChange}
                        selectedEndDate={selectedEndDate}
                        handleEndDateChange={handleEndDateChange}
                        isEditing={isEditing}
                        setShowRenewalUpdate={setShowRenewalUpdate}
                    />
                    <Divider variant="inset" style={{ margin: '41px 0 29px 0' }} />
                    <Typography variant="subtitle1" style={{ marginBottom: '27.17px' }}>
                        Territories
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allSelected()}
                                onChange={() => toggleWorld()}
                                name="World"
                                color="primary"
                                indeterminate={allSelected() ? false : anySelected()}
                                disabled={isEditing && territoriesPropModel.editableDealInfo.locked}
                            />
                        }
                        label="World"
                    />
                    {groupedTerritories.map((territoryGroup) => (
                        <RegionItem
                            key={territoryGroup.region.id}
                            territorySelection={territoryGroup.territories}
                            territories={territories}
                            setTerritories={setTerritories}
                            region={territoryGroup.region}
                            classes={classes}
                            isDisabled={isEditing && territoriesPropModel.editableDealInfo.locked}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={noneSelected()}
                        variant="contained"
                        onClick={handleSave}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect((store) => ({
    territoriesPropModel: store.fugaReducers.territories,
}))(TerritoriesModal);
