export const bulkUpdateAlerts = (data) => {
    const skippedAssetNumber = data?.skippedAssetNumber;
    const statusChanges = data?.statusChanges;

    const statusChangesMessage = Object.entries(statusChanges || {}).map(([key, value]) => {
        const [fromStatus, toStatus] = key.slice(1, -1).split(',');
        return (
            <div key={key}>
                - <strong>{value}</strong> tracks changed from <strong>{fromStatus}</strong> to{' '}
                <strong>{toStatus}</strong>;
            </div>
        );
    });

    return (
        <div style={{ textAlign: 'start' }}>
            <span style={{ display: 'block' }}>Bulk update completed.</span>
            <span style={{ display: 'block' }}>{statusChangesMessage}</span>
            <span style={{ display: 'block' }}>
                - <strong>{skippedAssetNumber}</strong> tracks were skipped.
            </span>
        </div>
    );
};
