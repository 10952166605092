import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EditOutlined, DeleteOutline } from '@material-ui/icons';
import { Chip, Tooltip, makeStyles } from '@material-ui/core';
import { changeStateByNestedKey } from '@actions/fugaActions';
import CommonTableGrid from '@common/CommonTableGrid';
import OverflowTip from '@common/OverflowTip';
import TerritoriesPerformersModal from '@common/modals/TerritoriesPerformersModal';
import DeleteClientDealDialog from '@common/DeleteClientDealDialog';
import { formatTerritoryCodes, getAllTerritories } from '@actions/territories';
import { getPerformerById } from '@actions/performers';

const useStyles = makeStyles(() => ({
    btn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '20px',
        height: '20px',
        color: '#5F5F60',
    },
    disabledIcon: {
        color: 'rgba(0, 0, 0, 0.25)',
    },
}));

function PerformerDealsTable(props) {
    const headers = [
        {
            title: 'Territories',
            dataIndex: 'territories',
        },
        {
            title: 'Claim Method',
            dataIndex: 'claimMethod',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            hasNumericData: true,
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            hasNumericData: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: '',
            dataIndex: '',
            hasIcon: true,
        },
    ];

    const dispatch = useDispatch();
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const performersModel = useSelector((state) => state.fugaReducers.performers);

    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dataItem, setDataItem] = useState({});

    useEffect(() => {
        dispatch(getAllTerritories());
        setTerritories(territoriesModel.checkedTerritories);
    }, [territoriesModel.checkedTerritories]);

    const EditBtnsGroup = ({ data }) => {
        const classes = useStyles();
        const handleEdintBtn = () => {
            dispatch(formatTerritoryCodes(data.territoryCodes));
            dispatch(changeStateByNestedKey('territories', 'editableDealInfo', data));
            setShowTerritoryModal(true);
        };

        const handleDeleteBtn = () => {
            if (!data.locked) {
                setDataItem(data);
                setIsDialogOpen(true);
            }
        };

        return (
            <>
                <EditOutlined
                    style={{ marginRight: '8px' }}
                    className={classes.btn}
                    onClick={handleEdintBtn}
                />
                <Tooltip
                    title="Client Deal cannot be deleted as one or more registration actions have already been taken"
                    disableHoverListener={!data.locked}
                >
                    <DeleteOutline
                        onClick={handleDeleteBtn}
                        className={`${classes.btn} ${data.locked ? classes.disabledIcon : ''}`}
                    />
                </Tooltip>
            </>
        );
    };

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle.includes('status')) {
            return (
                <>
                    <Chip
                        label={dataItem.status ? 'Current' : 'Expired'}
                        variant={dataItem.status ? 'outlined' : 'filled'}
                        color={dataItem.status ? 'primary' : 'secondary'}
                    />
                </>
            );
        } else if (coulumnTitle === '') {
            return <EditBtnsGroup data={dataItem} />;
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex]}</OverflowTip>;
        }
    });

    return (
        <div style={{ width: '100%', marginBottom: '32px' }}>
            <CommonTableGrid
                dataList={performersModel.clientDeals}
                headerList={headers}
                loading={performersModel.tableIsLoading}
                cellDataCb={getSpecificData}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: getPerformerById,
                        argumentsList: [props.match.params.id],
                    },
                }}
                selection={{
                    isSelectable: false,
                }}
                rowsPerPageOff={true}
                tablePaginationOff={true}
            />
            <TerritoriesPerformersModal
                showTerritoryModal={showTerritoryModal}
                setShowTerritoryModal={setShowTerritoryModal}
                territories={territories}
                setTerritories={setTerritories}
                groupedTerritories={territoriesModel.groupedTerritories}
                clientId={props.match.params.id}
                isEditing={true}
            />
            <DeleteClientDealDialog
                isOpen={isDialogOpen}
                closeCb={setIsDialogOpen}
                dataItem={{ clientDealId: dataItem.id, clientId: props.match.params.id }}
                isPerformer={true}
            />
        </div>
    );
}

const withUrlPerformerDealsTable = withRouter(PerformerDealsTable);
export default connect((r) => r)(withUrlPerformerDealsTable);
