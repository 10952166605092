const isSymbolsCountValid = (value, handleTooltipOpen, handleTooltipClose, setCommentSymCount) => {
    const symbolsCount = value.length;
    if (symbolsCount > 5000) {
        handleTooltipOpen();
        return false;
    }
    setCommentSymCount(symbolsCount);
    handleTooltipClose();

    return true;
};

export { isSymbolsCountValid };
