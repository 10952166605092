import userData from '@data/userData';
import { handlePostRequest } from '@dataUtils/APIUtils';

const CONTROLLER_URL = userData.api.baseAssetClaimEventUrl();

async function changeBulkStatusForAPI(urlPath, bodyParams, queryParams) {
    return handlePostRequest({
        baseUrl: CONTROLLER_URL,
        path: `bulk/${urlPath}/change-status/${queryParams.cmoId}`,
        queryParams,
        bodyParams,
        successMessage: 'Bulk status changed successfully',
        errorMessage: 'Failed to change bulk status',
        pendingMessage: 'Processing bulk status change...',
        withToasts: bodyParams.updateStatuses,
    });
}

async function createBulkComments(queryParams, bodyParams, isPerformer) {
    const type = isPerformer ? 'performer' : 'rh';
    const path = `bulk/${type}/create-comment/${queryParams.cmoId}`;
    return handlePostRequest({
        baseUrl: CONTROLLER_URL,
        path,
        queryParams,
        bodyParams,
        successMessage: 'Comments created successfully',
        errorMessage: 'Failed to create comments',
        pendingMessage: 'Creating comments...',
    });
}

async function raiseBulkAlertForRightsHolder(queryParams, bodyParams) {
    return handlePostRequest({
        baseUrl: CONTROLLER_URL,
        path: `alert/bulk/rh/raise/${queryParams.cmoId}`,
        queryParams,
        bodyParams,
        successMessage: 'Alerts for rights holder raised successfully',
        errorMessage: 'Failed to raise alerts for rights holder',
        pendingMessage: 'Raising alerts for rights holder...',
    });
}

async function raiseBulkAlertForPerformer(queryParams, bodyParams) {
    return handlePostRequest({
        baseUrl: CONTROLLER_URL,
        path: `alert/bulk/performer/raise/${queryParams.cmoId}`,
        queryParams,
        bodyParams,
        successMessage: 'Alerts for performer raised successfully',
        errorMessage: 'Failed to raise alerts for performer',
        pendingMessage: 'Raising alerts for performer...',
    });
}

export {
    changeBulkStatusForAPI,
    raiseBulkAlertForRightsHolder,
    raiseBulkAlertForPerformer,
    createBulkComments,
};
