import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    makeStyles,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CommonDropDownButtonWithFixedLabel from '@common/CommonDropDownButtonWithFixedLabel';
import { EditOutlined } from '@material-ui/icons';
import SaveIconSvg from '@images/svgComponents/SaveIconSvg';
import CrossIconSvg from '@images/svgComponents/CrossIconSvg';
import moment from 'moment';
import ThumbsUpIconSvg from '@images/svgComponents/ThumbsUpIconSvg';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { resolveAlertMsgByAlertId, updateAlertMessage } from '@actions/assetsClaimEvent';
import { manualAlertsItemsList } from '@pages/AssetsCmoPage/common/common';
import { createAlertMsgByAssetClaimEventIdAPI } from '@api/assetsClaimEventController';

const useStyles = makeStyles(() => ({
    title: {
        padding: '19px 16px',
    },
    content: {
        padding: '10px 16px',
        minWidth: '568px',
    },
    textField: {
        width: '488px',
        padding: 0,
    },
    textCounter: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '488px',
    },
    addAlertButton: {
        fontSize: '13px',
        height: '30px',
        padding: '4px 10px',
    },
    addIcon: {
        margin: '0 10px 0 0',
        width: '18px',
        height: '18px',
    },
    addAlertBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    alertDropDownLabel: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.25px',
        textTransform: 'capitalize',
        padding: 0,
        '&:hover': {
            background: 'unset',
        },
    },
    infoIcon: { '& svg': { width: '18px', height: '18px' } },
    operationsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    saveIcon: {
        marginLeft: '16px',
        marginRight: '4px',
    },
    thumbsUPIcon: {
        marginLeft: '16px',
        marginRight: '4px',
    },
    operationIcons: { '&:hover': { cursor: 'pointer' }, marginLeft: '8px' },
    disabledIcon: {
        '& svg': { fill: '#00000061' },
        '&:hover': { cursor: 'unset' },
    },
    metaDataIcons: {
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
    },
    actionNDateContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '18px',
        justifyContent: 'space-between',
    },
    editIcon: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '24px',
        height: '24px',
        color: '#5F5F60',
        marginLeft: '8px',
    },
    coloredIcon: {
        height: '18px',
    },
    alertRow: {
        marginBottom: '26px',
    },
}));

export default function CmoAssetRegistrationManualAlertsModal({
    isOpen,
    setOpen,
    dataItem,
    pageModelName,
    getAssetData,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [alertMsgInput, setAlertMsgInput] = useState('');
    const [severalAlertMsgInputs, setSeveralAlertMsgInputs] = useState([{ text: '', id: -1 }]);
    const [severalAlertMsgCounts, setSeveralAlertMsgCounts] = useState([{ count: 0, id: -1 }]);
    const [severalAlertMsgDisabledStates, setSeveralAlertMsgDisabledStates] = useState([
        { isDisabled: false, id: -1 },
    ]);
    const [alertMsgSymCount, setAlertMsgSymCount] = useState(0);
    const assetRegistrationModel = useSelector((state) => state.fugaReducers[pageModelName]);
    const [isTypesDropdwnVisible, setisTypesDropdwnVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        setIsUpdated(false);
    }, []);

    useEffect(() => {
        const alertMsgArray = assetRegistrationModel.alertMsgs.map((el) => ({
            text: el.alertMsg,
            id: el.alertId,
        }));
        setSeveralAlertMsgInputs(alertMsgArray);
        const alertInitCountArray = assetRegistrationModel.alertMsgs.map((el) => ({
            count: (el.alertMsg + '').length || 0,
            id: el.alertId,
        }));
        setSeveralAlertMsgCounts(alertInitCountArray);
        setSeveralAlertMsgInputs(alertMsgArray);
        const alertDisabledStateArray = assetRegistrationModel.alertMsgs.map((el) => ({
            isDisabled: false,
            id: el.alertId,
        }));
        setSeveralAlertMsgDisabledStates(alertDisabledStateArray);
    }, [assetRegistrationModel.alertMsgs]);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleClose = (event, reason, updated = isUpdated) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            setAlertMsgInput('');
            setSelectedValue(null);
            setisTypesDropdwnVisible(false);
            setAlertMsgSymCount(0);
            if (updated) {
                getAssetData();
            }
        }
    };

    const resetAlertTextBeforeEdit = (id) => {
        const textToResetTo =
            assetRegistrationModel.alertMsgs.find((el) => el.alertId === id)?.alertMsg || '';
        const indexOfResetableAlert = severalAlertMsgInputs.findIndex((el) => el.id === id);
        const updatedArrayOfInputs = [...severalAlertMsgInputs];
        updatedArrayOfInputs[indexOfResetableAlert].text = textToResetTo;
        setSeveralAlertMsgInputs(updatedArrayOfInputs);
    };

    const getSymbolsCount = (value, isBulk = false, id = 0) => {
        const symbolsCount = value.length;
        if (symbolsCount > 5000) {
            handleTooltipOpen();
            return false;
        }
        if (isBulk) {
            let result = [...severalAlertMsgCounts];
            result = result.map((el) => {
                if (el.id === id) el.count = symbolsCount;
                return el;
            });
            setSeveralAlertMsgCounts(result);
        } else {
            setAlertMsgSymCount(symbolsCount);
        }
        handleTooltipClose();
        return true;
    };

    const handleInputChange = (e) => {
        const value = e.currentTarget.value;
        if (getSymbolsCount(value)) setAlertMsgInput(value);
    };

    const handleSeveralInputChange = (e, id) => {
        const value = e.currentTarget.value;
        if (getSymbolsCount(value, true, id)) {
            let result = [...severalAlertMsgInputs];
            result = result.map((el) => {
                if (el.id === id) el.text = e.target.value;
                return el;
            });
            setSeveralAlertMsgInputs(result);
        }
    };

    const handleSeveralInputDisableState = (id) => {
        let result = severalAlertMsgDisabledStates.map((el) => {
            if (el.id === id) {
                return { ...el, isDisabled: !el.isDisabled };
            }
            return el;
        });
        setSeveralAlertMsgDisabledStates(result);
    };

    const getExistingAlertMsg = (id) => {
        return severalAlertMsgInputs.find((el) => el.id === id)?.text;
    };
    const getExistingAlertMsgCount = (id) => {
        return severalAlertMsgCounts.find((el) => el.id === id)?.count;
    };
    const getExistingAlertMsgDisabledState = (id) => {
        return severalAlertMsgDisabledStates.find((el) => el.id === id)?.isDisabled;
    };

    const updateAndClose = () => {
        setIsUpdated(true);
        handleClose(null, null, true);
    };

    const handleAlertInputSave = async () => {
        if (selectedValue) {
            await createAlertMsgByAssetClaimEventIdAPI({
                alertMsg: alertMsgInput,
                alertCategory: selectedValue?.beValue,
                assetClaimId: dataItem.assetClaimId,
            });
            updateAndClose();
        }
    };

    const handleAlertInputUpdate = (alertId = -1, alertMsg = '', assetClaimId) => {
        dispatch(
            updateAlertMessage(
                {
                    alertMsg: alertMsg,
                    alertId: alertId,
                },
                assetClaimId,
                pageModelName,
            ),
        );
        setIsUpdated(true);
    };

    const handleAlertResolve = (alertId, assetClaimId) => {
        dispatch(resolveAlertMsgByAlertId(alertId, assetClaimId, pageModelName));
        setIsUpdated(true);
    };

    const handleAddNewAlertBtn = () => {
        setisTypesDropdwnVisible(true);
    };

    const handleAlertInputCancel = () => {
        if (selectedValue) {
            setSelectedValue(null);
            setAlertMsgInput('');
            setAlertMsgSymCount(0);
        }
    };

    const getMetaDataByAlertCategory = (category) => {
        return manualAlertsItemsList.find((el) => el.beValue === category) || {};
    };

    const isCloseButtonActive = () => {
        return !!selectedValue || severalAlertMsgDisabledStates.some((el) => el.isDisabled);
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md" disableEscapeKeyDown>
            <DialogTitle disableTypography onClose={handleClose} className={`${classes.title}`}>
                <Typography variant="h6">Alerts</Typography>
            </DialogTitle>
            <DialogContent className={`${classes.content}`}>
                {assetRegistrationModel?.alertMsgs?.map((item) => {
                    const alertMetaData = getMetaDataByAlertCategory(item.alertCategory);
                    if (item.alertState !== 'RESOLVED') {
                        return (
                            <div key={item.alertId} className={classes.alertRow}>
                                <div className={classes.actionNDateContainer}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className={classes.metaDataIcons}>
                                            {alertMetaData.coloredIcon &&
                                                alertMetaData.coloredIcon(classes.coloredIcon)}
                                        </div>
                                        <Typography
                                            style={{ color: alertMetaData.color }}
                                            variant="subtitle2"
                                        >
                                            {alertMetaData.title}
                                        </Typography>
                                    </div>
                                    <div style={{ marginTop: '2px' }}>
                                        <Typography
                                            variant="caption"
                                            style={{
                                                marginLeft: '8px',
                                                display: 'flex',
                                                height: '100%',
                                            }}
                                        >
                                            Added{' '}
                                            {`${moment(item.date).format('D/M/YYYY HH:mm')} UTC`}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.operationsContainer}>
                                    {alertMetaData.beValue === 'CMO_VALIDATION' ? (
                                        <Typography variant="p">
                                            {getExistingAlertMsg(item.alertId) || 'Empty message'}
                                        </Typography>
                                    ) : (
                                        <div>
                                            <Tooltip
                                                title="Text should be less than 5000 charactes"
                                                disableHoverListener={true}
                                                open={tooltipOpen}
                                                className={classes.textField}
                                            >
                                                <TextFieldWrapper
                                                    onChange={(evt) =>
                                                        handleSeveralInputChange(evt, item.alertId)
                                                    }
                                                    name="alertMsg"
                                                    type="text"
                                                    multiline
                                                    maxRows={10}
                                                    value={getExistingAlertMsg(item.alertId) || ''}
                                                    isDisabled={
                                                        !getExistingAlertMsgDisabledState(
                                                            item.alertId,
                                                        ) || false
                                                    }
                                                    classNameOpt={classes.textField}
                                                    InputProps={{
                                                        readOnly:
                                                            !getExistingAlertMsgDisabledState(
                                                                item.alertId,
                                                            ) || false,
                                                    }}
                                                    onBlur={() => setTooltipOpen(false)}
                                                />
                                            </Tooltip>
                                            <div className={classes.textCounter}>
                                                <FormHelperText>
                                                    {getExistingAlertMsgCount(item.alertId) || 0}
                                                    /5000
                                                </FormHelperText>
                                            </div>
                                        </div>
                                    )}
                                    {alertMetaData.beValue !== 'CMO_VALIDATION' && (
                                        <div className={classes.operationsContainer}>
                                            {!getExistingAlertMsgDisabledState(item.alertId) ? (
                                                <Tooltip title="Resolve this open alert">
                                                    <div>
                                                        <ThumbsUpIconSvg
                                                            styles={`${classes.thumbsUPIcon} ${classes.operationIcons}`}
                                                            onClickCb={() =>
                                                                handleAlertResolve(
                                                                    item.alertId,
                                                                    item.assetClaimId,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Save this alert">
                                                    <div>
                                                        <SaveIconSvg
                                                            styles={`${classes.saveIcon} ${classes.operationIcons}`}
                                                            onClickCb={() =>
                                                                handleAlertInputUpdate(
                                                                    item.alertId,
                                                                    getExistingAlertMsg(
                                                                        item.alertId,
                                                                    ),
                                                                    item.assetClaimId,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {!getExistingAlertMsgDisabledState(item.alertId) ? (
                                                <Tooltip title="Edit text of this alert">
                                                    <div
                                                        onClick={() =>
                                                            handleSeveralInputDisableState(
                                                                item.alertId,
                                                            )
                                                        }
                                                    >
                                                        <EditOutlined
                                                            className={classes.editIcon}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Cancel edit">
                                                    <div>
                                                        <CrossIconSvg
                                                            styles={`${classes.operationIcons}`}
                                                            onClickCb={() => {
                                                                resetAlertTextBeforeEdit(
                                                                    item.alertId,
                                                                );
                                                                handleSeveralInputDisableState(
                                                                    item.alertId,
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    }
                })}

                {isTypesDropdwnVisible && (
                    <CommonDropDownButtonWithFixedLabel
                        btnTitle="Alert Type"
                        manualAlertsItemsList={manualAlertsItemsList.slice(
                            0,
                            manualAlertsItemsList.length - 1,
                        )}
                        customStyles={{
                            color: 'primary',
                            variant: 'text',
                            classStyles: classes.alertDropDownLabel,
                        }}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                )}
                {!isTypesDropdwnVisible && (
                    <div className={classes.addAlertBtn}>
                        <Button
                            className={classes.addAlertButton}
                            variant="outlined"
                            onClick={handleAddNewAlertBtn}
                        >
                            <AddIcon className={classes.addIcon} />
                            Add new alert
                        </Button>
                    </div>
                )}
                {isTypesDropdwnVisible && (
                    <div className={classes.operationsContainer}>
                        <div>
                            <Tooltip
                                title="Text should be less than 5000 charactes"
                                disableHoverListener={true}
                                open={tooltipOpen}
                            >
                                <div>
                                    <TextFieldWrapper
                                        onChange={(evt) => handleInputChange(evt)}
                                        name="alertMsg"
                                        type="text"
                                        multiline
                                        maxRows={10}
                                        value={alertMsgInput || ''}
                                        isDisabled={!selectedValue}
                                        classNameOpt={classes.textField}
                                        InputProps={{
                                            readOnly: !selectedValue,
                                        }}
                                        onBlur={() => setTooltipOpen(false)}
                                    />
                                </div>
                            </Tooltip>
                            <div className={classes.textCounter}>
                                <FormHelperText>{alertMsgSymCount}/5000</FormHelperText>
                            </div>
                        </div>
                        <div className={classes.operationsContainer}>
                            <Tooltip title="Save this alert">
                                <div>
                                    <SaveIconSvg
                                        styles={`${classes.saveIcon} ${classes.operationIcons} ${
                                            selectedValue ? '' : classes.disabledIcon
                                        }`}
                                        onClickCb={handleAlertInputSave}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip title="Clear text">
                                <div>
                                    <CrossIconSvg
                                        styles={`${classes.operationIcons} ${
                                            selectedValue ? '' : classes.disabledIcon
                                        }`}
                                        onClickCb={handleAlertInputCancel}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Tooltip
                    disableHoverListener={!isCloseButtonActive()}
                    title={"Select 'Save' or 'Clear text' before closing"}
                >
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="primary"
                        disableElevation
                        disabled={isCloseButtonActive()}
                    >
                        close
                    </Button>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}
