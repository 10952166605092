import React from 'react';

import ClientsHeaderBtnDropDownButton from './ClientsHeaderBtnDropDownButton';
import HeaderIconsPanel from './HeaderIconsPanel';
import { items } from '@data/constants';
import utils from '@utils/utils';
import { Link } from 'react-router-dom';
import { Button, AppBar, Typography, Toolbar, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getRouteFromUserRole } from '@actions/authActions';
import logo from '@images/branding/DT_logo.png';

const useStyles = makeStyles((theme) => ({
    appLogoName: {
        color: theme.palette.text.white,
    },
    logoParent: {
        paddingLeft: '4px',
        paddingRight: '10px',
    },
    logo: {
        width: 'auto',
        height: '36px',
    },
    appBar: {
        width: '100%',
        height: '48px',
        left: '0px',
        top: '0px',
    },
}));

function Header() {
    const customClasses = useStyles();

    return (
        <AppBar position="relative" className={customClasses.appBar}>
            <Toolbar variant="dense" disableGutters>
                <>
                    <Link to={getRouteFromUserRole()}>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignContent="center"
                            className={customClasses.logoParent}
                        >
                            <Grid item xs={3}>
                                <img className={customClasses.logo} src={logo} />
                            </Grid>

                            <Grid item xs={9} container direction="column" alignItems="flex-start">
                                <Grid item>
                                    <Typography
                                        classes={{ root: customClasses.appLogoName }}
                                        variant="subtitle2"
                                        style={{ textTransform: 'uppercase' }}
                                        noWrap
                                    >
                                        Neighbouring Rights
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        classes={{ root: customClasses.appLogoName }}
                                        variant="subtitle2"
                                        style={{ fontSize: 10 }}
                                    >
                                        Powered by FUGA
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                    <>
                        {utils.checkItemRolesAndPermissions(items.btns.cmoListHeader) && (
                            <Link to="/cmo-list">
                                <Button variant="text">CMOs</Button>
                            </Link>
                        )}
                        {utils.checkItemRolesAndPermissions(items.btns.clientsListHeader) && (
                            <ClientsHeaderBtnDropDownButton />
                        )}
                        {utils.checkItemRolesAndPermissions(items.btns.userMgmgtHeader) && (
                            <Link to="/users">
                                <Button variant="text">User Management</Button>
                            </Link>
                        )}
                    </>
                </>
                <HeaderIconsPanel />
            </Toolbar>
        </AppBar>
    );
}

export default Header;
