import React from 'react';
import { Chip, makeStyles, withStyles } from '@material-ui/core';
import { ArrowDropDown, Close } from '@material-ui/icons';

const ChipBtn = withStyles({
    root: {
        '&.Mui-disabled': {
            background: '#ececee',
        },
        color: '#1F1F21',
        borderRadius: '30px',
        border: '1px solid #9b9b9d',
        padding: '6px 8px 6px 12px',
        backgroundColor: '#ffffff',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '19px',
        textAlign: 'center',
        letterSpacing: '0.25px',
        textTransform: 'capitalize',
        marginRight: '8px',
        justifyContent: 'normal',
        minHeight: 'auto',
        maxWidth: 'auto',
        boxSizing: 'border-box',
        '&:hover': {
            background: 'rgba(0,0,0,0.07) !important',
        },
        '&:focus': {
            background: 'initial',
        },
        '&:active': {
            boxShadow: 'none',
            background: 'rgba(0,0,0,0.12)',
            border: '1px solid #9b9b9d',
        },
        position: 'relative',
    },
    icon: {
        width: '18px',
        height: '18px',
        position: 'absolute',
        right: 0,
        margin: 0,
        marginRight: '8px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    label: {
        padding: 0,
        paddingRight: '26px',
    },
})(Chip);

const getLabelValue = (valuesList, filterField) => {
    if (valuesList.length < 1) return valuesList[0];
    switch (filterField) {
        case 'lastModified':
            return `${valuesList[0]} - ${valuesList[1]}`;
        default:
            return `${valuesList[0]} +${valuesList.length - 1} more`;
    }
};

const useStyles = makeStyles((theme) => ({
    selectedValue: {
        maxWidth: 303,
        background: '#C3CCDC !important',
        '&:hover': {
            background: 'rgba(0,0,0,0.07)',
        },
    },
}));

function FilterChip({
    onClick,
    dataItem: { label, value = '', filterField },
    handleFilterCancelCb,
}) {
    const classes = useStyles();

    const handleFilterSelected = (evt) => {
        if (onClick) onClick(evt);
    };

    const handleCancelClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (handleFilterCancelCb) handleFilterCancelCb();
    };

    const valueRemaked = value.includes(', ') ? value.split(', ') : value;
    const valueToShow = Array.isArray(valueRemaked)
        ? getLabelValue(valueRemaked, filterField)
        : valueRemaked;

    return (
        <ChipBtn
            className={value.length > 0 ? classes.selectedValue : ''}
            label={valueToShow || label}
            icon={value.length > 0 ? <Close onClick={handleCancelClick} /> : <ArrowDropDown />}
            onClick={handleFilterSelected}
            disableRipple
        />
    );
}

export default FilterChip;
