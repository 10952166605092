import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    makeStyles,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
    createCommentByAssetsClaimId,
    getCommentByAssetClaimIdAPI,
} from '@api/assetsClaimEventController';
import { isSymbolsCountValid } from '@common/modals/common';

const useStyles = makeStyles(() => ({
    title: {
        padding: '19px 16px',
    },
    content: {
        padding: '10px 16px',
    },
    textField: {
        width: '552px',
        paddingBottom: 0,
    },
    commentCounter: {
        display: 'flex',
        paddingBottom: '21px',
        justifyContent: 'flex-end',
    },
}));

export function AssetsRegistrationCommentsModal({ isOpen, setOpen, pageModelName, getAssetData }) {
    const classes = useStyles();
    const [commentInput, setCommentInput] = useState('');
    const [commentSymCount, setCommentSymCount] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const dataItem = useSelector((state) => state.fugaReducers[pageModelName].selectedRowDataItem);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const getComments = async () => {
        const comment = await getCommentByAssetClaimIdAPI(dataItem.assetClaimId);

        setCommentInput(comment?.data?.commentMsg || '');
    };

    useEffect(() => {
        getComments();
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        await createCommentByAssetsClaimId({
            commentMsg: commentInput,
            assetClaimId: dataItem.assetClaimId,
        });
        getAssetData();
        handleClose();
    };

    const handleInputChange = (e) => {
        const value = e.currentTarget.value;
        if (isSymbolsCountValid(value, handleTooltipOpen, handleTooltipClose, setCommentSymCount)) {
            setCommentInput(value);
        }
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md">
            <DialogTitle onClose={handleClose} className={`${classes.title}`}>
                <Typography variant="h6">Edit Comment</Typography>
            </DialogTitle>
            <DialogContent className={`${classes.content}`}>
                <Tooltip
                    title="Input text should be less than 5000 charactes"
                    disableHoverListener="true"
                    open={tooltipOpen}
                >
                    <TextField
                        label="Comment"
                        onChange={(evt) => handleInputChange(evt)}
                        name="comment"
                        type="text"
                        multiline
                        maxRows={10}
                        value={commentInput || ''}
                        className={classes.textField}
                        onBlur={() => setTooltipOpen(false)}
                    />
                </Tooltip>
                <div className={classes.commentCounter}>
                    <FormHelperText>{commentSymCount}/5000</FormHelperText>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary" disableElevation>
                    cancel
                </Button>
                <Button onClick={handleSave} variant="contained" color="primary" disableElevation>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
