import Fetch from '@utils/Fetch';
import userData from '@data/userData';

const CONTROLLER_URL = userData.api.baseAssetClaimEventUrl();

// Get

async function getAssetClaimByAssetClaimIdAPI(assetClaimId) {
    const url = `${CONTROLLER_URL}/${assetClaimId}`;

    return Fetch.fetchWithErrorToast({
        url,
    });
}

async function getAlertsByAssetClaimIdAPI(assetClaimId) {
    const url = `${CONTROLLER_URL}/alert/${assetClaimId}`;

    return Fetch.fetchWithErrorToast({
        url,
    });
}

async function getCommentByAssetClaimIdAPI(assetClaimId) {
    const url = `${CONTROLLER_URL}/comment/${assetClaimId}`;

    return Fetch.fetchWithErrorToast({
        url,
    });
}

// Post

function changeRegistrationStatusForAssetAPI(assetClaimId, content) {
    const url = `${CONTROLLER_URL}/changeAssetRegistrationStatus/${assetClaimId}`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Registration status changed successfully',
        errorMessage: 'Failed to change registration status',
        pendingMessage: 'Changing registration status...',
    });
}

function createCommentByAssetsClaimId(content) {
    const url = `${CONTROLLER_URL}/comment`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Comment created successfully',
        errorMessage: 'Failed to create comment',
        pendingMessage: 'Creating comment...',
    });
}

function createAlertMsgByAssetClaimEventIdAPI(content) {
    const url = `${CONTROLLER_URL}/alert`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Alert message created successfully',
        errorMessage: 'Failed to create alert message',
        pendingMessage: 'Creating alert message...',
    });
}

function resolveAlertMsgByAlertIdAPI(alertId) {
    const url = `${CONTROLLER_URL}/alert/resolve/${alertId}`;

    return Fetch.fetchWithToasts({
        url,
        successMessage: 'Alert resolved successfully',
        errorMessage: 'Failed to resolve alert',
        pendingMessage: 'Resolving alert...',
    });
}

function updateAlertMessageAPI(content) {
    const url = `${CONTROLLER_URL}/alert`;

    const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Alert message updated successfully',
        errorMessage: 'Failed to update alert message',
        pendingMessage: 'Updating alert message...',
    });
}

export {
    changeRegistrationStatusForAssetAPI,
    createCommentByAssetsClaimId,
    createAlertMsgByAssetClaimEventIdAPI,
    resolveAlertMsgByAlertIdAPI,
    updateAlertMessageAPI,
    getAssetClaimByAssetClaimIdAPI,
    getAlertsByAssetClaimIdAPI,
    getCommentByAssetClaimIdAPI,
};
