import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseUrl;
const contactUrl = userData.api.baseContactUrl();

export function getContactByIdAPI(contactId) {
    const url = `${CONTROLLER_URL}/contact/${contactId}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get contact',
    });
}

function createContactAPI(content) {
    const url = `${CONTROLLER_URL}/contact/create-contact`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...content, isAllClientsContactedFor: false }),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        pendingMessage: 'Creating contact...',
        successMessage: 'Contact successfully added',
        errorMessage: 'Failed to create contact',
    });
}

function editContactAPI(content) {
    const url = `${CONTROLLER_URL}/contact/edit-contact`;

    const options = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...content, isAllClientsContactedFor: false }),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        pendingMessage: 'Editing contact...',
        successMessage: 'Contact successfully edited',
        errorMessage: 'Failed to edit contact',
    });
}

function deleteContactByIdAPI(contactId) {
    const url = `${contactUrl}/remove-contact/${contactId}`;

    const options = {
        method: 'DELETE',
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
    });
}

export { createContactAPI, editContactAPI, deleteContactByIdAPI };
