import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { withRouter } from 'react-router-dom';
import { FormControl, Grid } from '@material-ui/core';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';

const PerformerIdentifiersContent = (props) => {
    const dispatch = useDispatch();
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const { byClietnId } = performersModel;
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        dispatch(changeStateByNestedKey('performers', 'spotifyIdErrorText', ''));
        dispatch(changeStateByNestedKey('performers', 'aliasesPerformerErrorText', ''));
    }, []);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                ipn: byClietnId.ipn,
                otherIdent: byClietnId.otherIdentifiers,
                nrpClientId: byClietnId.nrpClientId,
                spotifyIds: byClietnId.spotifyIds,
                clientAliases: (byClietnId.clientAliases || []).join(', '),
                royaltiesClientName: byClietnId.royaltiesClientName,
            }),
        );
    }, [performersModel.byClietnId]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <>
            <div className="topContainer flex-left" style={{ marginBottom: '32px' }}>
                <Grid container spacing={4}>
                    <Grid item sx={6} md={6}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '8' }}
                                label="NRP Client ID"
                                onChange={(evt) => handleInputChange(evt)}
                                name="ipn"
                                type="text"
                                isDisabled={true}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.nrpClientId
                                        ? performersModel.mainDetailsInptValues.nrpClientId
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '9' }}
                                label="IPN"
                                onChange={(evt) => handleInputChange(evt)}
                                name="ipn"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.ipn
                                        ? performersModel.mainDetailsInptValues.ipn
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '10' }}
                                label="Royalties Client Name"
                                onChange={(evt) => handleInputChange(evt)}
                                name="royaltiesClientName"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.royaltiesClientName
                                        ? performersModel.mainDetailsInptValues.royaltiesClientName
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item sx={6} md={6}>
                        <FormControl
                            variant="standard"
                            style={{ textAlign: 'left', width: '100%' }}
                        >
                            <TextFieldWrapper
                                inputProps={{ tabIndex: '11' }}
                                label="Other identifiers"
                                onChange={(evt) => handleInputChange(evt)}
                                name="otherIdent"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.otherIdent
                                        ? performersModel.mainDetailsInptValues.otherIdent
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <TextFieldWrapper
                                helperText={performersModel.spotifyIdErrorText}
                                error={!!performersModel.spotifyIdErrorText}
                                inputProps={{ tabIndex: '12' }}
                                label="Spotify Artist IDs"
                                onChange={(evt) => handleInputChange(evt)}
                                name="spotifyIds"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={
                                    performersModel.mainDetailsInptValues.spotifyIds
                                        ? performersModel.mainDetailsInptValues.spotifyIds
                                        : ''
                                }
                                style={{ margin: '0 0 32px 0' }}
                            />
                            <TextFieldWrapper
                                helperText={performersModel.aliasesPerformerErrorText}
                                error={!!performersModel.aliasesPerformerErrorText}
                                inputProps={{ tabIndex: '13' }}
                                label="Royalties Contract Reference(s)"
                                onChange={(evt) => handleInputChange(evt)}
                                name="clientAliases"
                                type="text"
                                isDisabled={isDisabled}
                                InputProps={{
                                    readOnly: isDisabled,
                                }}
                                value={joinClientAliases(
                                    performersModel.mainDetailsInptValues.clientAliases,
                                )}
                                style={{ margin: '0 0 32px 0' }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

function joinClientAliases(clientAliases) {
    if (Array.isArray(clientAliases)) {
        return clientAliases.join(', ');
    } else if (clientAliases !== undefined) {
        return clientAliases.toString();
    } else {
        return '';
    }
}

const withUrlPerformerIdentifiersContent = withRouter(PerformerIdentifiersContent);
export default connect((r) => r)(withUrlPerformerIdentifiersContent);
