import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseUrl;

function checkMaintenanceAvailabilityAPI() {
    const url = `${CONTROLLER_URL}/nr-user-access`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to check maintenance availability',
    });
}

export { checkMaintenanceAvailabilityAPI };
