import { TextField, makeStyles } from '@material-ui/core';
import React from 'react';

const useClasses = makeStyles({
    customReadOnlyTextFields: {
        // css for direct disabling
        // '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        //     borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        // },
        // '& .MuiInput-underline.Mui-focused:after': {
        //     transform: 'scaleX(0)',
        // },
        '& .MuiInput-underline': {
            pointerEvents: 'none',
        },
    },
});

export default function TextFieldWrapper(props) {
    const { classNameOpt, isDisabled, ...textFieldProps } = props;
    const classes = useClasses();
    const clasStr = `${textFieldProps.className} ${classNameOpt} ${isDisabled ? classes.customReadOnlyTextFields : ''}`;
    return <TextField {...textFieldProps} className={clasStr} />;
}
