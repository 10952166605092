import React, { useEffect, useState } from 'react';
import {
    DEFAULT_TAB_CMOS,
    DEFAULT_TAB_MAIN_DETAILS,
    DEFAULT_TAB_REPERTORIRES,
    DEFAULT_TAB_STATEMENTS,
    PERMISSIONS_REPERTOIRE,
    PERMISSIONS_STATEMENTS,
} from '@data/globalConstants';
import { Tabs, Tab, Box, makeStyles } from '@material-ui/core';
import PerformerRepertoires from './PerformerRepertoires';
import PerformerMainDetailsNavigation from './PerformerMainDetailsNavigation';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import CmoConnectionTable from './CmoConnectionTable';
import CommonDropDownButton from '@common/CommonDropDownButton';
import NewCmoConnectionModal from '@common/modals/NewCmoConnectionModal';
import { withRouter } from 'react-router-dom';
import utils from '@utils/utils';
import { items } from '@data/constants';
import StatementsTable from '@common/StatementsTable';
import { getCmoConnectionsByClientId } from '@actions/apiActions';
import { getPerformerById } from '@actions/performers';

const useStyles = makeStyles({
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px 0',
    },
    tab: {
        width: '7%',
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

function PerformerInfoNavigation(props) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const performersModel = useSelector((state) => state.fugaReducers.performers);

    const panes = {
        [DEFAULT_TAB_MAIN_DETAILS]: {
            label: 'MAIN DETAILS',
            id: 0,
            isVisible: utils.checkItemRolesAndPermissions(items.tabs.mainDetails),
        },
        [DEFAULT_TAB_REPERTORIRES]: {
            label: 'REPERTOIRE',
            id: 1,
            isVisible:
                utils.checkPermissionsOnPage(
                    performersModel.byClietnId.permissions || [],
                    PERMISSIONS_REPERTOIRE,
                ) || utils.checkItemRolesAndPermissions(items.tabs.repertories),
        },
        [DEFAULT_TAB_STATEMENTS]: {
            label: 'STATEMENTS',
            id: 2,
            isVisible:
                utils.checkPermissionsOnPage(
                    performersModel.byClietnId.permissions || [],
                    PERMISSIONS_STATEMENTS,
                ) || utils.checkItemRolesAndPermissions(items.tabs.statements),
        },
        [DEFAULT_TAB_CMOS]: {
            label: 'CMOS',
            id: 3,
            isVisible: utils.checkItemRolesAndPermissions(items.tabs.cmos),
        },
    };

    useEffect(() => {
        let tabId = 0;
        if (performersModel.byClietnId.permissions) {
            const defaultTabId = utils.getDefaultTabIdForClientPage(
                items.tabs.statements,
                panes,
                performersModel.byClietnId.permissions,
            );
            const tabIdFromClientsListRegistrationsColumn = utils.getTabIdByNameForClientPage(
                performersModel.selectedTab,
                panes,
            );
            tabId = defaultTabId || tabIdFromClientsListRegistrationsColumn;
        }
        if (tabId) {
            setValue(tabId);
        }
    }, [
        performersModel.isInEditMode,
        performersModel.isInSaveNewMode,
        performersModel.byClietnId.permissions,
    ]);

    const openModal = () => {
        setShowTerritoryModal(true);
    };

    const menuItemsList = [
        {
            title: 'Add single CMO',
            onClickClb: openModal,
        },
    ];

    const handleChange = (event, newValue) => {
        if (newValue !== panes.details.id) {
            // this is used to hide btnGroupf from other tabs (Repertoire, Statements, Cmo ...)
            dispatch(changeStateByNestedKey('performers', 'isEditBtnVisible', false));
        } else if (newValue === panes[DEFAULT_TAB_MAIN_DETAILS].id) {
            dispatch(getPerformerById(props.match.params.id));
        }
        setValue(newValue);
    };

    const getTabsContent = () => {
        const finalTabs = [];
        for (const [tabName, tab] of Object.entries(panes)) {
            if (tab.isVisible)
                finalTabs.push(
                    <Tab
                        key={panes[tabName].id}
                        value={tab.id}
                        label={panes[tabName].label}
                        className={classes.tab}
                    />,
                );
        }
        return finalTabs;
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="scrollable"
                    style={{ width: '55%' }}
                >
                    {getTabsContent()}
                </Tabs>
            </Box>
            <TabPanel value={value} index={panes[DEFAULT_TAB_MAIN_DETAILS].id}>
                <PerformerMainDetailsNavigation />
            </TabPanel>
            <TabPanel value={value} index={panes[DEFAULT_TAB_REPERTORIRES].id}>
                <PerformerRepertoires />
            </TabPanel>
            <TabPanel value={value} index={panes[DEFAULT_TAB_STATEMENTS].id}>
                <StatementsTable />
            </TabPanel>
            <TabPanel value={value} index={panes[DEFAULT_TAB_CMOS].id}>
                <div className={classes.flexEnd}>
                    <CommonDropDownButton
                        btnTitle="ADD CMO"
                        menuItemsList={menuItemsList}
                        customStyles={{ color: 'primary', variant: 'contained' }}
                    />
                </div>
                <CmoConnectionTable />
                {showTerritoryModal && (
                    <NewCmoConnectionModal
                        showTerritoryModal={showTerritoryModal}
                        setShowTerritoryModal={setShowTerritoryModal}
                        territories={territories}
                        setTerritories={setTerritories}
                        groupedTerritories={territoriesModel.groupedTerritories}
                        clientId={props.match.params.id}
                        customData={{
                            dealsContainerName: 'clientDeals',
                            selectedCmoStoreName: 'performers',
                            dataModel: performersModel.byClietnId,
                            selectedCmoId: performersModel.selectedCmoId,
                            modalValidations: performersModel.modalValidations,
                            isPerformer: { isPerformer: true },
                            // replaced with updated endpoint as previous was depricated
                            onTableReload: {
                                reloadCb: getCmoConnectionsByClientId,
                                params: [props.match.params.id],
                            },
                            cmoIssuedIds: performersModel.cmoIssuedIds,
                            hasIssuedId: performersModel.hasIssuedId,
                        }}
                    />
                )}
            </TabPanel>
        </>
    );
}

const withUrlPerformerInfoNavigation = withRouter(PerformerInfoNavigation);
export default connect((r) => r)(withUrlPerformerInfoNavigation);
