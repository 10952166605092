import React from 'react';

export default function WarningAmberSvg(props) {
    return (
        <div className={props.styles}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#FF8800"
            >
                <path
                    d="M12 7.5L17.6602 17.2383H6.33984L12 7.5ZM12 4.51172L3.73828 18.75H20.2617L12 4.51172ZM12.7383 14.9883H11.2617V16.5H12.7383V14.9883ZM12.7383 10.4883H11.2617V13.5117H12.7383V10.4883Z"
                    fill="#FF8800"
                />
            </svg>
        </div>
    );
}
