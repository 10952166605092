import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    makeStyles,
} from '@material-ui/core';

import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    titile: {
        padding: '19px 16px',
    },
    content: {
        width: '444px',
        padding: '10px 16px',
    },
}));

function CommonConfirmationDialog({
    isOpen,
    closeCb,
    title,
    description,
    onConfirmCb,
    confirmCbArgs = [],
    noConfirm,
    custumConfirmText,
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
    };

    const handleConfirm = () => {
        if (onConfirmCb) onConfirmCb(...confirmCbArgs);
        handleClose();
    };

    return (
        <div>
            {/* maxWidth="md" makes no sense in here */}
            <Dialog onClose={handleClose} open={open} maxWidth="md">
                <DialogTitle onClose={handleClose} className={`${classes.titile}`}>
                    {title}
                </DialogTitle>
                <DialogContent className={`${classes.content}`}>
                    <Typography variant="body1">{description}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant={noConfirm ? 'contained' : 'outlined'}
                        color="primary"
                        disableElevation
                    >
                        {noConfirm ? 'close' : 'cancel'}
                    </Button>
                    {!noConfirm && (
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleConfirm}
                        >
                            {custumConfirmText ? custumConfirmText : 'confirm'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withRouter(CommonConfirmationDialog);
