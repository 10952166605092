import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseAssetUrl();

async function getAssetTrackTitleLookup(trackTitle, signal) {
    try {
        const url = new URL(`${CONTROLLER_URL}/track-title/lookup`);
        url.searchParams.append('trackTitle', trackTitle);

        return Fetch.fetchWithErrorToast({
            url: url.toString(),
            signal,
        });
    } catch (e) {
        return [];
    }
}

async function getArtistLookup(artist, signal) {
    try {
        const url = new URL(`${CONTROLLER_URL}/artist/lookup`);
        url.searchParams.append('artist', artist);

        return Fetch.fetchWithErrorToast({
            url,
            signal,
        });
    } catch (e) {
        return [];
    }
}

async function getAssetClientNameLookup({ clientName, signal, isPerformer }) {
    try {
        const url = new URL(
            `${CONTROLLER_URL}/client-name/${isPerformer ? 'performer' : 'rh'}/lookup`,
        );
        url.searchParams.append('clientName', clientName);

        return Fetch.fetchWithErrorToast({
            url: url.toString(),
            signal,
        });
    } catch (e) {
        return [];
    }
}

function getRHAssetsByIdAPI(id, queryParams = { sort: '', page: '', size: '' }) {
    const url = new URL(`${CONTROLLER_URL}/rh/${id}`);
    const page = queryParams.page || queryParams.page >= 0;
    url.search = new URLSearchParams({
        ...(page ? { page: queryParams.page } : {}),
        ...(queryParams.size ? { size: queryParams.size } : {}),
        ...(queryParams.sort ? { sort: queryParams.sort } : {}),
    });

    let isrcs = [];
    if (queryParams.isrc) {
        isrcs = queryParams?.isrc?.split(',')?.map((item) => item.trim());
    }

    return Fetch.fetchWithErrorToast({
        url,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(isrcs),
    });
}

function getAssetByPerformerIdAPI(id, queryParams = { page: '', size: '' }) {
    const url = new URL(`${CONTROLLER_URL}/performer/${id}`);

    const page = queryParams.page || queryParams.page === 0;

    url.search = new URLSearchParams({
        ...(page ? { page: queryParams.page } : {}),
        ...(queryParams.size ? { size: queryParams.size } : {}),
        ...(queryParams.sort ? { sort: queryParams.sort } : {}),
        ...(queryParams.direction ? { direction: queryParams.direction } : {}),
    });

    let isrcs = [];

    if (queryParams.isrc) {
        isrcs = queryParams?.isrc?.split(',')?.map((item) => item.trim());
    }

    return Fetch.fetchWithErrorToast({
        url,
        options: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(isrcs),
        },
        errorMessage: 'Failed to get assets by performer id',
    });
}

function importCSVAPI(files) {
    const data = new FormData();
    data.append('file', files[0]);

    const url = `${CONTROLLER_URL}/upload-with-errors`;
    const options = {
        method: 'POST',
        body: data,
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        errorMessage: 'Maximum upload size exceeded',
        successMessage: 'File uploaded successfully',
        pendingMessage: 'Uploading file...',
    });
}

export {
    getAssetTrackTitleLookup,
    getArtistLookup,
    getAssetClientNameLookup,
    getRHAssetsByIdAPI,
    getAssetByPerformerIdAPI,
    importCSVAPI,
};
