import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { FormControl, Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';

const useStyles = makeStyles(() => ({
    textField: {
        margin: '0 0 32px 0',
    },
}));

const ClientIdentifiersContent = () => {
    const dispatch = useDispatch();
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const { detailsData } = rhDataModel;
    const classes = useStyles();
    const params = useParams();
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    useEffect(() => {
        dispatch(changeStateByNestedKey('rhData', 'aliasesError', ''));
    }, []);

    useEffect(() => {
        if (params.id === '-1' || !areFieldsDisabled) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [areFieldsDisabled, params.id]);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                fugaOrgId: detailsData.fugaOrgId,
                otherIdentifiers: detailsData.otherIdentifiers,
                royaltiesClientName: detailsData.royaltiesClientName,
                ddexPartyId: detailsData.ddexPartyId,
                nrpClientId: detailsData.nrpClientId,
                clientAliases: (detailsData.clientAliases || []).join(', '),
            }),
        );
    }, [rhDataModel.detailsData]);

    const [isDisabled, setIsDisabled] = useState(true);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <Grid container spacing={4}>
            <Grid item sx={6} md={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                    <TextFieldWrapper
                        label="NRP Client ID"
                        onChange={(evt) => handleInputChange(evt)}
                        name="nrpClientId"
                        type="text"
                        isDisabled={true}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: true,
                        }}
                        value={rhDataModel.mainDetailsInptValues.nrpClientId || ''}
                        inputProps={{ tabIndex: '6' }}
                    />
                    <br />
                    <TextFieldWrapper
                        label="FUGA Org ID"
                        onChange={(evt) => handleInputChange(evt)}
                        name="fugaOrgId"
                        type="text"
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.fugaOrgId || ''}
                        inputProps={{ tabIndex: '7' }}
                    />
                    <br />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '9' }}
                        label="Other Identifiers"
                        onChange={(evt) => handleInputChange(evt)}
                        name="otherIdentifiers"
                        type="text"
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.otherIdentifiers || ''}
                    />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '10' }}
                        label="Royalties Client Name"
                        onChange={(evt) => handleInputChange(evt)}
                        name="royaltiesClientName"
                        type="text"
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.royaltiesClientName || ''}
                    />
                </FormControl>
            </Grid>
            <Grid item sx={6} md={6}>
                <FormControl variant="standard" style={{ textAlign: 'left', width: '100%' }}>
                    <TextFieldWrapper
                        label="invisible to keep shape"
                        style={{ margin: '0 0 32px 0', visibility: 'hidden' }}
                    />
                    <br />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '8' }}
                        label="DDEX Party ID"
                        onChange={(evt) => handleInputChange(evt)}
                        name="ddexPartyId"
                        type="text"
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.ddexPartyId || ''}
                    />
                    <TextFieldWrapper
                        label="invisible to keep shape"
                        style={{ margin: '0 0 32px 0', visibility: 'hidden' }}
                    />
                    <TextFieldWrapper
                        helperText={rhDataModel.aliasesError}
                        error={!!rhDataModel.aliasesError}
                        inputProps={{ tabIndex: '11' }}
                        label="Royalties Contract Reference(s)"
                        onChange={(evt) => handleInputChange(evt)}
                        name="clientAliases"
                        type="text"
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={joinClientAliases(rhDataModel)}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

function joinClientAliases(dataModel) {
    if (Array.isArray(dataModel.mainDetailsInptValues.clientAliases)) {
        return dataModel.mainDetailsInptValues.clientAliases.join(', ');
    } else if (dataModel.mainDetailsInptValues.clientAliases !== undefined) {
        return dataModel.mainDetailsInptValues.clientAliases.toString();
    } else {
        return '';
    }
}

export default ClientIdentifiersContent;
