import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CommonDropDownButton from './CommonDropDownButton';
import PerformersSvg from '@images/svgComponents/PerformersSvg';
import RightsHoldersSvg from '@images/svgComponents/RightsHoldersSvg';
import { Redirect, useHistory } from 'react-router-dom';
const useStyles = makeStyles({
    itemIcon: {
        height: '24px',
        width: '24px',
        marginRight: '19px',
    },
});

export default function ClientsHeaderBtnDropDownButton() {
    const customClasses = useStyles();
    const history = useHistory();

    const navigateToPerformers = () => {
        history.push('/performers');
    };

    const navigateToRightsHolders = () => {
        history.push('/clients');
    };

    const menuItemsList = [
        {
            title: 'Performers',
            icon: <PerformersSvg styles={customClasses.itemIcon} />,
            onClickClb: navigateToPerformers,
        },
        {
            title: 'Rights Holders',
            icon: <RightsHoldersSvg styles={customClasses.itemIcon} />,
            onClickClb: navigateToRightsHolders,
        },
    ];

    return (
        <>
            <CommonDropDownButton btnTitle="Clients" menuItemsList={menuItemsList} />
        </>
    );
}
