import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseMetadatatUrl();

function getAllRolesAndInstrumentsForMetadataAPI() {
    const url = `${CONTROLLER_URL}/get-roles-instruments/all`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get roles and instruments',
    });
}

function getAllRhNamesForMetadataAPI() {
    const url = `${CONTROLLER_URL}/get-rh-names/all`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get RH names',
    });
}

function getAllPerformerNamesForMetadataAPI() {
    const url = `${CONTROLLER_URL}/get-performers-names/all`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get Performer names',
    });
}

function getRhOwnershipByRhOwnIdAPI(rhOwnId) {
    const url = `${CONTROLLER_URL}/rh-ownership/${rhOwnId}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get RH Ownership',
    });
}

function getContributionAPI(performerId, assetId) {
    const url = new URL(`${CONTROLLER_URL}/get-contribution`);

    url.search = new URLSearchParams({
        ...(performerId ? { performerId } : {}),
        ...(assetId ? { assetId } : {}),
    });

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get contribution',
    });
}

function getAssetMetadataByIdAPI(assetId) {
    const url = `${CONTROLLER_URL}/assets/${assetId}`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get asset metadata',
    });
}

function validateAssetMetadataByIdAPI(assetId, metadataContent) {
    const url = `${CONTROLLER_URL}/validate-assets/${assetId}`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(metadataContent),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to validate asset metadata',
    });
}

function validateContributionsAPI(assetId, contributionContent) {
    const url = new URL(`${CONTROLLER_URL}/validate-contribution`);

    url.search = new URLSearchParams({
        ...(assetId ? { assetId } : {}),
    });

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contributionContent),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Contribution validated successfully',
        errorMessage: 'Failed to validate contribution',
        pendingMessage: 'Validating contribution...',
    });
}

function createRhOwnershipAPI(ownershipContent) {
    const url = `${CONTROLLER_URL}/create/rh-ownership`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ownershipContent),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'RH Ownership created successfully',
        errorMessage: 'Failed to create RH Ownership',
        pendingMessage: 'Creating RH Ownership...',
    });
}

function createContributionAPI(contributionContent, assetId) {
    const url = new URL(`${CONTROLLER_URL}/create-contribution`);

    url.search = new URLSearchParams({
        ...(assetId ? { assetId } : {}),
    });

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contributionContent),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        errorMessage: 'Failed to create contribution',
        successMessage: 'Contribution created successfully',
        pendingMessage: 'Creating contribution...',
    });
}

function createArtistAPI(artistName, assetId) {
    const url = new URL(`${CONTROLLER_URL}/create-artist`);

    url.search = new URLSearchParams({
        ...(assetId ? { assetId } : {}),
    });

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            artistName,
        }),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        errorMessage: 'Failed to create artist',
        successMessage: 'Artist created successfully',
        pendingMessage: 'Creating artist...',
    });
}

function createOrUpdateCmoRegistrationAPI(content, update = false) {
    const url = update
        ? `${userData.api.baseCmoUrl()}/update-cmo-territory-registration`
        : `${userData.api.baseClientUrl()}/create-cmo-territory-registrations`;

    const options = {
        method: update ? 'PATCH' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
    };

    return Fetch.fetchWithToastsCustomSuccess({
        url,
        options,
        failFullResp: true,
        errorMessage: 'Failed to update CMO registration',
        pendingMessage: 'Updating CMO registration...',
    });
}

function editRhOwnershipAPI(ownershipContent, rhId) {
    const url = `${CONTROLLER_URL}/update-rh-ownership/${rhId}`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ownershipContent),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'RH Ownership updated successfully',
        errorMessage: 'Failed to update RH Ownership',
        pendingMessage: 'Updating RH Ownership...',
    });
}

function editContributionAPI(contributionContent, assetId) {
    const url = new URL(`${CONTROLLER_URL}/update-contribution`);

    url.search = new URLSearchParams({
        ...(assetId ? { assetId } : {}),
    });

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contributionContent),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Contribution updated successfully',
        errorMessage: 'Failed to update contribution',
        pendingMessage: 'Updating contribution...',
    });
}

function editAssetMetadataByIdAPI(assetId, metadataContent) {
    const url = `${CONTROLLER_URL}/assets/${assetId}`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(metadataContent),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Asset metadata updated successfully',
        errorMessage: 'Failed to update asset metadata',
        pendingMessage: 'Updating asset metadata...',
    });
}

function editArtistAssetMetadataByIdAPI(assetId, artistContent) {
    const url = new URL(`${CONTROLLER_URL}/update-artist`);

    url.search = new URLSearchParams({
        ...(assetId ? { assetId } : {}),
    });

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(artistContent),
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Artist updated successfully',
        errorMessage: 'Failed to update artist',
        pendingMessage: 'Updating artist...',
    });
}

function validateRhOwnershipByRhAndAssetIdAPI(ownershipContent, assetId, rhId) {
    const url = new URL(`${CONTROLLER_URL}/validate-rh-ownership/${rhId}`);

    url.search = new URLSearchParams({
        ...(assetId ? { assetId } : {}),
    });

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ownershipContent),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to validate RH Ownership',
    });
}

function deleteRhOwnershipAPI(rhoId, assetId) {
    const url = new URL(`${CONTROLLER_URL}/remove-rh-ownership/${rhoId}`);

    url.search = new URLSearchParams({
        ...(assetId ? { assetId } : {}),
    });

    const options = {
        method: 'DELETE',
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'RH Ownership deleted successfully',
        errorMessage: 'Failed to delete RH Ownership',
        pendingMessage: 'Deleting RH Ownership...',
    });
}

function deleteContributionAPI(performerId, assetId) {
    const url = new URL(`${CONTROLLER_URL}/remove-contribution`);

    url.search = new URLSearchParams({
        ...(assetId ? { assetId } : {}),
        ...(performerId ? { performerId } : {}),
    });

    const options = {
        method: 'DELETE',
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Contribution deleted successfully',
        errorMessage: 'Failed to delete contribution',
        pendingMessage: 'Deleting contribution...',
    });
}

function deleteArtistByIdAPI(assetId, artistId) {
    const url = new URL(`${CONTROLLER_URL}/remove-artist`);

    url.search = new URLSearchParams({
        ...(assetId ? { assetId } : {}),
        ...(artistId ? { artistId } : {}),
    });

    const options = {
        method: 'DELETE',
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        successMessage: 'Artist deleted successfully',
        errorMessage: 'Failed to delete artist',
        pendingMessage: 'Deleting artist...',
    });
}

export {
    getAllRolesAndInstrumentsForMetadataAPI,
    getAllRhNamesForMetadataAPI,
    getAllPerformerNamesForMetadataAPI,
    getContributionAPI,
    getAssetMetadataByIdAPI,
    validateAssetMetadataByIdAPI,
    createRhOwnershipAPI,
    createArtistAPI,
    createOrUpdateCmoRegistrationAPI,
    createContributionAPI,
    editAssetMetadataByIdAPI,
    editRhOwnershipAPI,
    editArtistAssetMetadataByIdAPI,
    editContributionAPI,
    validateRhOwnershipByRhAndAssetIdAPI,
    validateContributionsAPI,
    getRhOwnershipByRhOwnIdAPI,
    deleteRhOwnershipAPI,
    deleteContributionAPI,
    deleteArtistByIdAPI,
};
