import { Dialog, Typography, makeStyles } from '@material-ui/core';
import brandedTitle from '@images/branding/brandedtitle.png';
import React, { useEffect } from 'react';
import { MAINTENANCE_LOCAL_STORAGE_NAME } from '@data/globalConstants';
import CerberusUtils from '@utils/cerberusUtils';
import { roles } from '@data/constants';
import helper from '@utils/helper';
import { checkMaintenanceAvailabilityAPI } from '@api/nrUserAccessController';
const { getVarFromLocalStorage, getUserRole } = CerberusUtils;

const { deleteVarFromLocalStorage } = CerberusUtils;

const useClasses = makeStyles(() => ({
    displayFlex: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#FFF',
    },
}));

const useBrandedTitleStyles = makeStyles(() => ({
    container: {
        marginBottom: 10,
    },
    logo: {
        width: 250,
        height: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const BrandedTitle = () => {
    const classes = useBrandedTitleStyles();

    return (
        <div className={classes.container}>
            <img className={classes.logo} src={brandedTitle} />
        </div>
    );
};

export default function MaintenancePage() {
    const classes = useClasses();
    const role = getUserRole();
    const isUnderMaintenance = getVarFromLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME);

    const checkMaintenanceAvailability = (defaultRedirectPath) => {
        let timeoutId;

        async function sendRequest() {
            try {
                await checkMaintenanceAvailabilityAPI();
                deleteVarFromLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME);
                window.location.assign(defaultRedirectPath);
            } catch (error) {
                console.error('Error checking maintenance availability:', error);
                timeoutId = setTimeout(sendRequest, 3000);
            }
        }

        sendRequest();

        setTimeout(() => {
            clearTimeout(timeoutId);
        }, 300000);
    };

    useEffect(() => {
        const roleInfo = roles.find((rl) => rl.name === role);
        const redirectPathLocal = roleInfo.landingPage.toBeGenerated
            ? helper.generateLandingPage(roleInfo.landingPage.url)
            : roleInfo.landingPage.url;
        checkMaintenanceAvailability(redirectPathLocal);
    }, []);

    return (
        <Dialog fullScreen open={!!isUnderMaintenance}>
            <div className={classes.displayFlex}>
                <div>
                    <BrandedTitle />
                    <Typography variant="body1">
                        The Neighbouring Rights Platform is undergoing update and will be back
                        shortly
                    </Typography>
                </div>
            </div>
        </Dialog>
    );
}
