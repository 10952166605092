import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey, changeStateByKey } from '@actions/fugaActions';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import ClientDealsTable from './ClientDealsTable';
import TerritoriesModal from '@common/modals/TerritoriesModal';
import { CommonDropDownWithInput } from '@common/CommonDropDownWithInput';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import { Button, FormControl, Grid, Typography, makeStyles } from '@material-ui/core';
import { getAllTerritories } from '@actions/territories';

const useStyles = makeStyles(() => ({
    textField: {
        margin: '0 0 32px 0',
    },
}));

const currencyMenuItems = [
    {
        title: 'EUR',
        value: 'EUR',
    },
    {
        title: 'GBP',
        value: 'GBP',
    },
    {
        title: 'USD',
        value: 'USD',
    },
];

const ClientDetailsContent = (props) => {
    const dispatch = useDispatch();
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const [showTerritoryModal, setShowTerritoryModal] = useState(false);
    const [territories, setTerritories] = useState([]);
    const classes = useStyles();
    const { detailsData } = rhDataModel;
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    useEffect(() => {
        if (areFieldsDisabled) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [areFieldsDisabled, props.match.params.id]);

    useEffect(() => {
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
        dispatch(getAllTerritories());
    }, []);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                advance: detailsData.advance,
                dealRate: detailsData.dealRate,
                rateAfterRec: detailsData.rateAfterRecoupment,
                rateDuringRec: detailsData.rateDuringRecoupment,
                accounting: detailsData.accountingFrequency,
                currency: detailsData.currency,
                postTerm: detailsData.postTerm,
            }),
        );
    }, [detailsData]);

    const [isDisabled, setIsDisabled] = useState(true);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    const handleCurrencyValueChange = (value) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                currency: value,
            }),
        );
    };

    const handleAddDeal = () => {
        if (props.match.params.id === '-1') {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please save this Client before adding their Deal terms',
                    severity: 'warning',
                }),
            );
        } else {
            setShowTerritoryModal(true);
        }
    };

    return (
        <>
            <Grid container spacing={4}>
                <Grid item sx={6} md={6}>
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <TextFieldWrapper
                            label="Advance"
                            onChange={(evt) => handleInputChange(evt)}
                            name="advance"
                            type="text"
                            value={rhDataModel.mainDetailsInptValues.advance || ''}
                            inputProps={{ tabIndex: '15' }}
                            isDisabled={isDisabled}
                            classNameOpt={classes.textField}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                        />
                        <br />
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '17' }}
                            label="Rate (after recoupment)"
                            onChange={(evt) => handleInputChange(evt)}
                            name="rateAfterRec"
                            type="text"
                            isDisabled={isDisabled}
                            classNameOpt={classes.textField}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={rhDataModel.mainDetailsInptValues.rateAfterRec || ''}
                        />
                        <br />
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '19' }}
                            label="Accounting Frequency"
                            onChange={(evt) => handleInputChange(evt)}
                            name="accounting"
                            type="text"
                            isDisabled={isDisabled}
                            classNameOpt={classes.textField}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={rhDataModel.mainDetailsInptValues.accounting || ''}
                        />

                        <br />
                    </FormControl>
                </Grid>
                <Grid item sx={6} md={6}>
                    <FormControl variant="standard" style={{ textAlign: 'left', width: '100%' }}>
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '16' }}
                            label="Rate (during recoupment)"
                            onChange={(evt) => handleInputChange(evt)}
                            name="rateDuringRec"
                            type="text"
                            isDisabled={isDisabled}
                            classNameOpt={classes.textField}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={rhDataModel.mainDetailsInptValues.rateDuringRec || ''}
                        />
                        <br />
                        <TextFieldWrapper
                            inputProps={{ tabIndex: '18' }}
                            label="Post Term"
                            onChange={(evt) => handleInputChange(evt)}
                            name="postTerm"
                            type="text"
                            isDisabled={isDisabled}
                            classNameOpt={classes.textField}
                            InputProps={{
                                readOnly: isDisabled,
                            }}
                            value={rhDataModel.mainDetailsInptValues.postTerm || ''}
                        />
                        <br />
                        <CommonDropDownWithInput
                            menuItemsList={currencyMenuItems}
                            labelName="Currency"
                            preSelectedValue={rhDataModel.mainDetailsInptValues.currency}
                            isEditing={isDisabled}
                            isDisabled={isDisabled}
                            inputProps={{ tabIndex: '4' }}
                            onValueChangeCb={handleCurrencyValueChange}
                        />
                        <br />
                    </FormControl>
                </Grid>
            </Grid>
            <div
                className="flex-horizontal"
                style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    marginBottom: '13.5px',
                }}
            >
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Deals
                </Typography>
                <Button variant="outlined" onClick={handleAddDeal}>
                    <AddIcon style={{ margin: '0 10px 0 0' }} />
                    ADD DEAL
                </Button>
            </div>
            <TerritoriesModal
                showTerritoryModal={showTerritoryModal}
                setShowTerritoryModal={setShowTerritoryModal}
                territories={territories}
                setTerritories={setTerritories}
                groupedTerritories={territoriesModel.groupedTerritories}
                clientId={props.match.params.id}
                isEditing={false}
            />
            <ClientDealsTable />
        </>
    );
};

const withUrlClientDetailsContent = withRouter(ClientDetailsContent);
export default connect((r) => r)(withUrlClientDetailsContent);
