import React from 'react'

export default function RightsHoldersSvg(props) {
    return (
        <div className={props.styles}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 8.23828V5.25H4.51172V18.75H19.4883V8.23828H12ZM7.5 17.2383H5.98828V15.7617H7.5V17.2383ZM7.5 14.25H5.98828V12.7383H7.5V14.25ZM7.5 11.2617H5.98828V9.75H7.5V11.2617ZM7.5 8.23828H5.98828V6.76172H7.5V8.23828ZM10.4883 17.2383H9.01172V15.7617H10.4883V17.2383ZM10.4883 14.25H9.01172V12.7383H10.4883V14.25ZM10.4883 11.2617H9.01172V9.75H10.4883V11.2617ZM10.4883 8.23828H9.01172V6.76172H10.4883V8.23828ZM18.0117 17.2383H12V15.7617H13.5117V14.25H12V12.7383H13.5117V11.2617H12V9.75H18.0117V17.2383ZM16.5 11.2617H14.9883V12.7383H16.5V11.2617ZM16.5 14.25H14.9883V15.7617H16.5V14.25Z" fill="#5F5F60" />
            </svg>
        </div>
    )
}
