import React, { useState } from 'react';
import {
    Button,
    Button as MuiButton,
    Checkbox,
    makeStyles,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import helper from '@utils/helper';

const useClasses = makeStyles({
    menuItem: {
        padding: '0px 16px 0px 0px',
        height: '48px',
    },
    centerIcon: {
        display: 'flex',
    },
    applyBtn: {
        marginBottom: '8px',
    },
    menuWrapper: {
        '& ul': { width: '208px', minHeight: '150px', maxHeight: '300px', marginBottom: '8px' },
    },
});

export default function CommonDropDownWithCheckboxesBtn({
    btnTitle,
    menuItemsList,
    customStyles,
    handleApplyCB,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useClasses();
    const [selectedItems, setSelectedItems] = useState([]);

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    };

    const handleApply = () => {
        handleApplyCB(selectedItems);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedItems([]);
    };

    const onItemClick = (item) => {
        if (item.onClickClb) item.onClickClb();
    };
    const handleCheckItem = (clickedItem) => {
        return selectedItems.includes(clickedItem.title);
    };

    const handleCheckboxChange = (clickedItem) => {
        const selectedId = selectedItems.findIndex((item) => item === clickedItem.title);
        const updatedSelectedList = helper.updateList(selectedItems, clickedItem.title, selectedId);
        setSelectedItems(updatedSelectedList);
    };

    return (
        <>
            <MuiButton
                variant={customStyles?.variant ? customStyles.variant : 'text'}
                onClick={(e) => handleOpen(e)}
                color={customStyles?.color ? customStyles.color : 'default'}
                disableRipple
                style={{ height: '33px' }}
            >
                {btnTitle}
                <ArrowDropDown style={{ marginRight: '0' }} />
            </MuiButton>
            <Menu
                className={classes.menuWrapper}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <div
                    style={{
                        position: 'relative',
                        minHeight: '300px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ height: '250px', overflow: 'auto' }}>
                        {menuItemsList &&
                            menuItemsList.map((item, ind) => (
                                <MenuItem
                                    disableRipple
                                    key={`${ind}-${item.title}`}
                                    onClick={() => onItemClick(item)}
                                    className={classes.menuItem}
                                >
                                    <Checkbox
                                        color="primary"
                                        disableRipple
                                        checked={handleCheckItem(item)}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                    <Typography variant="subtitle2">{item.title}</Typography>
                                </MenuItem>
                            ))}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            position: 'absolute',
                            bottom: 0,
                            width: '90%',
                        }}
                    >
                        <Button
                            className={classes.applyBtn}
                            color="primary"
                            variant="contained"
                            onClick={handleApply}
                        >
                            APPLY
                        </Button>
                    </div>
                </div>
            </Menu>
        </>
    );
}
