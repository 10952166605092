import React from 'react';

export default function FoundEeMarketingLogo(props) {
    return (
        <div className={props.styles}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.9238 15.3916C24.5981 11.6298 29.3019 12.2536 32.1112 14.981C35.2083 17.9872 35.5912 22.4217 32.2766 25.8156C29.2407 28.9232 25.3501 28.5712 22.4176 25.7243C22.0691 25.3858 21.4312 24.6072 21.4312 24.6072L27.921 17.9627C26.3285 16.9344 24.6955 17.4202 23.5972 18.5447C23.1608 18.9915 22.8401 19.5125 22.6049 20.0248C22.5384 20.5452 22.3748 21.3972 21.9722 22.3772C21.9713 22.3882 21.9705 22.3971 21.9701 22.404C21.9696 22.4093 21.9693 22.4134 21.9692 22.4161L21.9688 22.4206L21.9556 22.4174C21.5427 23.4104 20.8842 24.5311 19.8345 25.5698C16.0966 29.2688 11.4037 28.5653 8.64114 25.7907C5.5964 22.7324 5.28907 18.292 8.66129 14.9549C11.7491 11.8992 15.6336 12.3172 18.5171 15.2134C18.8599 15.5577 19.4844 16.3469 19.4844 16.3469L12.8821 22.8805C14.4569 23.9357 16.098 23.4776 17.2153 22.3719C17.6795 21.9125 18.0187 21.3698 18.2656 20.8391C18.41 19.7202 18.9449 17.4177 20.9238 15.3916ZM29.61 23.2275C30.6091 22.2052 30.5344 21.1367 29.9748 20.1553L27.0386 23.1607C27.6956 23.7978 28.8918 23.9636 29.61 23.2275ZM11.2826 17.5878C10.2669 18.593 10.3237 19.6627 10.8664 20.6534L13.8527 17.6982C13.2074 17.0501 12.0139 16.8641 11.2826 17.5878Z"
                    fill="white"
                />
            </svg>
        </div>
    );
}
