import React from 'react'
import { connect, useSelector } from 'react-redux'
import {
    Typography,
} from '@material-ui/core'

function ClientMainInfo() {
    const cmoClientModel = useSelector(state => state.fugaReducers.cmoClient)
    return (
        <>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                TERRITORIES/CMO SELECTION
            </Typography>
                    <br/>
            <Typography variant="body1">
                <b>Client name :</b> {cmoClientModel.clientData.legalCompanyName}
            </Typography>
            <br/>
        </>
    )
}

export default connect(r => r)(ClientMainInfo)
