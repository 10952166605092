import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    makeStyles,
} from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import { deleteClientByIdAPI } from '@api/clientController';

const useStyles = makeStyles(() => ({
    title: {
        padding: '19px 16px',
    },
    content: {
        padding: '10px 16px',
    },
}));

function DeletePerformerDialog({ isOpen, closeCb, clientId, isRh }) {
    const classes = useStyles();
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
    };

    const handleConfirm = async () => {
        const redirectPath = isRh ? '/clients' : '/performers';
        await deleteClientByIdAPI(clientId, redirectPath);
        window.location.assign(redirectPath);
        handleClose();
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={open} maxWidth="md">
                <DialogTitle onClose={handleClose} className={classes.title}>
                    Delete client?
                </DialogTitle>
                <DialogContent className={`${classes.content}`}>
                    <Typography variant="body1">
                        Are you sure you want to delete this client?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="primary"
                        disableElevation
                    >
                        cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleConfirm}
                    >
                        delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withRouter(DeletePerformerDialog);
