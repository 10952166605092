import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseTerritoriesUrl();

function getAllTerritoriesAPI() {
    const url = `${CONTROLLER_URL}/all`;

    return Fetch.fetchWithErrorToast({
        url,
        errorMessage: 'Failed to get territories',
    });
}

function formatTerritoryCodesAPI(territoryCodes = []) {
    const url = `${CONTROLLER_URL}/format`;

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            codesToFormat: Array.isArray(territoryCodes) ? territoryCodes.join() : territoryCodes,
        }),
    };

    return Fetch.fetchWithErrorToast({
        url,
        options,
        errorMessage: 'Failed to format territory codes',
    });
}

export { getAllTerritoriesAPI, formatTerritoryCodesAPI };
