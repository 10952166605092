import utils from '@utils/utils';
import { items } from '@data/constants';
import { changeStateByNestedKey } from './fugaActions';
import { getStatementsByClientIdAPI } from '@api/clientController';

function getStatementsByClientId(clientId, orderBy = 'type') {
    return async function (dispatch) {
        dispatch(changeStateByNestedKey('statements', 'tableIsLoading', true));

        const resp = await getStatementsByClientIdAPI(clientId);

        const statementsList = resp?.data?.statements || [];

        const sortingCb = (file1, file2) => {
            const value1 = file1[orderBy];
            const value2 = file2[orderBy];

            if (value1 == null && value2 == null) return 0;
            if (value1 == null) return 1;
            if (value2 == null) return -1;

            if (value1 < value2) return 1;
            if (value1 > value2) return -1;
            return 0;
        };

        const filteredList = statementsList.filter((statement) => {
            const isFlagIgnored = utils.checkItemRolesAndPermissions(
                items.tableColiumns.fullListInCollapsibleTable,
            );
            return isFlagIgnored ? true : statement.fullStListPresent;
        });

        const sortedList = filteredList.map((statement) => {
            const sortedFiles = statement.files.sort(sortingCb);
            return { ...statement, files: sortedFiles };
        });

        dispatch(
            changeStateByNestedKey('statements', 'dataFromBE', {
                content: sortedList,
            }),
        );
        dispatch(changeStateByNestedKey('statements', 'tableIsLoading', false));
    };
}

export { getStatementsByClientId };
