import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { FormControl, Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';

const useStyles = makeStyles(() => ({
    textField: {
        margin: '0 0 32px 0',
    },
}));

const ClientBillingContent = () => {
    const dispatch = useDispatch();
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const { detailsData } = rhDataModel;
    const params = useParams();
    const classes = useStyles();
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    useEffect(() => {
        if (params.id === '-1' || !areFieldsDisabled) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [areFieldsDisabled, params.id]);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                bankAccNum: detailsData.bankAccountNumber,
                bankCity: detailsData.bankCity,
                iban: detailsData.bankIbanNumber,
                bankName: detailsData.bankName,
                bankAccPayee: detailsData.bankAccountPayee,
                bankCountry: detailsData.bankCountry,
                bankSwiftNumber: detailsData.bankSwiftNumber,
            }),
        );
    }, [rhDataModel.detailsData]);

    const [isDisabled, setIsDisabled] = useState(true);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    return (
        <Grid container spacing={4}>
            <Grid item sx={6} md={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                    <TextFieldWrapper
                        label="Bank account number"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankAccNum"
                        type="text"
                        isDisabled={isDisabled}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.bankAccNum || ''}
                        inputProps={{ tabIndex: '20' }}
                        classNameOpt={classes.textField}
                    />
                    <br />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '22' }}
                        label="Bank city"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankCity"
                        type="text"
                        isDisabled={isDisabled}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.bankCity || ''}
                        classNameOpt={classes.textField}
                    />
                    <br />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '24' }}
                        label="Bank IBAN number"
                        onChange={(evt) => handleInputChange(evt)}
                        name="iban"
                        type="text"
                        value={rhDataModel.mainDetailsInptValues.iban || ''}
                        isDisabled={isDisabled}
                        classNameOpt={classes.textField}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                    />
                    <br />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '26' }}
                        label="Bank name"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankName"
                        type="text"
                        value={rhDataModel.mainDetailsInptValues.bankName || ''}
                        classNameOpt={classes.textField}
                        isDisabled={isDisabled}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item sx={6} md={6}>
                <FormControl variant="standard" style={{ textAlign: 'left', width: '100%' }}>
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '21' }}
                        label="Bank account payee"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankAccPayee"
                        type="text"
                        classNameOpt={classes.textField}
                        isDisabled={isDisabled}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.bankAccPayee || ''}
                    />
                    <br />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '23' }}
                        label="Bank country"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankCountry"
                        type="text"
                        value={rhDataModel.mainDetailsInptValues.bankCountry || ''}
                        classNameOpt={classes.textField}
                        isDisabled={isDisabled}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                    />
                    <br />
                    <TextFieldWrapper
                        inputProps={{ tabIndex: '25' }}
                        label="Bank Swift number"
                        onChange={(evt) => handleInputChange(evt)}
                        name="bankSwiftNumber"
                        type="text"
                        isDisabled={isDisabled}
                        InputProps={{
                            readOnly: isDisabled,
                        }}
                        value={rhDataModel.mainDetailsInptValues.bankSwiftNumber || ''}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ClientBillingContent;
